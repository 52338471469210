import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { LanguageService } from '../../Services/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService, } from 'app/Services/admin.service';
import { CommonService } from 'app/common.service';
import { MeineHeizungSetupComponent } from '../meine-heizung-setup/meine-heizung-setup.component';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ThirdPartyService } from 'app/Services/third-party.service';
import { InventoyTrackingService } from 'app/Services/inventoy-tracking.service';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SafePipe } from 'app/pipes/safe.pipe';

export class CustomDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const names = super.getDayOfWeekNames('short');
    return names.map(name => name.substr(0, 3));
  }

  format(date: Date, displayFormat: Object): string {

    console.log("case1")
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // Return the format as per your requirement
    return `${this._to2digit(day)}.${this._to2digit(month)}.${year}`;

  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

@Component({
  selector: 'app-meinepopup',
  templateUrl: './meinepopup.component.html',
  styleUrls: ['./meinepopup.component.css'],
  providers: [SafePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS] }
  ]
})
export class MeinepopupComponent implements OnInit {
  @ViewChild('snackbarElement', { static: true }) snackbarElement: ElementRef;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  url: any;
  address: { [key: string]: any }[] = [];
  addAddressinpopup: boolean = false;
  adressenpopup: boolean = false;
  tankFillForm: FormGroup;
  bestandForm: FormGroup;
  isEditing: boolean = false;
  @Input('createAddress') createAddress: boolean = false;
  langData: any;
  showInputError: boolean = false;
  showrefillinput1: boolean = false;
  showrefillinput2: boolean = false;
  minDate = new Date();
  maxFuelAmount = 5000;
  stageTwoForm: FormGroup;
  HouseTypes = [];
  svgs = [
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/HomeHeatercopy.svg',
    environment.assetsUrl + 'assets/EditIcon.svg'
  ];
  stepProcessInitalize: any = {
    address: {},
  }
  invData: any;
  showFullScreenLoading: Boolean = false;
  noHeatingAddress: { [key: string]: any; }[];

  constructor(private langService: LanguageService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<MeinepopupComponent>,
    private adminService: AdminService,
    private fb: FormBuilder,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private thirdParty: ThirdPartyService,
    private inventoryService: InventoyTrackingService
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['kundeportal'] || {};
    this.langData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['kundeportal'] || {};
      this.langData = langData['web-pc'] || {};
    });
  };

  async ngOnInit(): Promise<void> {
    this.getAddresses();
    this.getDynamicSVG(this.svgs);

    this.tankFillForm = this.fb.group({
      tankFull: [false],
      newFuelLevel: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      AddedFuelAmount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      date: [new Date()],
    });

    this.bestandForm = this.fb.group({
      currentFuelLevel: ['2000', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      date: [new Date()],
    });

    this.bestandForm.get('currentFuelLevel').disable();
    if (this.data.address?.show) {
      this.initalizeProcess(this.data.address.address);
      this.dialogRef.close({ address: false })
    };

    if (this.data.type == 'tankFill' || this.data.type == 'Bestandskontrolle') {
      this.maxFuelAmount = this.data.heatingData.maxTankSize;
      if (this.data.type == 'Bestandskontrolle') {
        const formvalues = {
          currentFuelLevel: this.data.heatingData.currentActiveLevel,
        };
        this.bestandForm.patchValue(formvalues);
      };
    };
  };

  next() {
    this.getAddresses();
    this.adressenpopup = true;
  };

  close() {
    this.dialogRef.close({ address: false });
  };


  getAddresses() {
    this.adminService.getPortaladdresses().subscribe((result: any) => {
      if (result.success) {
        this.address = result.data;
        this.address = this.address.sort(a => a.isPrimary ? -1 : 1)
        this.noHeatingAddress = this.address.filter(each => !each.myheating?.isHeating);
      } else {
        this.address = [];
      };
    });
  };


  changeView() {
    this.dialogRef.close({ address: true })
  };

  initalizeProcess(address) {
    this.stepProcessInitalize['address'] = address;

    console.log(address);
    //address.plzId || address._id || address.
    // get latitude,longtiude,zipCode,city from address
    let { lat, lng, zipCode, city, plzId, _id, suburb, country } = this.stepProcessInitalize['address'];
    if (this.stepProcessInitalize.address.hasOwnProperty('weatherId')) {
      this.stepProcessInitalize.address.weatherId = this.stepProcessInitalize.address.weatherId;
      //weather id is present available for address so open the setup dialog
      const HeatingSetupDialog = this.dialog.open(MeineHeizungSetupComponent, {
        disableClose: true,
        data: {
          type: 'meinepopup',
          weatherId: this.stepProcessInitalize['address']['weatherId'],
          sunHoursProvideWeatherStation: this.stepProcessInitalize['address']['sunhourStation'],
          addressId: this.stepProcessInitalize['address']['_id']
        }
      });
      this.close();
      HeatingSetupDialog.afterClosed().subscribe((data: any) => {
        if (data?.success) {
          this.saveMyHeatingData(data.data)
        } else {
          this.inventoryService.emitNewHeatingReocrd('update');
        };
      });
    } else {
      //weather id is not available for address so search the weather id using lat,lng,zipCode,city
      let requestedAddress = '';
      if (lat != '' && lng != '') {
        requestedAddress = `${lat},${lng}`;
      } else if (city != '') {
        requestedAddress = city;
      } else if (zipCode != '') {
        //if city is not available then use zipCode but zipcode only work canada usa and uk postal codes
        requestedAddress = zipCode;
      };

      //for meteostat weather id
      let meteostatPayload = {
        lat: lat, lng: lng, zipCode: zipCode, city: city, plzId: plzId, _id: _id, suburb: suburb, country: country,
        addressId: this.stepProcessInitalize['address']['_id'],
      }
      this.thirdParty.getNearWeatherStation(meteostatPayload).subscribe((meteoStations): any => {
        console.log(meteoStations, 4545);
        if (meteoStations.success && meteoStations.data.length > 0) {
          let result = meteoStations.data;
          // alert(`weather id ${result[0]['id']}`) ;
          this.inventoryService.saveWeatherId({ lat: lat, lon: lng, stationsList: meteoStations.data, weatherId: result[0]['id'], addressId: this.stepProcessInitalize['address']['_id'], sunHoursProvideWeatherStation: meteoStations.sunHoursProvider }).subscribe((resp) => {
            if (resp.success) {
              this.stepProcessInitalize['address']['weatherId'] = result[0]['id'];
              this.stepProcessInitalize['address']['sunHoursProvideWeatherStation'] = result['sunHoursProvider'];
              console.log("weather id saved successfully", result.sunHoursProvider);
              const HeatingSetupDialog = this.dialog.open(MeineHeizungSetupComponent, {
                disableClose: true,
                data: {
                  type: 'meinepopup',
                  weatherId: result[0]['id'],
                  addressId: this.stepProcessInitalize['address']['_id'],
                  sunHoursProvideWeatherStation: meteoStations.sunHoursProvider
                }
              });
              this.close();
              HeatingSetupDialog.afterClosed().subscribe(data => {
                if (data?.success) {
                  console.log(data, 131)
                  // this.saveMyHeatingData(data.data)
                } else {
                  this.inventoryService.emitNewHeatingReocrd('update');
                }
              });
            } else {
              console.log("based on the address details weather id is not available");
              this.showSnackbar("based on the address details weather id is not available");
            };
          });
        } else {
          console.log("based on the address details weather id is not available");
          this.showSnackbar("based on the address details weather id is not available");
        }
      }, error => {
        console.log("based on the address details weather id is not available or error in fetching the weather id");
        console.log(error);
      }, () => { });

      // this.thirdParty.searchAddressId(requestedAddress).subscribe((result: any) => {
      //   if (Array.isArray(result) && result.length > 0) {
      //     this.inventoryService.saveWeatherId({ weatherId: result[0]['id'], addressId: this.stepProcessInitalize['address']['_id'] }).subscribe((resp) => {
      //       if (resp.success) {
      //         this.stepProcessInitalize['address']['weatherId'] = result[0]['id'];
      //         console.log("weather id saved successfully");
      //         const HeatingSetupDialog = this.dialog.open(MeineHeizungSetupComponent, {
      //           data: {
      //             type: 'meinepopup',
      //             weatherId: result[0]['id'],
      //             addressId: this.stepProcessInitalize['address']['_id']
      //           }
      //         });
      //         this.close();
      //         HeatingSetupDialog.afterClosed().subscribe(data => {
      //           console.log(data, 123);
      //           if (data.success) {
      //             console.log(data, 131)
      //             // this.saveMyHeatingData(data.data)
      //           } else {
      //             this.inventoryService.emitNewHeatingReocrd('update');
      //           }
      //         });
      //       } else {
      //         console.log("based on the address details weather id is not available");
      //         this.showSnackbar("based on the address details weather id is not available");
      //       };
      //     });
      //   } else {
      //     console.log("based on the address details weather id is not available");
      //     this.showSnackbar("based on the address details weather id is not available");
      //   };
      // }, (error) => {
      //   console.log("based on the address details weather id is not available or error in fetching the weather id");
      //   console.log(error);
      // });
    };
  };

  validateInput(input, control) {
    console.log(input, control, 6464);
    if (input && input <= 0) {
      control == ('newFuelLevel') ? this.showrefillinput1 = true : this.showrefillinput2 = true;
    } else {
      this.showrefillinput1 = false;
      this.showrefillinput2 = false;
      this.tankFillForm.get(control).markAsTouched();
    };

    let newFuelLevel = this.tankFillForm.get('newFuelLevel').value;
    let AddedFuelAmount = this.tankFillForm.get('AddedFuelAmount').value;
    newFuelLevel = Number(newFuelLevel);
    AddedFuelAmount = Number(AddedFuelAmount);
    if (newFuelLevel > 0 && newFuelLevel > 0) {

      if (AddedFuelAmount > newFuelLevel) {
        this.tankFillForm.get(control).setErrors({ 'lessThanInput1': true });
      } else if (newFuelLevel < AddedFuelAmount) {
        this.tankFillForm.get(control).setErrors({ 'lessThanInput1': true });
      };
    };
    if (newFuelLevel > 0 || AddedFuelAmount > 0) {
      console.log((this.maxFuelAmount < newFuelLevel), (this.maxFuelAmount < AddedFuelAmount), 5456);
      if (this.maxFuelAmount < newFuelLevel) {
        this.tankFillForm.get(control).setErrors({ 'maxFuelAmount': true });
      } else if (this.maxFuelAmount < AddedFuelAmount) {
        this.tankFillForm.get(control).setErrors({ 'maxFuelAmount': true });
      };
    };
  };

  saveFillingData() {
    console.log(this.tankFillForm.value, this.tankFillForm.valid, 4546);
    this.dialogRef.close({ data: this.tankFillForm.value, save: true })
  };

  backToDashboard() {
    this.dialogRef.close({ cancel: true })
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
    });
  };

  bestandInputValidate(input, control) {
    if (input && input <= 0) {
      this.showInputError = true
    } else {
      this.showInputError = false;
      this.bestandForm.get(control).markAsTouched();
    };

    let currentFuelLevel = this.bestandForm.get('currentFuelLevel').value;
    currentFuelLevel = Number(currentFuelLevel);
    if (this.maxFuelAmount < currentFuelLevel) {
      this.bestandForm.get(control).setErrors({ 'maxFuelAmount': true });
    } else {
      const formvalues = {
        currentFuelLevel: currentFuelLevel,

      };
      this.bestandForm.patchValue(formvalues);
    };
  };

  saveInventarydata() {
    if (this.bestandForm.value && this.bestandForm.valid) {
      this.dialogRef.close({ data: this.invData, save: true })
    };
  };
  enableEditing() {
    this.isEditing = true;
    this.bestandForm.get('currentFuelLevel').enable();
  };

  // Function to save data when clicking outside the input
  saveData() {
    if (this.bestandForm.value && this.bestandForm.valid) {
      this.isEditing = false;
      this.invData = this.bestandForm.value;
      this.bestandForm.get('currentFuelLevel').disable();
    };
  };

  closehyperlink() {
    this.dialogRef.close()
  };

  saveMyHeatingData(data) {
    this.showFullScreenLoading = true;
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string' && value !== '') {
        data[key] = parseInt(value, 10);
      }
    });
    ['stageFour', 'stageThree', 'stageTwo'].forEach(property => {
      if (data.hasOwnProperty(property)) {
        delete data[property];
      }
    });
    console.log(data, 45445);
    data = { ...data, addressId: this.stepProcessInitalize['address']._id }
    // this.adminService.saveInventoryData({ inventoryData: data }).subscribe(resp => {
    //   if (resp.success) {
    //     this.showFullScreenLoading = false;
    //     this.showSnackbar("Heating data saved successfully");
    //     // window.location.reload();
    //     this.inventoryService.emitNewHeatingReocrd('update');
    //   } else {
    //     this.showFullScreenLoading = false;
    //   }
    // },(err)=>{
    //   this.showFullScreenLoading = false;
    // });

    // this.adminService.saveInventoryData({ inventoryData: data }).subscribe(resp => {
    //   if (resp.success) {
    //     this.showFullScreenLoading = false;
    //     this.showSnackbar("Heating data saved successfully");
    //     // window.location.reload();
    //     this.inventoryService.emitNewHeatingReocrd('update');
    //   } else {
    //     this.showFullScreenLoading = false;
    //   }
    // });
  };


  showSnackbar(message: string) {
    if (this.snackbarElement && this.snackbarElement.nativeElement) {
      this.snackbarElement.nativeElement.querySelector('#snackbar-message').textContent = message;
      this.snackbarElement.nativeElement.classList.add('show');
      setTimeout(() => {
        this.snackbarElement.nativeElement.classList.remove('show');
      }, 3000);
    };
  };

}
