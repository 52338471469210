<div class="aupris_main scrolltotopprogress" [ngClass]="theme == 'Theme1' ? 'theme1':'theme2'">
    <div class="" style="margin-top:50px;width: 100%;" *ngIf="clickedPage=='Page3' ">
        <form [formGroup]="notificationForm">
            <div class="aurow">
                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mb30">
                    {{languageData['price_alarm_stage3'].Var_notificationHeaderText || 'Please select how you want
                    to be notified:'}}</div>
                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mb30">

                    <div class="aucol-lg-1 aucol-md-1 aucol-sm-1 aucol-xs-1 emcheckBox">
                        <input class="inputCheckbox" type="checkbox" value="emailCheck" formControlName="emailCheck"
                            (change)="emailCheck=!emailCheck">
                    </div>

                    <div class="aucol-lg-5 aucol-md-7 aucol-sm-8 aucol-xs-11 ipfield">
                        <label class="posAbs aupLabel">{{languageData['price_alarm_stage3'].Var_Email ||
                            'Email:'}}</label>
                        <input autocomplete="email" class="auform-control forLabel widthh" formControlName="Email"
                            tabindex="1" />
                        <div *ngIf="EmailError" class="alert-danger">
                            {{languageData['Adresseingabe'].Var_email_validation || 'Geben Sie eine gültige Email an'}}
                        </div>
                        <div *ngIf="notificationForm.controls.Email.errors && notificationForm.controls.Email.touched">
                            <div class="alert-danger" *ngIf="notificationForm.controls.Email.errors?.pattern">
                                {{languageData['price_alarm_stage3'].Var_IncorrectMail || 'Falsche Email'}}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 " style="margin-bottom:7px;">
                    <div class="aucol-lg-1 aucol-md-1 aucol-sm-1 aucol-xs-1 emcheckBox">
                        <input class="inputCheckbox" type="checkbox" name="smsCheck" value="smsCheck"
                            formControlName="smsCheck" (change)="smsCheck=!smsCheck">
                    </div>
                    <div class="aucol-lg-5 aucol-md-7 aucol-sm-8 aucol-xs-11 ipfield">
                        <span class="new">+49</span>
                        <label class="posAbs aupLabel">{{languageData['price_alarm_stage3'].Var_SMS || 'SMS:'}}</label>
                        <input autocomplete="" style="text-indent:33px;" class="auform-control forLabel widthh"
                            formControlName="sms" tabindex="2" />
                        <div *ngIf="msgError" class="alert-danger">
                            {{languageData['Adresseingabe'].Var_mobile_validation || 'Geben Sie eine gültige Handynummeran'}}
                        </div>
                    </div>
                </div>

                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 txtcss" style="margin-bottom:50px">
                    <!-- {{languageData['price_alarm_stage3'].var_smsNumSuggestionText || 'Nur deutsche Handynummern. Muss mit "01" beginnen.'}} -->
                </div>
                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mb30" style="display:flex">
                    <div style="margin-right:1%;margin-top: 5px;">
                        {{languageData['price_alarm_stage3'].Var_activationText || 'Preisalarme sind aktiv für'}}
                    </div>
                    <div>
                        <select name="months" class="auform-control anrede" style="color:black;font-size:15px;" value=""
                            required selected formControlName="months" #select tabindex="3">
                            <option *ngFor="let month of months" [ngValue]="month">
                                {{month}} {{languageData['price_alarm_stage3'].var_months || 'Monate'}}</option>
                        </select>
                        <div class="alert-danger" *ngIf="monthSelectErr">
                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}
                        </div>
                    </div>

                </div>

                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">

                    <div class="aucol-lg-1 aucol-md-1 aucol-sm-1 aucol-xs-1 emcheckBox">
                        <input class="inputCheckbox" type="checkbox" style="vertical-align: super;" name="agreeCheck"
                            value="agreeCheck" formControlName="agreeCheck" (change)="agreeCheck=!agreeCheck">
                    </div>

                    <div class="aucol-lg-11 aucol-md-11 aucol-sm-11 aucol-xs-11">
                        {{languageData['price_alarm_stage3'].Var_agreeText || 'Ja, senden Sie mir Benachrichtigungen an
                        die angegebene E-Mail-Adresse und / oder Telefonnummer. Ich bin damit einverstanden, dass meine
                        Informationen für die Dauer der Alarmeinstellungen gespeichert werden. Mir ist bewusst, dass ich
                        die Alarme und Informationen jederzeit ohne Angabe von Gründen löschen kann.'}}
                        <div *ngIf="agreeCheckErr" class="alert-danger">
                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}
                        </div>
                    </div>
                </div>


                <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 mb30">
                    <div class="aurow pb20 " style="margin-top:5%">
                        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
                            <button type="button" class="cnlBtn font20 smalBtn"
                                (click)="abbrechen()">{{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}</button>
                            <button type="button" class="nxtBtn font20 pull-right smalBtn"
                                [ngClass]="theme == 'Theme1' ? 'marright':'marright2'"
                                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                [style.background]="'var(--primaryColour)'" (click)="submit()">
                                {{languageData['Adresseingabe'].Var_Weiter || 'Weiter'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div [ngClass]="{snackbar:true,show:viewsnackErr}">
    {{languageData['Bestatigung']['Var_Bitte_alle_Pflichtfelder_ausfüllen'] || 'Bitte alle Pflichtfelder ausfüllen!'}}
    <button mat-button class="close-icon" (click)="viewsnackErr = false">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div [ngClass]="{snackbar:true,show:viewsnackErr1}">
    {{languageData['Adresseingabe'].Var_type_notification || 'Eine Benachrichtigungsart muss gewählt sein'}}
    <button mat-button class="close-icon" (click)="viewsnackErr1 = false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div>
    <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
</div>