import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AdminService } from "app/Services/admin.service";
import { LanguageService } from '../../Services/language.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MeinepopupComponent } from '../meinepopup/meinepopup.component';
import { InventoyTrackingService } from "app/Services/inventoy-tracking.service";
import { LangReplacerPipe } from "app/lang-replacer.pipe";
export const tankGrossValidator: ValidatorFn = (control: FormGroup) => {
  const tankGross = control.get('tankGross');
  const currentFuel = control.get('currentFuel');

  if (tankGross && currentFuel && tankGross.value && currentFuel.value) {
    const tankValue = parseFloat(String(tankGross.value).replace('.', ''));
    const currentFuelValue = parseFloat(String(currentFuel.value).replace('.', ''));
    return tankValue < currentFuelValue ? { 'tankTooBig': true } : null;
  }
  return null;
};
export function minGerman(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = parseFloat(String(control.value).replace('.', ''));
    return isNaN(value) || value < min ? { 'min': { value: control.value } } : null;
  };
}
export function alarmsValidator(toggle1, toggle2, controlName1: string, controlName2: string): ValidatorFn {
  return (control: FormGroup) => {
    const control1 = control.get(controlName1);
    const control2 = control.get(controlName2);
    const toogle1 = control.get(toggle1).value;
    const toogle2 = control.get(toggle2).value;
    if(toogle1 && toogle2) { 
    if (control1 && control2 && control1.value && control2.value) {
      const control1Value = parseFloat(String(control1.value).replace('.', ''));
      const control2Value = parseFloat(String(control2.value).replace('.', ''));

      return control1Value > control2Value ? null : { 'invalidAlarms': true };
    }
  } else if(toogle1 && !toogle2) {
    return null;
  }
    return null;
  };
}
@Component({
  selector: "app-meine-heizung-setup",
  templateUrl: "./meine-heizung-setup.component.html",
  styleUrls: ["./meine-heizung-setup.component.css"],
})

export class MeineHeizungSetupComponent implements OnInit {
  currentSetupStage = 1;
  language: string = localStorage.getItem("appLanguage") || "GEN";
  languageData: any = {};
  preisrechnerTranslations: any = {};
  stageTwo: boolean = false;
  stageTwoForm: FormGroup;
  stageThreeForm: FormGroup;
  stageFourForm: FormGroup;
  tempEle = false;
  toggleSwitchRange: boolean = true;
  HouseTypes = [];
  selectedSVG: string = "";
  isInputEnabled: boolean = false;
  tiles: any = [];
  selectedTiles = [];
  heatingSystem = [];
  svgHtmls = {};

  svgs = [
    environment.assetsUrl +
    "assets/Trackingofinventoriessvgs/HomeHeatercopy.svg",
    environment.assetsUrl + "assets/EditIcon.svg",
    environment.assetsUrl + "assets/Trackingofinventoriessvgs/family.svg",
    environment.assetsUrl + "assets/Trackingofinventoriessvgs/roomTemperature.svg",
    environment.assetsUrl + "assets/Trackingofinventoriessvgs/heatLimit.svg",
    environment.assetsUrl + "assets/Trackingofinventoriessvgs/Triangle.svg",
    environment.assetsUrl + "assets/Trackingofinventoriessvgs/Triangleflipped.svg",
    // environment.assetsUrl + "assets/KeineSolarthermie.svg",
    environment.assetsUrl + "assets/Keine Solarthermie.svg",
    environment.assetsUrl + "assets/Solarthermie.svg",
  ];
  steps = [
    { label: "Step 1" },
    { label: "Step 2" },
    { label: "Step 3" },
    { label: "Step 4" },
  ];
  setUpStages: any = {};

  existedFirstRowTiles = [];
  existedSecondRowTiles = [];
  langData: any;
  solarHouses: any = [];
  stageFourunits: string;
  langData1: any;
  stge1FinalData: any = { product: {}, heatingType: {} };
  stage1Check = false
  inventoryOverAllData: any = [];
  stage2FinalData: any = {};
  currentoptionIndex: number = 0;
  option: any;
  heatingSystemIndex: number;
  allHeatingSytems: any[];
  startIndex = 0;
  endIndex = 3;
  productStart = 0;
  productEnd = 3;
  houseStartIndex = 0;
  houseEndIndex = 3;
  langVariable = localStorage.getItem("language") || "GEN";
  selectedUnits: any;
  standardConsumption: any = 0;
  inputConsumptionChanged: boolean = true;
  constructor(private langService: LanguageService, private fb: FormBuilder, public httpClient: HttpClient, public sanitizer: DomSanitizer, public dialog: MatDialog,
    public dialogRef: MatDialogRef<MeineHeizungSetupComponent>, @Inject(MAT_DIALOG_DATA) public sourceData: any, private adminService: AdminService, private inventoryTracking: InventoyTrackingService,
    private http: HttpClient, private cdr: ChangeDetectorRef, private el: ElementRef,
    private inventoryService: InventoyTrackingService
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['kundeportal'] || {};
    this.preisrechnerTranslations = langData['preisrechner'] || {};
    this.langData = langData['web-pc'] || {};
    this.langData1 = langData['administration'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data["kundeportal"] || {};
      this.preisrechnerTranslations = data["preisrechner"] || {};
      this.langData = langData["web-pc"] || {};
      this.langData1 = langData["administration"] || {};
    });
    // this.langService.onLanguageChange.subscribe((data: any) => {
    //   this.languageData = data['web-pc'] || {};
    // });
  }

  async getImageUrl(id: string, tile) {
    let data = await this.adminService.getLogoImageById(id);
    tile
    if (data.success) {
      return this.sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${data.data.data}`);
    }
  }
  byPassSVGSecurity(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  // byPassSVGSecurity(url): SafeHtml {
  //   let svgHtml: SafeHtml;
  //   console.log(url, 123);
  //   this.http.get(url, { responseType: 'text' }).subscribe(data => {
  //     svgHtml = this.sanitizer.bypassSecurityTrustHtml(data);
  //   });
  //   console.log(svgHtml, 124);
  //   return svgHtml;
  // }
  async loadImages(data) {
    const promises = data.map(async tile => {
      tile['imageUrl'] = await this.getImageUrl(tile.svgId, tile);
    });

    await Promise.all(promises);
  }
  loader: boolean = false;
  byPassSVGSecurityItem(url: string, id: string): void {
    // console.log(url, id, 1432)
    this.http.get(url, { responseType: 'text' }).subscribe(data => {
      this.svgHtmls[id] = this.sanitizer.bypassSecurityTrustHtml(data);

      this.cdr.detectChanges();
      this.changeColor();

    });
    // console.log(this.svgHtmls,143)
  }
  async ngOnInit() {
    let tempSvgs = [];
    // console.log(this.sourceData ,4544);
    if (this.sourceData?.edit) {
      this.sourceData = { ...this.sourceData, weatherId: this.sourceData.address.weatherId, sunhourStation: this.sourceData.address.sunHoursProvideWeatherStation }
      console.log(this.sourceData, 176);
      this.setUpStages = { ...this.sourceData.address, stageTwo: true, stageThree: true, stageFour: true, };
      this.stage2FinalData['houseTypes'] = { _id: this.sourceData.address.houseType };
      // this.stge1FinalData = {  _id: this.sourceData.address.product,
      //   heaterType: {_id:this.sourceData.address.heatingType},}
    };

    this.getDynamicSVG(this.svgs);
    // this.loadImages(this.tiles);
    this.loader = true;
    this.inventoryTracking.fetchInventoryProducts({}).subscribe((data: any) => {
      if (data.success) {
        this.inventoryOverAllData = data.data;
        // console.log(this.inventoryOverAllData,141);
        this.inventoryOverAllData.forEach((data) => {
          data.svgFullPath && this.byPassSVGSecurityItem(data.svgFullPath, data.svgUrl);
          data.hasOwnProperty('heatingSystems') && data.heatingSystems.map((heatingSystem) => {
            // data.svgFullPath && this.byPassSVGSecurityItem(heatingSystem.svgFullPath, data.svgFullPath);
            heatingSystem.svgFullPath && this.byPassSVGSecurityItem(heatingSystem.svgFullPath, heatingSystem.svgUrl);
          })
        });
        // tempSvgs = tempSvgs.filter((item) => item !== undefined);
        // console.log(tempSvgs, 142);

        this.tiles = data.data.filter(xx => xx.type == 'products');
        this.tiles = this.tiles.map(xx => {
          xx.isSelected = false;
          return xx;
        })
        this.loader = false;
        if (this.sourceData?.edit) {
          let findProductIndex = this.tiles.findIndex(o => o._id == this.sourceData.address.product);
          //  console.log(findProductIndex);
          this.selectTile(0, this.tiles[findProductIndex], 'product');
          this.selectTile(0, { _id: this.sourceData.address.heaterType }, '');
        } else {
          this.selectTile(0, this.tiles[0], 'product')
        };

        // let products = data.data[0];
        // let heatingSystems =products['heatingsystems'];
        //  let products = data.data.filter(x => x.type === 'products');
        //  console.log(products,165);
        //  this.tiles = products;

        //  console.log(this.tiles,5454);

        //  this.tiles = this.tiles.concat(products.heatingSystems);
        //  this.loadImages(products);
        // this.tiles= data.data.map(async (product) => {
        //   product['imageUrl']=   await this.getImageUrl(product.svgId, {});
        //   product['heatingSystems'].map(async (heatingSystem) => {
        //     heatingSystem['imageUrl'] = await this.getImageUrl(heatingSystem.svgId, {});
        //   })
        // });
      } else {
        this.loader = false;
      }
    }, (err) => this.loader = false);
  };

  selectTile(index: number, tileData, type): void {
    if (type === 'product') {
      this.tiles = this.tiles.map((tile) => {
        if (tile._id === tileData._id) {
          this.changeColor();
          /** The below if condition removes the previous data while shifting products */
          if (Object.keys(this.stge1FinalData.product).length && tile._id !== this.stge1FinalData.product._id) {
            let oldProduct = this.tiles.find(tile => tile._id == this.stge1FinalData.product._id);
            if (oldProduct) {
              this.heatingSystem = oldProduct.heatingSystems?.map((tile) => {
                tile.isSelected = false;
                return tile;
              });
            }
            // Reset heatingType when switching product
            this.stge1FinalData.heatingType = {};
            this.stage1Check = false;
          }
          tile.isSelected = !tile.isSelected;
          if (tile.isSelected) {
            this.stge1FinalData['product'] = { _id: tileData._id, name: tileData.name };
            if (tileData.heatingSystems?.length) {
              this.allHeatingSytems = [].concat(tileData.heatingSystems);
              this.heatingSystem = [].concat(tileData.heatingSystems);
            } else {
              this.heatingSystem = []
            }
          } else {
            this.heatingSystem = this.heatingSystem.map((tile) => {
              tile.isSelected = false;
              return tile;
            })
            this.heatingSystem = [];
            this.stge1FinalData['heatingType'] = {};
            this.stge1FinalData['product'] = {};
          }
        } else {
          tile.isSelected = false;
        }
        return tile;
      });
      this.changeColor();
    } else {
      this.heatingSystem = this.heatingSystem.map((tile) => {
        if (tile._id === tileData._id) {
          tile.isSelected = !tile.isSelected;
          if (tile.isSelected) {
            this.stge1FinalData['heatingType'] = { _id: tileData._id, name: tileData.name };
          } else {
            this.stge1FinalData['heatingType'] = {};
          }
        } else {
          tile.isSelected = false;
        }
        return tile;
      });
    }
    if (Object.keys(this.stge1FinalData.product).length > 0 && Object.keys(this.stge1FinalData.heatingType).length > 0) {
      this.stage1Check = true;
    } else {
      this.stage1Check = false;
    }
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split(".svg")[0];
      let iconNameArray = first.split("/");
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: "text" }).subscribe((logo) => {
        logo = logo.replace(/#333/gi, "var(--primaryColour)"); //fill
        logo = logo.replace(/#111/gi, "var(--highlightColour)"); //storke
        logo = logo.replace(/#444/gi, "var(--colorShade1)"); //shade1
        logo = logo.replace(/#555/gi, "var(--colorShade2)"); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
    });
  };

  getSVG(name) {
    return this[name];
  };

  stageName(stage) {
    switch (stage) {
      case 1:
        return (
          this.languageData["Dashboard"]["VarKundeportalMeine_Heizung8"] ||
          "Heizung"
        );
      case 2:
        return this.languageData.Meine_Heizung.VarKundeportalMeine_Heizung12;
      case 3:
        return "Solarthermie";
      case 4:
        return "Bestandstracking";
    }
  };

  tileName(name) {
    switch (name) {
      case "Var_Heizöl":
        return this.languageData["Dashboard"]["Var_Heizöl"] || "Heizöl";
      case "Var_pellets":
        return this.languageData["Dashboard"]["Var_pellets"] || "Pellets";
      case "Var_gas":
        return this.languageData["Dashboard"]["Var_gas"] || "Gas";
      case "VarKundeportalMeine_Heizung9":
        return (
          this.languageData["Dashboard"]["VarKundeportalMeine_Heizung9"] ||
          "Alter Standard"
        );
      case "VarKundeportalMeine_Heizung10":
        return (
          this.languageData["Dashboard"]["VarKundeportalMeine_Heizung10"] ||
          "Niedertemperatur"
        );
      case "VarKundeportalMeine_Heizung11":
        return (
          this.languageData["Dashboard"]["VarKundeportalMeine_Heizung11"] ||
          "Brennwert"
        );
    };
  };

  close() {
    this.dialogRef.close();
  };

  saveStage1() {
    if (Object.keys(this.stge1FinalData.product).length > 0 && Object.keys(this.stge1FinalData.heatingType).length > 0) {
      const { product, heatingType } = this.stge1FinalData;
      this.setUpStages = {
        product: product._id,
        heaterType: heatingType._id,
        ...this.setUpStages,
      };
      this.currentSetupStage = 2;
      this.prepareData();
      this.updateUnits(product._id);
    };
  };

  prepareData() {
    this.changeColor();
    this.stageTwoForm = this.fb.group({
      tankType: ["", [Validators.required]],
      tempeature: [
        21,
        [
          Validators.required,
          Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/),
          Validators.min(15),
          Validators.max(30),
        ],
      ],
      familyCount: [
        3,
        [Validators.required, Validators.min(1), Validators.max(20)],
      ],
      heatedArea: [, [Validators.required, Validators.pattern(/^[0-9]+$/)]],
    });
    setTimeout(() => {
      this.tempEle = true;
    }, 200);
    this.HouseTypes = this.inventoryOverAllData.filter(x => x.type === 'houses');

    if (this.setUpStages && this.setUpStages?.stageTwo) {
      let { houseType, familySize, roomTempeature, houseArea } = this.setUpStages;
      const stageTwoFormValues = {
        tankType: houseType,
        familyCount: familySize,
        tempeature: roomTempeature,
        heatedArea: houseArea,
      };
      if (this.sourceData?.edit) {
        let index = this.HouseTypes.findIndex(obj => obj._id == houseType);
        index = index + 1;
        houseType = index
      };

      this.stageTwoForm.patchValue(stageTwoFormValues);
      this.HouseTypes[houseType - 1]["show"] = true;
    };
  };

  selectedHouse(house) {

    this.HouseTypes = this.HouseTypes.map((obj) => {
      if (obj._id == house._id) {
        obj.show = true;
        this.stageTwoForm.controls.tankType.setValue(house._id);
      } else {
        obj.show = false;
      }
      return obj;
    })
    this.stage2FinalData['houseTypes'] = { _id: house._id, name: house.name };
    // let index = this.HouseTypes.findIndex((obj) => obj.id == house.id);
    // let checked = this.HouseTypes.find((obj) => obj.id == house.id);
    // if (checked.show) {
    //   this.stageTwoForm.controls.tankType.setValue("");
    //   this.HouseTypes[index]["show"] = false;
    //   return;
    // }
    // this.HouseTypes = this.HouseTypes.map((obj) => ({ ...obj, show: false }));
    // this.stageTwoForm.controls.tankType.setValue(house.id);
    // if (index >= 0) {
    //   this.HouseTypes[index]["show"] = true;
    // }
  };

  prepareSolar() {
    this.stageThreeForm = this.fb.group({
      solar: ["", [Validators.required]],
      support: [false],
      flache: [],
    });
    this.solarHouses = [
      {
        type: this.languageData.Meine_Heizung.VarKundeportalMeine_Heizung22,
        show: false,
        icon: "Solarthermie",
        id: 1,
      },
      {
        type: this.languageData.Meine_Heizung.VarKundeportalMeine_Heizung23,
        show: false,
        icon: "Keine Solarthermie",
        id: 0,
      },
    ];

    if (this.setUpStages && this.setUpStages?.stageThree) {
      const { solarEnergy, heatingSupport, solarPannelArea } = this.setUpStages;
      const stageThree = {
        solar: solarEnergy,
        support: heatingSupport,
        flache: solarPannelArea,
      };
      console.log(stageThree, 46222);
      this.stageThreeForm.patchValue(stageThree);
      this.isInputEnabled = solarEnergy ? this.solarHouses[0]["show"] = true : this.solarHouses[1]["show"] = true;
      this.isInputEnabled = solarEnergy ? true : false;
      if(this.isInputEnabled) {
        this.stageThreeForm.get("flache").setValidators([Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)]);
        this.stageThreeForm.get("flache").updateValueAndValidity();
      }
      // this.solarHouses[solarEnergy - 1]["show"] = true;
      // this.isInputEnabled = solarEnergy == 1 ? true : false;
    } else {
      this.isInputEnabled = false;
      this.resetInputAndToggle();
    };
  };

  solarHouse(solar, selectedIndex) {
    // let index = this.solarHouses.findIndex((obj) => obj.id == solar.id);
    // let checked = this.solarHouses.find((obj) => obj.id == solar.id);
    // if (checked.show) {
    //   this.solarHouses[index]["show"] = false;
    //   this.stageThreeForm.controls.solar.setValue("");
    //   this.isInputEnabled = false;
    //   this.resetInputAndToggle();
    //   return;
    // };

    // this.solarHouses = this.solarHouses.map((obj) => ({ ...obj, show: false }));
    // this.stageThreeForm.controls.solar.setValue(solar.id);
    // if (index >= 0) {
    //   this.solarHouses[index]["show"] = true;
    //   if (solar.id == 1) {
    //     this.isInputEnabled = true;
    //     this.enableInputAndToggle();
    //     this.stageThreeForm
    //       .get("flache")
    //       .setValidators([
    //         Validators.required,
    //         Validators.min(1),
    //         Validators.pattern(/^[0-9]+$/),
    //       ]);
    //     this.stageThreeForm.get("flache").updateValueAndValidity();
    //   } else {
    //     this.isInputEnabled = false;
    //     this.resetInputAndToggle();
    //   }
    // };

    let index = selectedIndex;
    let checked = this.solarHouses.find((obj) => obj.id == solar.id);
    if (checked.show) {
      this.solarHouses[index]["show"] = false;
      this.stageThreeForm.controls.solar.setValue("");
      this.isInputEnabled = false;
      this.resetInputAndToggle();
      return;
    };

    this.solarHouses = this.solarHouses.map((obj) => ({ ...obj, show: false }));
    this.stageThreeForm.controls.solar.setValue(solar.id);
    if (index >= 0) {
      this.solarHouses[index]["show"] = true;
      if (solar.id == 1) {
        this.isInputEnabled = true;
        this.enableInputAndToggle();
        this.stageThreeForm.get("flache").setValidators([Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)]);
        this.stageThreeForm.get("flache").updateValueAndValidity();
      } else {
        this.isInputEnabled = false;
        this.resetInputAndToggle();
      };
    };
  };

  enableInputAndToggle() {
    this.stageThreeForm.get("flache").updateValueAndValidity();
    this.stageThreeForm.get("support").enable();
    this.stageThreeForm.get("support").updateValueAndValidity();
  };

  resetInputAndToggle() {
    this.stageThreeForm.get("flache").setValue("");
    this.stageThreeForm.get("flache").setValidators([]);
    this.stageThreeForm.get("flache").updateValueAndValidity();

    this.stageThreeForm.get("support").setValue(false);
    this.stageThreeForm.get("support").disable();
    this.stageThreeForm.get("support").updateValueAndValidity();
  };

  stageThreeSupport(event) {
    this.stageThreeForm.controls["support"].setValue(event);
  };

  validateInput(event: any) {
    let input = event.target;
    input.value = input.value.replace(/[^0-9]/g, "");
  };

  prepareStageFour() {
    // console.log(this.setUpStages, 751);
    this.stageFourForm = this.fb.group({
      consumptionFuel: [this.setUpStages['consumptionFuel'] || '', [Validators.required, Validators.min(1), Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/)]],
      tankGross: [this.setUpStages['tankGross'] || '', [Validators.required, Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/), Validators.min(1)]],
      currentFuel: [this.setUpStages['currentFuel'] || '', [Validators.required, Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/), Validators.min(1)]],
      fuelRemainder: [8, [Validators.required, Validators.pattern("^[0-9]+$")]],
      toogle1: [true],
      toogle2: [true],
      rangeWarning: [30],
      Bestandsalarm1: [this.germanConversion(1500)],
      Bestandsalarm2: [this.germanConversion(900)],
      myCheckbox: [false, Validators.requiredTrue],
      toggleSwitchRange: [true],
    }, { validators: [tankGrossValidator, alarmsValidator('toogle1', 'toogle2', 'Bestandsalarm1', 'Bestandsalarm2')] });
      this.handleConditionalValidation();
    if (this.setUpStages && this.setUpStages?.stageFour) {
      const {
        grossConsumption,
        cosumptionTolarance,
        tankSize,
        currentTankFuelLevel,
        reminderRange,
        reminderRangeValue,
        reminderAlert,
        reminderAlertValue,
        reminderAlertOne,
        reminderAlertOneValue,
      } = this.setUpStages;
      const stageFour = {
        consumptionFuel: this.germanConversion(grossConsumption),
        fuelRemainder: cosumptionTolarance,
        tankGross: this.germanConversion(tankSize),
        currentFuel: this.germanConversion(currentTankFuelLevel),
        rangeWarning: reminderRange,
        toggleSwitchRange: reminderRangeValue,
        toogle1: reminderAlert,
        Bestandsalarm1: this.germanConversion(reminderAlertValue),
        toogle2: reminderAlertOne,
        Bestandsalarm2: this.germanConversion(reminderAlertOneValue),
        myCheckbox: true,
      };
      this.stageFourForm.patchValue(stageFour);
    };
  };

  handleConditionalValidation() {
    this.stageFourForm.get('toogle1')?.valueChanges.subscribe((value) => {
      const Bestandsalarm1 = this.stageFourForm.get('Bestandsalarm1');
      if (value) {
        Bestandsalarm1?.setValidators([Validators.required, minGerman(500), Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/)]);
      } else {
        Bestandsalarm1?.clearValidators();
      }
      Bestandsalarm1?.updateValueAndValidity();
    });

    this.stageFourForm.get('toggleSwitchRange')?.valueChanges.subscribe((value) => {
      const rangeWarning = this.stageFourForm.get('rangeWarning');
      if (value) {
        rangeWarning?.setValidators([Validators.required, Validators.min(10), Validators.pattern("^[0-9]+$"),]);
      } else {
        rangeWarning?.clearValidators();
      }
      rangeWarning?.updateValueAndValidity();
    });

    this.stageFourForm.get('toogle2')?.valueChanges.subscribe((value) => {
      const Bestandsalarm2 = this.stageFourForm.get('Bestandsalarm2');
      if (value) {
        Bestandsalarm2?.setValidators([Validators.required, minGerman(250), Validators.pattern(/[0-9]+(\.[0-9][0-9]?)?/)]);
      } else {
        Bestandsalarm2?.clearValidators();
      }
      Bestandsalarm2?.updateValueAndValidity();
    });
  }

  tempeatureUpdate(value, type) {
    if ((type == "i" && value >= 30) || (type == "d" && value <= 15)) return;
    value = type == "i" ? value + 1 : value - 1;
    this.stageTwoForm.controls.tempeature.setValue(value);
  };

  navigateToStageOne() {
    this.changeColor();
    this.currentSetupStage = 1;
  };

  navigateToStageTwo() {
    this.changeColor();
    this.currentSetupStage = 2;
  };

  navigateToStageThree() {
    this.currentSetupStage = 3;
    let { Bestandsalarm1, Bestandsalarm2, consumptionFuel, currentFuel, fuelRemainder, tankGross, rangeWarning } = this.stageFourForm.value;
    //  Bestandsalarm1 = Bestandsalarm1.replace('.', '');
    //  Bestandsalarm2 = Bestandsalarm2.replace('.', '');
    //  consumptionFuel = consumptionFuel.replace('.', '');
    //  currentFuel = Bestandsalarm1.replace('.', '');
    //  fuelRemainder = Bestandsalarm1.replace('.', '');
    //  fuelRemainder = Bestandsalarm1.replace('.', '');
    localStorage.setItem('stageFourForm', JSON.stringify(this.stageFourForm.value));

    this.setUpStages = {
      ...this.setUpStages,
      ...this.stageFourForm.value,
      stageThree: true,
    };
    // console.log(this.setUpStages)
  };

  saveStageTwo() {
    this.setUpStages = {
      ...this.setUpStages,
      houseType: this.stageTwoForm.value.tankType,
      houseArea: this.stageTwoForm.value.heatedArea,
      roomTempeature: this.stageTwoForm.value.tempeature,
      familySize: this.stageTwoForm.value.familyCount,
      stageTwo: true,
    };
    this.currentSetupStage = 3;
    this.prepareSolar();
  };

  saveStageThree() {
    this.setUpStages = {
      ...this.setUpStages,
      solarEnergy: this.stageThreeForm.value.solar,
      heatingSupport: this.stageThreeForm.value.support ? 1 : 0,
      solarPannelArea: "",
      stageThree: true,
    };
    if (this.stageThreeForm.value.solar == 1) {
      this.setUpStages = {
        ...this.setUpStages,
        solarPannelArea: this.stageThreeForm.value.flache,
      };
    };
    console.log(this.setUpStages, 677);
    this.currentSetupStage = 4;
    this.prepareStageFour();
    if (!this.sourceData?.edit) {
      this.getStandanrdConsumption(this.setUpStages)
    }
  };

  saveAllStages() {
    let productId = this.stge1FinalData.product['_id'];
    let heaterTypeId = this.stge1FinalData.heatingType['_id'];
    let houseTypeId = this.stage2FinalData.houseTypes['_id'];

    let test = {
      productId: '' + productId,
      heaterTypeId: '' + heaterTypeId,
      houseTypeId: '' + houseTypeId,
    };

    this.setUpStages = {
      ...this.setUpStages,

      grossConsumption: parseFloat(String(this.stageFourForm.value.consumptionFuel).replace('.', '')),
      cosumptionTolarance: this.stageFourForm.value.fuelRemainder,
      tankSize: parseFloat(String(this.stageFourForm.value.tankGross).replace('.', '')),
      currentTankFuelLevel: parseFloat(String(this.stageFourForm.value.currentFuel).replace('.', '')),
      reminderRange: this.stageFourForm.value.rangeWarning || this.stageFourForm?.get('rangeWarning').value,
      reminderRangeValue: this.stageFourForm.value.toggleSwitchRange,
      reminderAlert: this.stageFourForm.value.toogle1,
      reminderAlertValue: parseFloat(String(this.stageFourForm?.value?.Bestandsalarm1 || this.stageFourForm?.get('Bestandsalarm1').value).replace('.', '')),
      reminderAlertOne: this.stageFourForm.value.toogle2,
      reminderAlertOneValue: parseFloat(String(this.stageFourForm?.value?.Bestandsalarm2 || this.stageFourForm?.get('Bestandsalarm2').value).replace('.', '')),
      stageFour: true,
      productDetails: test,
      weatherId: this.sourceData.weatherId,
      sunhourStation: this.sourceData.sunHoursProvideWeatherStation,
    };
    // this.dialogRef.close({ success: true, data: this.setUpStages });
    Object.entries(this.setUpStages).forEach(([key, value]) => {
      if (typeof value === 'string' && value !== '') {
        this.setUpStages[key] = parseInt(value, 10);
      };
    });
    ['stageFour', 'stageThree', 'stageTwo'].forEach(property => {
      if (this.setUpStages.hasOwnProperty(property)) {
        delete this.setUpStages[property];
      };
    });


    console.log(this.setUpStages, 724);
    if (this.sourceData?.edit) {
      console.log(this.setUpStages, 751);
      this.dialogRef.close({
        success: true, data: {
          ...this.setUpStages,
          weatherId: this.sourceData.weatherId,
          sunhourStation: this.sourceData.sunHoursProvideWeatherStation,
          calculateQA : true
        }
      })
      return;
    };

    // this.loader = true;
    // let stageData = { ...this.setUpStages, addressId: this.stepProcessInitalize['address']._id }
    let stageData = {
      ...this.setUpStages, weatherId: this.sourceData.weatherId,
      sunhourStation: this.sourceData.sunHoursProvideWeatherStation,
      addressId: this.sourceData.addressId,
      calculateQA : true
    };
    if(!this.sourceData?.edit){
      this.inputConsumptionChanged = stageData.grossConsumption != this.standardConsumption;
      if(!this.inputConsumptionChanged){
        let findHouse = this.HouseTypes.find(house => house._id == stageData.productDetails.houseTypeId);
       stageData = {...stageData , QA :findHouse?.houseQA , calculateQA : false }
      };
    };
    console.log(stageData, 35454);

    this.adminService.saveInventoryData({ inventoryData: stageData }).subscribe(resp => {
      if (resp.success) {
        this.loader = false;
        this.dialogRef.close({ success: true, data: this.setUpStages });
        // this.showSnackbar("Heating data saved successfully");
        // window.location.reload();
        this.inventoryService.emitNewHeatingReocrd('update');
        this
      } else {
        // this.showFullScreenLoading = false;
      };
    });
  };

  toggleSwitch(event: any, type: any) {
    const toggle1 = this.stageFourForm.get("toogle1");
    const toggle2 = this.stageFourForm.get("toogle2");
    const alarm1 = this.stageFourForm.get("Bestandsalarm1");
    const alarm2 = this.stageFourForm.get("Bestandsalarm2");
    const rangeWarning = this.stageFourForm.get("rangeWarning");
    if (type === "Bestandsalarm1") {
      if (event) {
        alarm1?.enable();
        toggle1?.setValue(true);
      } else {
        alarm2?.disable();
        alarm1?.disable();
        toggle2?.setValue(false);
        this.stageFourForm.markAsPristine();
      }
    } else if (type === "Bestandsalarm2" && toggle1?.value === true) {
      if (event) {
        alarm2?.enable();
        toggle2?.setValue(true);
      } else {
        alarm2?.disable();
        toggle2?.setValue(false);
      }
    } else if (type === "Bestandsalarm2" && toggle1?.value === false) {
      alarm2?.disable();
      toggle2?.setValue(false);
    } else if (type === "rangeWarning") {
      event ? rangeWarning?.enable() : rangeWarning?.disable();
    }
  };

  openHyperlink() {
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        type: "hyperlink",
      },
    });
    meineopen.afterClosed().subscribe(async (data: any) => { });
  };

  moveToNext() {
    this.changeColor();
    if (this.productEnd < this.tiles.length) {
      this.productStart++;
      this.productEnd++;
    };
  };

  moveToPrevious() {
    this.changeColor();
    if (this.productStart > 0) {
      this.productStart--;
      this.productEnd--;
    }
  };

  moveNext() {
    this.changeColor();
    if (this.endIndex < this.allHeatingSytems.length) {
      this.startIndex++;
      this.endIndex++;
    };
  };

  movePrevious() {
    this.changeColor();
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    };
  };

  moveNextHouse() {
    this.changeColor();
    if (this.houseEndIndex < this.HouseTypes.length) {
      this.houseStartIndex++;
      this.houseEndIndex++;
    };
  };

  movePreviousHouse() {
    this.changeColor();
    if (this.houseStartIndex > 0) {
      this.houseStartIndex--;
      this.houseEndIndex--;
    };
  };

  changeColor() {
    setTimeout(() => {
      let svgs = this.el.nativeElement.getElementsByTagName('svg');
      for (let i = 0; i < svgs.length; i++) {
        let paths = svgs[i].getElementsByTagName('path');
        for (let j = 0; j < paths.length; j++) {

          if (paths[j].outerHTML != '<path d="M44 0L87.3013 75H0.69873L44 0Z" fill="red"></path>') {
            let primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour');
            primaryColour = primaryColour.trim();
            paths[j].setAttribute('fill', primaryColour);
          }
        }
      }
    }, 0);
  };

  ngAfterViewInit() {
    console.log("ngAfterViewInit");
    this.changeColor();
    this.cdr.detectChanges();
  };

  numberFormat(event, name) {
    // console.log(name, 751)
    // console.log(event);
    if (event.includes(",")) {
      event = event.replace('.', '');
    };
    let value = event;
    let replacedPipe = new LangReplacerPipe();
    let divider = replacedPipe.transform(event, this.langVariable);
    divider = divider.split(',')[0];
    this.stageFourForm.get(name).setValue(divider);
    // if (value.length >= 4) {
    //   divider = value
    // } else {
    //   divider = divider.replace(",", '.')
    // }
    console.log(divider, 757);
  };

  germanConversion(event) {
    let replacedPipe = new LangReplacerPipe();
    let divider = replacedPipe.transform(event, "GEN");
    divider = divider && divider.split(',')[0];
    return divider;
  };

  onKeyPress(event: KeyboardEvent) {
    // console.log(event ,4545);
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'];
    // console.log(!allowedKeys.includes(event.key) , !event.key.match(/^[a-zA-Z0-9 -]+$/) , !event.key.match(/^[a-zA-Z0-9]+$/));
    if (!allowedKeys.includes(event.key) && !event.key.match(/^[a-zA-Z0-9]+$/)) {
      event.preventDefault();
    };
  };
  updateUnits(matchId){
    let findProduct =  this.tiles.find(o => o._id == matchId);
    this.selectedUnits =findProduct.units.name;
  };

  getStandanrdConsumption(selectedData) {
    this.loader = true;
    let findHouse = this.HouseTypes.find(house => house._id == selectedData.houseType);
    let product = this.tiles.find(tile => tile._id == this.stge1FinalData.product._id)
    let heatingSystem = this.heatingSystem.find(heat => heat._id == this.stge1FinalData.heatingType._id);
    
    let payloadForConsumption = {
      QA: findHouse?.houseQA || 0,
      Area: selectedData?.houseArea || 0,
      persons: selectedData?.familySize || 0,
      days: 365,
      productId: this.stge1FinalData.product._id,
      heaterTypeId: this.stge1FinalData.heatingType._id,
      houseId: selectedData?.houseType,
      roomTemp: selectedData?.roomTempeature || 0,
      addressId: this.sourceData?.addressId,
      weatherId: this.sourceData?.weatherId,
      efficiency: heatingSystem?.efficiency || 0,
      energy: product?.energy || 0,
      solarPanel : selectedData?.solarPannelArea || 0,
      solarEnergy : selectedData?.solarEnergy,
      houseThresholdValue : findHouse?.temperatureThreshold || 0,
      solarHeatingSupport : selectedData.heatingSupport || false
    }
    this.inventoryService.getConsumption(payloadForConsumption).subscribe(data => {
      if (data.success && data?.consumption > 0) {
        this.standardConsumption = data?.consumption;
        this.numberFormat(this.standardConsumption.toString(), 'consumptionFuel');
        this.inputConsumptionChanged = false;
        this.loader = false;

      } else {
        this.standardConsumption = 0;
        this.loader = false;

      }
    }, (err) => {
      this.standardConsumption = 0;
      this.loader = false;

    })
  }
};