import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, } from '@angular/material/dialog';
import { SmallPcService } from '../Services/small-pc.service';
import { environment } from '../../environments/environment';
import { WindowRefService, ICustomWindow } from '../Services/window.service'
import { CommonService } from 'app/common.service';
import { PortalAuthService, } from 'app/login/auth.service';
import { LanguageService } from '../Services/language.service';
import { AdminService } from '../Services/admin.service';
import { Subject } from 'rxjs';
import { EmergencyHotlineComponent } from 'app/emergency-hotline/emergency-hotline.component';
import { BigpcService } from 'app/Services/bigpc.service';
import { UserInfo } from 'app/models/portalModels/userInfo.interface';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { OrderService } from 'app/Services/order.service';
@Component({
  selector: 'app-firststage',
  templateUrl: './firststage.component.html',
  styleUrls: ['./firststage.component.css']
})
export class FirststageComponent implements OnInit {

  @Output() logoutsmallpc = new EventEmitter<boolean>();

  isLoggedIn: boolean = false;
  failureMsg: string;
  bigpclink: any;

  @Input() registration_at_order;
  @Input("productInfoForWebPC") productInfoForWebPC: any;
  @Input("portalTermsAndConditions") portalTermsAndConditions: any;
  @Input("alarmSettings") alarmSettings: any;


  isGuestUser: boolean;

  buttontag: boolean = false;
  rightAroow: boolean = true;
  menubutton: boolean = false;
  toggbutton: boolean = true;
  primaryColor: any;
  screenHeight: number;
  hotlineStatus: any;
  screenWidth: number;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  private $unsubscriber: Subject<any> = new Subject();
  el;
  activetab = "Heizöl";


  @Input("logOutUser") logOutUser: boolean;

  hostname: any;
  url: any;
  bigpc: boolean = false;
  private _window: ICustomWindow;
  @Output() userLoggedIn: EventEmitter<{ userLogin: boolean, guestLogin: boolean }> = new EventEmitter();
  userInfo: UserInfo;

  userLogin: boolean;
  guestLogin: boolean;
  addNewAddress: Boolean;
  photoUrl: any;
  pricealarm: boolean = false;
  showPriceData: boolean;
  sessionData: any;
  sessionId: string;
  page: string;
  address: any;

  svgs = [
    environment.assetsUrl + 'assets/svgs/arrowIn.svg',
    environment.assetsUrl + 'assets/svgs/arrowOut.svg',
    environment.assetsUrl + 'assets/svgs/dashboard.svg',
    environment.assetsUrl + 'assets/svgs/priceAlarm.svg',
    environment.assetsUrl + 'assets/svgs/nopricealaram.svg',
    environment.assetsUrl + 'assets/svgs/noorders.svg',
    environment.assetsUrl + 'assets/svgs/orders.svg',
    environment.assetsUrl + 'assets/svgs/noadress.svg',
    environment.assetsUrl + 'assets/svgs/adress.svg',
    environment.assetsUrl + 'assets/svgs/nosettings.svg',
    environment.assetsUrl + 'assets/svgs/settings.svg',
    environment.assetsUrl + 'assets/svgs/logout.svg',
    environment.assetsUrl + 'assets/svgs/pcLogo.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/HomeHeater.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/noHomeHeater.svg',
    environment.assetsUrl + 'assets/svgs/noPcLogo.svg',
    environment.assetsUrl + 'assets/svgs/dashboard1.svg',
    environment.assetsUrl + 'assets/svgs/hotline.svg',
    environment.assetsUrl + 'assets/svgs/BackIcon.svg',
    environment.assetsUrl + 'assets/svgs/MenuIcon.svg',
  ];
  logoutHover: boolean = false;


  levels = [
    {
      no: 1,
      name: "Bestelloptionen"
    },
    {
      no: 2,
      name: "Adressingabe"
    },
    {
      no: 3,
      name: "Bestellübersicht"
    },
    {
      no: 4,
      name: "Bestatigung"
    },
  ];
  @Input("thridStagePdfs") thridStagePdfs: any;
  fromAdreses = { show: false , address: {} };
  
  constructor(
    private langService: LanguageService,
    private SmallPcService: SmallPcService,
    private windowRef: WindowRefService,
    private commonService: CommonService,
    private authService: PortalAuthService,
    private admin: AdminService,
    private dialog: MatDialog,
    public httpClient: HttpClient,
    private bigPcService: BigpcService,
    public sanitizer: DomSanitizer,
    public localStorageService: LocalstorgeService,
    public orderService: OrderService
  ) {
    this.getDynamicSVG(this.svgs);
    this.validateSessionData();

    this.isLoggedIn = this.authService.isUserLoggedIn()
    this.isGuestUser = this.authService.isGuestUser();

    this.bigPcService.showDashBoard$.subscribe((showdashbrd) => {
      if (showdashbrd.show) {
        this.activetab = 'dynamicPcs';
        this.navigateToSecoendStage();
      };
    });

    this.bigPcService.showDynamicPc$.subscribe((event) => {
      if (event.show) {
        this.setActiveTab("dynamicPcs");
      };
    });

    this.bigPcService.showSecondStage$.subscribe(show => {
      this.setActiveTab("dynamicPcs");
    });
    
    // console.log(this.bigpc, 16000000);
    this._window = windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);
    this.getScreenSize();

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));
      this.sessionId = params.get('sid');
      if (!this.sessionData) {
        this.SmallPcService.getSessionData(this.sessionId).then((data: any) => {
          if (data) {
            this.sessionData = data;
          };
        });
      };
    };

    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.authService.detailsChanged$.subscribe(newUserDetails => {
      this.userInfo = newUserDetails;
    });

    this.authService.isLoggedIn$.pipe(takeUntil(this.$unsubscriber)).subscribe((result) => {
      this.isLoggedIn = result.userLogin;
      this.isGuestUser = result.guestLogin;
      this.bigpc = true;
      if (this.isLoggedIn) {
        let userInfo = this.authService.getPortalUserInfo();
        this.setProfilePic(userInfo);
        this.activetab = 'dynamicPcs';
      }
    });


    let getTabName = params.get("stage") ? params.get("stage") : decodeURIComponent(params.get("page"));

    let stages = [
      "one",
      "two",
      "three",
      "four"
    ];

    if (stages.includes(getTabName)) {
      this.setActiveTab("dynamicPcs")
    } else {
      this.setActiveTab(getTabName);
    };
  };


  async ngOnInit() {
    this.fromAdreses = {show : false , address : {}};
    this.updateHotlineStatus();
    this.primaryColor = this.productInfoForWebPC?.colors?.primaryColour;
    this.pricealarm = this.productInfoForWebPC?.portalSettings?.alaramSettings?.status || this.alarmSettings?.status;
    var params = new URLSearchParams(this.url);
    this.sessionId = params.get('sid');
    this.page = params.get('page');
    let stage = params.get('stage');
    if (this.page == 'Dashboard') {
      this.activetab = 'Dashboard';
      this.bigpc = false;
    };

    this.sessionData = await this.SmallPcService.getSessionData(this.sessionId);
    let guestuser = this.authService.isGuestUser()
    let sessionDataCheck = this.sessionData && this.sessionData.product && Object.keys(this.sessionData.product).length > 0 ? true : false;
    if (sessionDataCheck && this.activetab == "dynamicPcs" || sessionDataCheck && guestuser
    ) {
      this.activetab = "dynamicPcs"
    };

    let aupris_id = params.get('aupris_id');
    let timestamp = aupris_id ? aupris_id.split('AU')[1] : null;
    let curdate = new Date(+timestamp).getDate();
    let curyear = new Date(+timestamp).getFullYear();
    let givenDate = curdate + curyear;
    let newDate = new Date();

    (this.sessionData && this.sessionData['product']) ? this.sessionData['product'] : '';
    let presentyear = newDate.getDate() + newDate.getFullYear();
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    if (!this.isLoggedIn && !this.isGuestUser) {
      // setting up user login info for previewing the price calculator
      if (givenDate == presentyear) {
        this.userLoggedIn.emit(
          {
            userLogin: true,
            guestLogin: false
          }
        );
        this.setUserType(
          {
            userLogin: true,
            guestLogin: false
          }
        )
      } else {
        this.userLoggedIn.emit(
          {
            userLogin: this.authService.isUserLoggedIn(),
            guestLogin: this.authService.isGuestUser()
          }
        );
        this.setUserType(
          {
            userLogin: this.authService.isUserLoggedIn(),
            guestLogin: this.authService.isGuestUser()
          }
        );
        return false;
      };
    } else {
      if (this.authService.isUserLoggedIn()) {
        let userInfo = this.authService.getPortalUserInfo();
        this.setProfilePic(userInfo);
      }
      this.userLoggedIn.emit(
        {
          userLogin: this.authService.isUserLoggedIn(),
          guestLogin: this.authService.isGuestUser()
        }
      );
      this.setUserType(
        {
          userLogin: this.authService.isUserLoggedIn(),
          guestLogin: this.authService.isGuestUser()
        }
      );
    };
    // console.log(this.bigpc, 3055555);
    if ((this.activetab == "Dashboard") && (stage == 'one' || stage == 'two' || stage == 'three' || stage == 'four')) {
      this.bigpc = false;
    } else {
      if (!this.isLoggedIn || this.isGuestUser) {
        this.bigpc = true;
      } else if ((this.activetab == "dynamicPcs")) {
        this.bigpc = true;
      } else if ((stage == 'one' || stage == 'two' || stage == 'three' || stage == 'four')) {
        this.activetab == "dynamicPcs";
        this.setActiveTab(this.activetab);
      } else {
        this.setActiveTab(this.activetab);
        this.bigpc = false;
      }
    };
    // console.log(this.bigpc, 32111);
  };



  async navigateToSecoendStage() {
    await this.SmallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
      if (data) {
        let url;
        this.bigpclink = data.priceCalcUrl;
        this.url = this._window.location.href;
        var params = new URLSearchParams(this.url);
        let clickePage = decodeURIComponent(params.get("clickedPage"));
        let tempOrderId ;
        if(this.url.match(/tempOrderId/gi)){
          tempOrderId = decodeURIComponent(params.get("tempOrderId"));
        }
        if (clickePage == 'pricealarm') {
          this.url = this.url.replace(new RegExp(`&clickePage=${clickePage}`, 'gi'), '');
          if (environment.testSite_or_thirdPartySites) {
            url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&page=pricealarm`;
            window.location.replace(url);
            this.bigpc = true;
          } else {
            url = `${environment.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&page=pricealarm`;
            window.location.replace(url);
            this.bigpc = true;
          };
        } else {
          if (environment.testSite_or_thirdPartySites) {
            url = `https://${this.bigpclink}#&view=bigpc&sid=${this.sessionId}&page=dynamicPcs&stage=two`;
            if(tempOrderId){
              url = `${url}&tempOrderId=${tempOrderId}`;
            }
            window.location.replace(url);
            this.bigpc = true;
          } else {
            url = `${environment.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${this.sessionId}&page=dynamicPcs&stage=two`;
            if(tempOrderId){
              url = `${url}&tempOrderId=${tempOrderId}`;
            }
            window.location.replace(url);
            this.bigpc = true;
          };
        }
      };
    })
  };

  async setActiveTab(tabName) {
    // console.log(tabName, 347);
    let tabList = [
      'Adressen',
      'Bestellung',
      'InventoryTracking',
      'Einstellungen',
      "dynamicPcs",
      'pricealarm',
      'Dashboard',
    ];

    if (tabList.includes(tabName)) {
      this.activetab = tabName;
    } else {
      let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
      if (affiliateDetailsExists.status) {
        if (!this.isLoggedIn) {
          this.bigpc = true;
        };
      } else {
        this.activetab = 'Dashboard';
        if (!this.isLoggedIn) {
          this.bigpc = true;
        };
      }

    };

    if (this.activetab == 'Dashboard') {
      this.url = window.location.href;
      let pageName = "&page=Dashboard"
      if (!this.url.includes(pageName)) {
        let url: string = this.url;
        let newUrl = `${url}&page=${this.activetab}`;;
        window.history.replaceState('', document.title, newUrl);
      };
    }
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      })
    });
  };

  async validateSessionData() {
    this.sessionId = localStorage.getItem('sid');
    this.sessionData = await this.SmallPcService.getSessionData(this.sessionId);
    if (this.sessionId && this.sessionData) {

      let lieferOrt = this.sessionData.postalCode && this.sessionData.postalCode.plzId ? this.sessionData.postalCode.plzId : null;
      let product = this.sessionData.product && this.sessionData.product.name ? this.sessionData.product.name : null;
      let Liter = this.sessionData.quantity ? this.sessionData.quantity : null;
      let params = new URLSearchParams(this.url);
      let stage = params.get('stage');
      if (lieferOrt && product && Liter && this.activetab == 'dynamicPcs' && stage != null) {
        this.bigpc = true;
      }
    } else {

    };
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1100) {
      this.rightAroow = false;
      this.buttontag = true;
    } else if (this.screenWidth < 768) {
      this.rightAroow = false;
      this.buttontag = true;
    } else {
      this.rightAroow = true;
      this.buttontag = false;
    }
  }

  setUserType = (userType: { userLogin: boolean, guestLogin: boolean }) => {
    this.userLogin = userType.userLogin;
    this.guestLogin = userType.guestLogin;
  };

  closemenu() {
    this.menubutton == !this.menubutton;
    this.toggbutton = true;
  };

  close() {
    this.buttontag = !this.buttontag;
    this.rightAroow = !this.rightAroow;
    this.toggbutton = true;
    if (this.rightAroow) {
      // 282px
    } else {
      // 102px
    }
  };

  setProfilePic(userInfo: any) {
    if (userInfo != 'undefined') {
      this.userInfo = JSON.parse(userInfo);
      if (typeof this.userInfo.photoUrl === 'string') {
        this.photoUrl = this.userInfo.photoUrl
      } else {
        if (this.userInfo.photoUrl) {
          this.getUserLogo(this.userInfo.photoUrl._id);
        };
      };
    };
  };

  async getUserLogo(image_id: any) {
    let profile_image_b64 = await this.admin.getLogoImageById(image_id);
    if (profile_image_b64.success) {
      this.photoUrl = `data:image/jpg;base64,${profile_image_b64.data.data}`;
    };
  };

  toggle() {
    this.toggbutton = false;
    this.buttontag = true;
  };

  async changeView(view) {
    this.activetab = view;
    let tabslist = ['dynamicPcs'];
    this.url = this._window.location.href;

    if (this.activetab == 'Logout') {
      this.logoutHover = false;

      localStorage.removeItem('aupris_token');
      localStorage.removeItem('portal_user');
      localStorage.removeItem('userdata');

      sessionStorage.removeItem('isNewVisit');
      localStorage.removeItem("priceAlarmInfo");
      this.bigpc = false;
      this.logOutUser = true;
      this.url = this._window.location.href
      let url: string = this.url;
      window.history.pushState('', document.title, url);

      let paramMap = new URLSearchParams(url);

      let sid = paramMap.get('sid');
      if (sid) {
        // alert("sid removed in changeView")
        localStorage.removeItem(sid);
        localStorage.removeItem('sid');
        url = url.replace(new RegExp(`&sid=${sid}`, 'gi'), '');
      };
      let tempOrderId = paramMap.get('tempOrderId');
      if (tempOrderId) {
        url = url.replace(new RegExp(`&tempOrderId=${tempOrderId}`, 'gi'), '');
      };

      this.logoutsmallpc.emit(false);
    } else {
      if (tabslist.includes(this.activetab)) {
        this.bigpc = true;
      } else {
        let newUrl = this.url;
        if (newUrl.includes('stage')) {
          newUrl = newUrl.replace(`&stage=one`, '');
          newUrl = newUrl.replace(`&stage=two`, '');
          newUrl = newUrl.replace(`&stage=three`, '');
          newUrl = newUrl.replace(`&stage=four`, '');
          let getAffiliateStatus = await this.orderService.checkForAffliateQueryParams();
          if (getAffiliateStatus.status) {
            let affiliateParams = this.orderService.affliateDetails;
            newUrl = newUrl.replace(`&amount=${affiliateParams.amount}`, '')
            newUrl = newUrl.replace(`&zip=${affiliateParams.zip}`, '')
            newUrl = newUrl.replace(`&unloadingPoints=${affiliateParams.unloadingPoints}`, '')
            newUrl = newUrl.replace(`&callBackUrl=${affiliateParams.callBackUrl}`, '')
            newUrl = newUrl.replace(`&product=${affiliateParams.productId}`, '')
            newUrl = newUrl.replace(`&affiliateId=${affiliateParams.affiliateId}`, '')
          };
          window.history.replaceState('', document.title, newUrl);
        };
        this.bigpc = false;
        this.userLogin = false;
      };
    };

    this.commonService.changeRouteParams('page', view);
  };

  emergency() {
    const dialogRef = this.dialog.open(EmergencyHotlineComponent,
      {
        width: '500px',
        disableClose: true,
        data: {}
      }
    );
    dialogRef.afterClosed().subscribe(result => {
    })

  };

  editAddress = (event: { edit: boolean, addNew: Boolean }) => {
    this.bigpc = true;
    this.addNewAddress = true;
  };

  username(event) {
    // console.log(event, this.userInfo)
    if (event) {
      if (this.userInfo) {
        this.userInfo.Vorname = event;
      };
    };
  };

  userName(event) {
    if (event) {
      if (this.userInfo) {
        this.userInfo.Name = event;
      };
    };
  };

  updatepic(event) {
    this.photoUrl = event
  };

  showOverview(e) {
    this.pricealarm = false;
    this.showPriceData = true;
  };

  showAllOrders(ev) {
    this.activetab = 'Bestellung';
  };

  changeProductViewInMenu(event) {
    this.activetab = event;
  };

  async showPricecalcfromAddress(event) {    
    let obj = { plzId: event.plzId, tag: this.sessionData['tag'] };

    this.bigPcService.dynamicPcWithAddress(obj).subscribe((res: any) => {
      if (res.success && res.data.length) {
        let postalCode = {
          city: event.city,
          plzId: event.plzId,
          postalcode: event.zipCode,
          suburb: event.suburb,
          latitude: event.lat,
          longitude: event.lat
        }
        let dynamicProduct = {
          name: res?.data[0]?.name,
          _id: res?.data[0]?.dynamicProductId
        }
        this.localStorageService.setSingleKeySessionData("postalCode", postalCode);
        this.localStorageService.setSingleKeySessionData("product", dynamicProduct);
        this.localStorageService.setSingleKeySessionData("quantity", 3000);
        this.bigpc = true;
        this.changeView('dynamicPcs');
      } else {
        this.failureMsg = "No PriceCalculators Avilable On This Address!!"
      }
    })
  };

  navigateToPage(event) {
    if (event == 'bigpc') {
      this.bigpc = true;
    } else {
      this.bigpc = false;
    };
    this.activetab = event;
  };

  ngOnDestroy(): void {
    this.$unsubscriber.complete();
  };

  updateHotlineStatus() {
    let terms = this.portalTermsAndConditions;
    this.hotlineStatus = terms?.hotlineStatus;
  };
  async naivateToAdress(event) {
    this.changeView('Adressen')
  }

  navigateToMyHeating(event) {
    if (event && typeof event == 'object'  && Object.keys(event).length && event.type == 'new') {
      this.fromAdreses = {show : true , address : event.address};
      setTimeout(() => {
        this.fromAdreses = { show: false, address: {} }
      }, 1000)
      this.changeView('InventoryTracking');
    } else if(event && typeof event == 'object'  && Object.keys(event).length && event.type == 'exists') {
      this.fromAdreses = {show : false , address : event.address};
      this.changeView('InventoryTracking');
    } else if(event && typeof event == 'object'  && Object.keys(event).length && event.type == 'delete' ){
      this.fromAdreses = {show : false , address : {}};
    }
};

showMyHeating(event){
  this.changeView('InventoryTracking')
}

}