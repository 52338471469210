<div class="aurow aupris_main scrolltotopprogress stage">
  <div class="aurow  mg section-containers">

    <div class="" *ngIf="!createAddress">
      <div>

        <div class="aurow proper">
          <div class="aucol-md-4 aucol-sm-6 aucol-xs-12 cardSec"
            style="min-width:360px;max-width: 360px;padding-right:0px" *ngFor="let item of userAddresses">
            <div class="card1 ">
              <div> <b>{{item?.currentTitle}} {{item?.firstName}} {{item?.lastName}}</b> <br>
                {{item?.streetName}}<span *ngIf="item?.houseNo"> {{item?.houseNo}}</span>
                <div class="contentt">{{item?.zipCode}}
                  <span *ngIf="item?.city">
                    {{item?.city}}
                    <span class="tooltipppp">
                      <img style="width: 20px;" *ngIf="item | validateAddress"
                        [src]="'assets/caution-sign.svg' | assetsUrl" alt="">
                      <span class="tooltiptextttt">
                        <pre style="position:relative; display:flex;font-weight: 100"> {{invalidZipcodeMsg}}</pre>
                      </span>
                    </span>
                  </span>
                  <!-- <span *ngIf="item?.suburb">{{item?.suburb}}</span> -->
                </div>
                <span *ngIf="item.isPrimary"  class="pointer"  Style="float: right;margin-top: -73px;" [innerHTML]="Maps">
                </span>
                <span *ngIf="!item.isPrimary" class="pointer" Style="float: right;margin-top: -73px;" [innerHTML]="notPrimary"
                  (click)="setprimary(item._id,item.kundenKontoId)">
                </span>
              </div>
              <div
                [ngClass]="item?.myheating?.hasWarning1 && item?.myheating?.hasWarning2 ? 'setCircle' : (item?.myheating?.hasWarning2 || item?.myheating?.hasWarning1) ? 'setSingle' : ''">
                <div>

                  <div style="position: relative;">
                    <div class="setuptext">
                      <span *ngIf="!item.myheating.isHeating">
                        {{'Bestandstracking'}}<br>{{'Inaktiv'}}
                      </span>
                      <span *ngIf="item.myheating.isHeating">
                        {{item?.myheating.activePercentageLevel}}%<br>
                        <!-- <span>
                          <img style="position: relative;right: 5px; top: 5px;" [src]="secureSvg(item.myheating.productId)"> 
                        </span> -->
                        <span class="productIcon" [innerHTML]="item.selectedProduct"> </span>
                        <span style="padding: 5px 0; display: inline-block;">{{item.myheating.currentActiveLevel | replaced}}  {{item.myheating.productUnits?.name}} </span> <br>
                        Reichweite : {{item.myheating.remainingDays}} Tage
                      </span>
                    </div>
                    <span *ngIf="item?.myheating.isHeating" [innerHTML]="getPercentageLevel(item.myheating)">
                    </span>
                    <span *ngIf="!item.myheating.isHeating" [innerHTML]="noheat"> </span>
                    <div class="setDates">
                      <span class="date-left" *ngIf="item.myheating.isHeating">
                        {{item.myheating.currentDate | date: 'dd.MM.yy' }}
                      </span>
                      <span class="maxValue-right" *ngIf="item.myheating.isHeating">
                        Max. {{item.myheating.maxTankSize | replaced }} {{item.myheating.productUnits?.name}}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="one" *ngIf="item.myheating.isHeating && item.myheating.hasWarning1">
                    1
                  </div>
                  <div class="two" *ngIf="item.myheating.isHeating && item.myheating.hasWarning2">
                    2
                  </div> -->
                  <div>
                    <button *ngIf="!item.myheating.isHeating" type="button" class="setBtn" style="width: 64%; position: relative;z-index: 1; bottom: 45px; left: 25px;"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    [style.background]="'var(--primaryColour)'" (click)="navigateToMyHeating(item , 'new')">{{'Heizung hinzufugen'}}
                  </button>
                  </div>
                  <div class="heziung-svg">
                    <div *ngIf="item.myheating.isHeating" class="pointer" (click)="navigateToMyHeating(item , 'exists')"
                      [innerHTML]="Heizung">
                    </div>
                    <div *ngIf="!item.myheating.isHeating" [innerHTML]="noHeating">
                    </div>
                    <div class="heating">{{'Heizung'}}</div>
                  </div>
                  <div class="calculate-svg" >
                  <div style="text-align: center;margin-left: 21px;" [innerHTML]="Preisrechner" class="pointer" (click)="calculateprice(item)"> </div>
                  <span style ="position: relative; left: 10px;" class="calculate">{{'Preisrechner'}}</span>
                  </div>
                </div>
              </div>
            

              <!-- <div><b>{{ item.isPrimary ? 'Primäre Lieferaddresse': 'Adresse'}}</b></div>
                      <div class="content"><b>{{item?.gender}} {{item?.firstName}} {{item?.lastName}}</b></div>
                      <div class="contentt">{{item?.companyName}} </div>
                      <div class="contentt">{{item?.addressZusatz}} </div>
                      <div class="contentt"> {{item?.streetName}}<span *ngIf="item?.houseNo"> {{item?.houseNo}}
                        </span>
                      </div>
        
                      <div class="contentt">{{item?.zipCode}}
                        <span *ngIf="item?.city">
                          {{item?.city}}
                          <span class="tooltipppp">
                            <img style="width: 20px;" *ngIf="item | validateAddress"
                              [src]="'assets/caution-sign.svg' | assetsUrl" alt="">
                            <span class="tooltiptextttt">
                              <pre style="position:relative; display:flex;font-weight: 100">
        Bei der Adresse ist ein Problem aufgetreten. 
        Bitte bearbeiten Sie die Adresse erneut 
        und wählen Sie eine PLZ und einen Ort
        aus unseren Vorschlägen aus! </pre>
                            </span>
                          </span>
                        </span>
                        <span *ngIf="item?.suburb">, {{item?.suburb}}</span>
                      </div>
        
                      <div class="contentt">{{item?.country}}</div>
                      <div class="content">Telefon: {{item?.phoneNumber}}</div>
                      <div class="contentt" style="margin-bottom: 5px">Email: {{item?.eMail}}</div>
                      <div style="padding-top:20px;font-size:12px">
                        {{languageData['Adresseingabe'].Var_additionaldeliveryinstructions || 'Zusätzliche Lieferhinweise anuns'}}:
                      </div>
        
                      <div [style.padding-top.px]='(item.isPrimary == false ? 8 : 40)'>
                        {{item?.comments}}
                      </div>
        
                      <button type="button" class="setBtn smalBtn"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        [style.background]="'var(--primaryColour)'" (click)="setprimary(item._id,item.kundenKontoId)"
                        *ngIf="item.isPrimary == false">
                        {{languageData['Address'].Var_set || 'Set as primary'}}
                      </button>
        
                      <button type="button" class="setBtn smalBtn" style="width: 100%;"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        [style.background]="'var(--primaryColour)'"
                        (click)="calculateprice(item)">{{languageData['Small-pc'].Var_Preis_Berechnen || 'Preis
                        Berechnen'}}
                      </button> -->
            </div>


            <div class="bottombtns">
              <div style="margin-left: 0px; cursor: pointer;display:inline-block;" (click)="deleteAddress(item)">
                <svg height="20px" viewBox="-40 0 427 427.00131" width="20px" xmlns="http://www.w3.org/2000/svg"
                  fill="rgb(228, 134, 131)">
                  <path
                    d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                  <path
                    d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                  <path
                    d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                  <path
                    d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                </svg>
              </div>
              <div class="pull-right" style="margin-right: 7px; cursor: pointer;display:inline-block; "
                (click)="editAddress(item); ">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.126" height="14.126" viewBox="0 0 14.126 14.126">
                  <path id="ic_edit_24px"
                    d="M3,14.181v2.942H5.942l8.678-8.678L11.678,5.5ZM16.9,6.17a.781.781,0,0,0,0-1.106L15.06,3.227a.781.781,0,0,0-1.106,0L12.518,4.663,15.46,7.605Z"
                    transform="translate(-3 -2.997)" [ngStyle]="{fill: 'var(--primaryColour)'}" />
                </svg>
              </div>
            </div>
          </div>
          <div class=" aucol-md-4 aucol-sm-6 aucol-xs-12 cardSec" (click)="openCreateAddress()"
            style="min-width: 360px;max-width: 360px;padding-right:0px">
            <div class="card1">
              <div style="text-align: center;min-height: 123px;margin-top: 124px;">
                <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" width="120" height="40"
                  viewBox="0 0 20 20">
                  <path id="ic_add_circle_outline_24px"
                    d="M10.631,5.923H9.062V9.062H5.923v1.569H9.062V13.77h1.569V10.631H13.77V9.062H10.631ZM9.847,2a7.847,7.847,0,1,0,7.847,7.847A7.85,7.85,0,0,0,9.847,2Zm0,14.124a6.277,6.277,0,1,1,6.277-6.277A6.286,6.286,0,0,1,9.847,16.124Z"
                    transform="translate(-2 -2)" fill="#707070" />
                </svg>
                <h3 style="margin-top:0px">{{languageData?.Hamburger?.Var_Adresse || 'Adresse'}}</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div [ngClass]="{snackbar:true,show:viewsnack}">
        {{languageData?.Bestatigung?.Var_PrimaryAddressDeleteErrorMsg || 'Primäre Adresse kann nicht gelöscht
        werden!'}}
      </div>
    </div>

  </div>
</div>


<div>
  <loader [full-screen]="true" *ngIf="loaderForScreen" [color]="'var(--primaryColour)'"></loader>
</div>

<app-pc-create-address *ngIf="createAddress  || editAdr" [editableAddress]="editableAddress"
  [createAddress]="createAddress" (abort)="cancelEdit()">
</app-pc-create-address>

<div>


</div>