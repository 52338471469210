<!--*------------------------------------------  ------------------------------------------------------*-->

<div style="width: 99%;" *ngIf="!heatingOverview && setupView">
  <span *ngIf="select" style="margin-bottom: 15px;position: relative;top: 10px;color: var(--primaryTextColour)">
    <button class="btnalign" (click)="openAdress()">
      {{'Andere Heizung Auswählen'}}
    </button>
  </span>

  <div class="meine" (click)="meinepopupopen()" style="margin-top: 10px;">
    <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" width="70" height="62" viewBox="0 0 20 20">
      <path id="ic_add_circle_outline_24px"
        d="M10.631,5.923H9.062V9.062H5.923v1.569H9.062V13.77h1.569V10.631H13.77V9.062H10.631ZM9.847,2a7.847,7.847,0,1,0,7.847,7.847A7.85,7.85,0,0,0,9.847,2Zm0,14.124a6.277,6.277,0,1,1,6.277-6.277A6.286,6.286,0,0,1,9.847,16.124Z"
        transform="translate(-2 -2)" fill="var(--primaryColour)" />
    </svg>
    <h1 style="margin-top:-4px; color:var(--primaryColour)">
      {{languageData['Meine_Heizung']?.VarKundeportalMeine_Heizung1||'Meine Heizung einrichten'}}
    </h1>
  </div>
</div>

<!--*---------------------------------------- Heating Setup Overview  ------------------------------------------------------------*-->

<div *ngIf="heatingOverview && !showAdresses">
  <span style="margin-bottom: 15px;position: relative;top: 10px;color: var(--primaryTextColour)" *ngIf="addresLength > 1">
    <button class="btnalign" (click)="openAdress()">
      {{'Andere Heizung Auswählen'}}
    </button>
  </span>
  <!-- <div class="container-fluid meine1 meine2">
    <div>
      <div class="col-md-4">
        <div class="position-relative" style="width: 240px; height: 240px; margin-top: -20px;">
          <span [innerHTML]="getPercenatgeLevel(selectAdress.myheating.activePercentageLevel)"></span>
        </div>

        <p class="percentage">
          {{selectAdress.myheating.activePercentageLevel}}%
        </p>
        <p class="quantity">
          {{selectAdress.myheating.currentActiveLevel | replaced}}
          {{selectAdress.myheating.productUnits.name}}
        </p>
        <p class="reichalign">
          Reichweite: {{selectAdress.myheating.remainingDays}} Tage
        </p>
        <p class="datealign">
          {{selectAdress.myheating.currentDate | date: 'dd.MM.yy'}}
        </p>
        <p class="quantityalign">
          Max {{selectAdress.myheating.maxTankSize | replaced }}
          {{selectAdress.myheating.productUnits.name}}
        </p>

        <div class="one">
          1
        </div>
        <div class="two">
          2
        </div>
      </div>

      <div class="col-md-8">
        <div class="svgalign">
          <span (click)="openFilling()" style="cursor: pointer;">
            <img style="position: relative;right: 12px;" src="assets/inventory/befullung.svg">
            <span style="position: relative;left: 4px;"> Befüllung</span>
          </span>
          <span style="cursor: pointer;" (click)="openBestands()">
            <img src="assets/inventory/Bestandskontrolle.svg">
            <span> Bestandskontrolle</span>
          </span>
          <span style="cursor: pointer;">
            <img style="position: relative;left: 10px;" src="assets/inventory/preisrechner.svg">
            <span style="position: relative;left: 4px;">Preisrechner</span>
          </span>
          <span style="cursor: pointer;">
            <img src="assets/inventory/Info.svg">
            <span style="position: relative;left: 35px;bottom: 5px;">Info</span>
          </span>
        </div>
      </div>
    </div>

    <div style="height: 90px;">
      <div class="addressDiv">
        <div>
          <b>
            {{selectAdress?.currentTitle}}
            {{selectAdress?.firstName}}
            {{selectAdress?.lastName}}
          </b>
        </div>
        <div>
          {{selectAdress?.companyName}}
          {{selectAdress?.addressZusatz}}
        </div>
        <div>
          {{selectAdress?.streetName}}
          {{selectAdress?.houseNo}}
        </div>
        <div>
          {{selectAdress?.zipCode}}
          {{selectAdress?.city}}
        </div>
      </div>
    </div>

    <div id="chartContainer" #chartContainer
      style="height: 275px !important;position: relative;top: 80px;right: 170px;width: 700px;" class="col-md-12">
    </div>
  </div> -->

  <div class="aupris_main scrolltotopprogress stage" style="margin-top: 20px;" [ngClass]="{'withoutBtn': !(addresLength > 1)}">
    <div class="au-container section-container card"
      style="padding: 18px 0px 0px 0px;margin-top: 0px !important;height: 400px;">
      <div class="aucol-lg-3 aucol-md-3 aucol-sm-4 aucol-xs-4" *ngIf="heatingSystemInfo" style="margin-top: -20px;">
        <div class="position-relative" style="height: 240px;">
          <span [innerHTML]="getPercentageLevel(selectAdress.myheating)"></span>
        </div>
        <p class="percentage">
          {{selectAdress.myheating.activePercentageLevel}}%
        </p>
        <p class="quantity">
          <span class="productIcon" [innerHTML]="selectedProduct">
          </span>
          <span>{{selectAdress.myheating.currentActiveLevel | replaced}}
            {{selectAdress.myheating.productUnits?.name}}
          </span>
        </p>
        <p class="reichalign">
          Reichweite: {{selectAdress.myheating.remainingDays}} Tage
        </p>

        <p class="datealign">
          {{selectAdress.myheating.currentDate | date: 'dd.MM.yy'}}
        </p>
        <p class="quantityalign">
          Max {{selectAdress.myheating.maxTankSize | replaced }}
          {{selectAdress.myheating.productUnits?.name}}
        </p>
        <div class="svgalign">
          <span (click)="openFilling()" style="cursor: pointer;position: relative;left: 11px;">
            <span [innerHTML]="befullung"></span>
            <span style="position: relative;left: 16px;">
              Befüllung
            </span>
          </span>
          <span style="cursor: pointer;position: relative;left: 9px;" (click)="openBestands()">
            <span [innerHTML]="Bestandskontrolle" style="position: relative;left: 11px;"></span>
            <span>
              Bestandskontrolle
            </span>
          </span>
          <span style="cursor: pointer;position: relative;left: 21px;">
            <span [innerHTML]="preisrechner" (click)="calculatePrice()" style="position: relative;left: 6px;"></span>
            <span>
              Preisrechner
            </span>
          </span>
          <span style="cursor: pointer;position: relative;left: 20px" (click)="editHeating()">
            <span [innerHTML]="Info"></span>
            <span style="position: relative;left: 34px;bottom: 5px;">
              Info
            </span>
          </span>
        </div>
      </div>

      <div class="aucol-lg-1 aucol-md-1">
      </div>

      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <div class="addressDiv">
          <div>
            <b>
              {{selectAdress?.currentTitle}}
              {{selectAdress?.firstName}}
              {{selectAdress?.lastName}}
            </b>
          </div>
          <div>
            {{selectAdress?.companyName}}
            {{selectAdress?.addressZusatz}}
          </div>
          <div>
            {{selectAdress?.streetName}}
            {{selectAdress?.houseNo}}
          </div>
          <div>
            {{selectAdress?.zipCode}}
            {{selectAdress?.city}}
          </div>
        </div>

        <div id="chartContainer" #chartContainer class="col-md-12 addressGraph">
        </div>
      </div>
    </div>
  </div>

  <!-- Show bar graph Info section start here -->
  <div class="aupris_main scrolltotopprogress stage">
    <div class="au-container section-container card" style="padding: 18px 0px 10px 0px;">
      <div class="aucol-lg-3 aucol-md-3 aucol-sm-4 aucol-xs-4" *ngIf="heatingSystemInfo">

        <!-- <div class="aucol-md-6 aucol-lg-5" style="margin-top:10px;padding-left:0px">
          <img class="houseIcon" src="../../assets/inventory/Heaterrr.svg">
          <strong style="font-size: 16px;">
            {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
          </strong>
        </div>

        <div class="aucol-md-6 aucol-lg-5" style="margin-top:5px">
          <div>{{heatingSystemInfo?.productName}} </div>
          <div>{{heatingSystemInfo?.heatingSystemName}}</div>
          <div>{{heatingSystemInfo?.houseName}} </div>
        </div> -->


        <!-- <strong style="font-size: 16px;">
          {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
        </strong> -->

        <div class="aucol-lg-12 aucol-md-12 details"
          style="display: flex;padding-left: 0px;margin-top: 5px;justify-content: space-between;">
          <div>
            <!-- <img class="houseIcon" src="../../assets/inventory/Heaterrr.svg"> -->
            <span [innerHTML]="Heaterrr" class="houseIcon"></span>
            <strong style="font-size: 16px;">
              {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
            </strong>
          </div>
          <div style="margin-top:5px">
            <div>{{heatingSystemInfo?.productName}} </div>
            <div>{{heatingSystemInfo?.heatingSystemName}}</div>
            <div>{{heatingSystemInfo?.houseName}} </div>
          </div>
        </div>


        <div class="aucol-lg-12 aucol-md-12 details" style="padding-left: 0px;margin-top: 5px;">
          <div>
            <label>
              Beheizte Fläche:
            </label>
            <span style="float: right;">
              {{heatingSystemInfo?.houseArea}}
              <span>
                m<sup>2</sup>
              </span>
            </span>
          </div>
          <div>
            <label>
              Innentemperatur:
            </label>
            <span style="float: right;">
              {{heatingSystemInfo?.roomTempeature}}
              <span>
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Personen im Haushalt:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.familySize}}
              <span style="visibility: hidden;">
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Sonnenkollektoren:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.solarPannelArea || 0}}
              <span>
                m<sup>2</sup>
              </span>
            </span>
          </div>
          <div>
            <label>
              Heizungsunterstützung:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.heatingSupport ? "Yes" : "No"}}
              <span style="visibility: hidden;">
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Geschätzter Normverbrauch:
            </label>
            <span style="float: right;position: relative;left: 4px;">
              {{heatingSystemInfo?.grossConsumption | replaced}}
              <span>
                {{'kg/l'}}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="aucol-lg-1 aucol-md-1">

      </div>

      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <!-- <div id="bar-chart-container" style="height: 275px !important"></div> -->
        <div id="bar-chart-container" style="height: 275px !important;width: 700px;" class="col-md-12">
        </div>
      </div>
    </div>
    <!-- tile 3 real consumption -->
    <!-- future development hiding for now 21-02-2025 -->
    <div class="au-container section-container card " style="display: flex;" *ngIf="false">
      <div class="aucol-lg-4 aucol-md-8 aucol-sm-4 aucol-xs-12">
        <div class="aucol-md-6" style="margin-top:10px;padding-left:0px">
          <!-- <img src="../../assets/inventory/Graph.svg" style="display: block;margin-left:25px;width: 60px;"> -->
          <span [innerHTML]="Graph" class="houseIcon"></span>
        </div>

        <div class="aucol-lg-12 aucol-md-12 details" style="padding: 0px;margin-top: 12px;">
          <div style="margin-bottom: 20px;">
            <strong style="font-size: 16px;">
              {{languageData['Energie']['VarKundeportalEnergie5'] || "Realverbrauch"}}
            </strong>
          </div>

          <ng-container>
            <!-- years data -->
            <div *ngFor="let item of consumptionDetails; let i = index; last as isLast"
              style="display: flex;margin-bottom: 10px;">
              <!-- <label >{{item?.fromDate| date:'dd.MM.yyyy' }}-</label> -->

              <div class="padding-left-0 hypen" style="width: 85px;">{{item?.fromDate| date:'dd.MM.yyyy' }} </div>
              <span style="margin-top: -1px;margin-left: 2px;">-</span>
              <div class="aucol-lg-3 aucol-md-3 aucol-sm-3" style="width: 100px;">{{item?.toDate| date:'dd.MM.yyyy' }}:
              </div>
              <!-- <label >{{item?.toDate| date:'dd.MM.yyyy'}}-</label> -->
              <div class="padding-right-0" style="margin-left: 20px;"> {{item?.consumption}} kg</div>

              <div style="position: relative;left: 15px;height: 20px;top: 2px;cursor: pointer;"
                *ngIf="isLast && !addNewEntry" (click)="deleteLastRow(item)">
                <svg height="15px" viewBox="-40 0 427 427.00131" width="20px" xmlns="http://www.w3.org/2000/svg"
                  fill="rgb(228, 134, 131)">
                  <path
                    d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0">
                  </path>
                  <path
                    d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0">
                  </path>
                  <path
                    d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0">
                  </path>
                  <path
                    d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0">
                  </path>
                </svg>
              </div>
            </div>
          </ng-container>

        </div>
        <div class="aucol-lg-12 aucol-md-12 details" style="padding: 0px;margin-top: 12px; " *ngIf="addNewEntry">

          <!--
        
          //if form turned on hide delete button
          // if form turned off show delete button to last row
          // align buttons to form
          // hypen between two dates
          -->
          <form [formGroup]="consumptionForm">
            <ng-container style="width: 50%;">
              <div style="display: inline-block;">
                <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 padding-left-0">
                  <!-- <div> -->
                  <label class="posAbs aupLabel" for="fromDate" style="top: 1px;">From Date</label>
                  <input matInput [matDatepicker]="fromPicker" name="fromDate" id="fromDate" formControlName="fromDate"
                    [min]="nextConsumptionDate" (ngModelChange)="setToDate($event)"
                    class="auform-control forLabel inptCls" required>
                  <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                  <mat-datepicker #fromPicker></mat-datepicker>
                  <!-- </div> -->
                </div>

                <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 padding-left-0">
                  <!-- <div> -->
                  <label class="posAbs aupLabel" for="toDate" style="top: 1px;">To Date</label>
                  <input matInput [matDatepicker]="toPicker" name="toDate" id="toDate"
                    class="auform-control forLabel inptCls" formControlName="toDate" [min]='minimumDate'
                    (ngModelChange)="resetDate($event)" required>
                  <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                  <mat-datepicker #toPicker></mat-datepicker>
                  <!-- </div> -->
                </div>

                <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 padding-right-0 padding-left-0"
                  style="position: relative;top: 2px;">
                  <!-- <div> -->
                  <label class="posAbs aupLabel" for="total">Consumption</label>
                  <input name="total" id="total" class="auform-control forLabel inptCls" required
                    formControlName="total">
                  <!-- </div> -->
                </div>
              </div>
            </ng-container>
            <div style="margin-bottom: 18px;width: 75%;">
              <button class="au-btn btn-default" (click)="cancel()"> Abbrechen </button>
              <button class="au-btn"
                style="background: var(--primaryColour); color: var(--primaryTextColour);float:right;"
                (click)="onSave()"> Speichern </button>
            </div>
          </form>

        </div>

        <div style="display: flex;justify-content: start; padding-left: 0px;margin-left: 0px;margin-top: 12px;"
          class="addNewEntry aucol-lg-12 aucol-md-12" (click)="addConsumption()" *ngIf="!addNewEntry">
          <img src="../../assets/inventory/add-outline.svg" style="display: block;margin-top:6px;width: 30px;">
          <span class="content" style="padding-left: 5px;">
            {{languageData['Energie']['VarKundeportalEnergie6'] || "Verbräuche hinzufügen oder bearbeiten"}}
          </span>
        </div>
      </div>
      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <div id="realConsumption-chart-container" style="height: 275px !important;width:700px"></div>
      </div>
    </div>
  </div>
</div>
<loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>