<div>
  <h2 class="header1 fs18" style="text-align: center;margin-top: -5px;"> Adresse auswählen </h2>
  <div [ngClass]="{'cards1': filteredData.length === 1, 'cards': filteredData.length > 1}">
    <div *ngFor="let addresen of filteredData; let i = index" class="cardssss" (click)="natigateSetup(addresen._id)"
      style="border: 1px solid #ddd;border-radius: 5px;padding: 10px;">
      <div>
        <b>
          {{addresen?.currentTitle}}
          {{addresen?.firstName}}
          {{addresen?.lastName}}
        </b>
      </div>
      <div>
        {{addresen?.streetName}}
        <span *ngIf="addresen?.houseNo">
          {{addresen?.houseNo}}
        </span>
        <div>
          {{addresen?.zipCode}}
          {{addresen?.city}}
        </div>
      </div>
    </div>
  </div>

  <div style="border: 1px solid #ddd;margin: 23px 0px;"></div>

  <div style="text-align: center;">
    <button class="btn" (click)="changeView('Adressen')">Neue Adresse hinzufügen</button>
  </div>
</div>