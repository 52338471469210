import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LanguageService } from '../Services/language.service';
import { AdminService, } from 'app/Services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from 'app/popups/delete/delete.component';
import { environment } from '../../environments/environment';
import { UserAddress } from '../models/portalModels/portalUserAddress.interface';
import { CommonService } from 'app/common.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-pc-manual-address',
  templateUrl: './pc-manual-address.component.html',
  styleUrls: ['./pc-manual-address.component.css']
})
export class PcManualAddressComponent implements OnInit {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  @Input('createAddress') createAddress: boolean = false;
  @Input('minMaxData') minMaxData;
  @Input('colors') colors;
  userAddresses: UserAddress[];
  editableAddress: UserAddress;
  editAdr: boolean;
  viewsnack: boolean = false;
  Pellets: any;
  Heizöl: any;
  sessionId: string;
  @Output() showPricecalcfromAddress = new EventEmitter();
  @Output() showMyHeating = new EventEmitter();

  url: any;
  editDocId: any;
  invalidZipcodeMsg = "Bei der Adresse ist ein Problem aufgetreten. Bitte bearbeiten Sie die Adresse erneut und wählen Sie eine PLZ und einen Ort aus unseren Vorschlägen aus!"
  // private _window: ICustomWindow;
  svgs = [
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Fire.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Pellets.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Gas.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Maps.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Heizung.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Preisrechner.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/notPrimary.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/noHeating.svg',
    environment.assetsUrl + 'assets/noheat.svg',
    environment.assetsUrl + 'assets/heat.svg'
  ];
  heatingSvg = environment.assetsUrl + 'assets/heat.svg'

  // loaderForScreen: boolean = false;
  loaderForScreen: any = false;
  loaderTime: any = 10000;
  constructor(
    private langService: LanguageService,
    private adminService: AdminService,
    public dialog: MatDialog,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.userAddresses = this.adminService.userAdresses;
    this.getAddresses();
    this.getDynamicSVG(this.svgs)
    this.url = window.location.href;

    if (this.url.includes("subPage=addAdressen")) {
      this.openCreateAddress();
    };

    if (this.url.includes("subPage=deleteAddress")) {
      var params = new URLSearchParams(this.url);
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.commonService.setEditAddressId(this.editDocId);
      setTimeout(() => {
        let findEditDoc = (this.userAddresses || []).find((doc) => doc._id == this.editDocId);
        this.deleteAddress(findEditDoc);
      }, 700);
    };


    if (this.url.includes("subPage=editAdressen")) {
      var params = new URLSearchParams(this.url);
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.commonService.setEditAddressId(this.editDocId);
      setTimeout(() => {
        let findEditDoc = (this.userAddresses || []).find((doc) => doc._id == this.editDocId);
        this.editAddress(findEditDoc);
      }, 700);
    };

  }

  async getAddresses() {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.adminService.getPortaladdresses().subscribe((result: any) => {
      if (result.success) {
        this.userAddresses = result.data;
        this.loaderForScreen = false;
        this.userAddresses = this.userAddresses.sort((a) => {
          if (a.isPrimary == true) {
            return -1
          } else {
            return 1
          };
        });
      }
    }, () => {
      this.loaderForScreen = false;
    });
  };

  stopLoaderHere() {
    setTimeout(() => {
      this.loaderForScreen = false;
    }, this.loaderTime);
  };

  setprimary(id, kundenKontoId) {
    this.loaderForScreen = true;
    this.stopLoaderHere();
    this.adminService.setprimary({ id: id, kundenKontoId: kundenKontoId }).subscribe(result => {
      this.loaderForScreen = false;
      if (result.success) {
        this.getAddresses();
      };
    });
  };

  editAddress = (address: UserAddress) => {
    this.addKeyInUrl(`&subPage=editAdressen&_id=${address._id}`);
    this.createAddress = true;
    this.editAdr = true;
    this.editableAddress = address;
  };

  cancelEdit = () => {
    this.editableAddress = <UserAddress>{};
    this.createAddress = false;
    this.editAdr = false;
    this.getAddresses();
    this.url = window.location.href; /*testcal */
    var params = new URLSearchParams(this.url);
    let subPageName = decodeURIComponent(params.get("subPage"));
    let removeSubpage;
    if (this.url.includes("page=Adressen") && subPageName == 'addAdressen') {
      removeSubpage = "&subPage=addAdressen"
      this.removeKeyInUrl(removeSubpage);
    } else if (this.url.includes("page=Adressen") && subPageName == 'editAdressen') {
      this.editDocId = decodeURIComponent(params.get("_id"));
      this.removeKeyInUrl(`&subPage=editAdressen&_id=${this.editDocId}`);
    };
  };

  openCreateAddress = () => {
    this.addKeyInUrl("&subPage=addAdressen");
    this.createAddress = true;
    this.editAdr = false;
    this.editableAddress = <UserAddress>{};
  };

  addKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    window.history.pushState("", document.title, url);
    if (!url.includes(subPageName)) {
      newUrl = `${newUrl}${subPageName}`;
      window.history.replaceState('', document.title, newUrl);
    };
  };


  removeKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    if (newUrl.includes(subPageName)) {
      newUrl = newUrl.replace(subPageName, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  deleteAddress = (item) => {
    if (item.isPrimary) {
      this.viewsnack = true;
      setTimeout(() => {
        this.viewsnack = false;
      }, 4500);
    } else {
      this.addKeyInUrl(`&subPage=deleteAddress&_id=${item._id}`);
      const dialogRef = this.dialog.open(
        DeleteComponent,
        {
          width: '500px',
          disableClose: true,
          data: this.colors
        }
      )
      dialogRef.afterClosed().subscribe((data: any) => {
        this.removeKeyInUrl(`&subPage=deleteAddress&_id=${item._id}`);
        if (data.delete == true) {
          this.loaderForScreen = true;
          this.stopLoaderHere();
          this.adminService.deleteAddress({ _id: item._id }).subscribe((data: any) => {
            this.loaderForScreen = false;
            this.getAddresses();
          }, error => {
            console.log(error);
          });
        };
      });
    };
  };

  calculateprice(address) {
    this.showPricecalcfromAddress.emit(address);
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
    });
  };


  getPercentageLevel(heatingData) {
    const level = typeof heatingData.activePercentageLevel === 'string' ? Number(heatingData.activePercentageLevel) : heatingData.activePercentageLevel;
    const percentage = `${(level * 3 / 4)} 75`;
    const circleRadius = 15.91549430918954;
    const circleCentreXY_AXIS = 21;
    const totalDegrees = 360 * 0.75;

    const getIndicator = (reminderValue, offset, label) => {
      const reminderPercentage = (reminderValue * 100) / heatingData.maxTankSize;
      const percentageOffset = (reminderPercentage + (offset * 3 / 4));
      const angle = (percentageOffset / 100) * totalDegrees;
      const radian = angle * (Math.PI / 180);
      const x = circleCentreXY_AXIS + circleRadius * Math.cos(radian - Math.PI / 2);
      const y = circleCentreXY_AXIS + circleRadius * Math.sin(radian - Math.PI / 2);
      return `
        <circle width="10" height="10" cx="${x}" cy="${y}" r="2.5" fill="white" stroke="gold" stroke-width="0.8" />
        <text x="${x}" y="${y}" text-anchor="middle" alignment-baseline="middle" style="font-size: 2px; fill: black;font-weight:bold">${label}</text>`;
    };

    const firstIndicator = heatingData?.hasWarning1 && heatingData?.reminderOneValue ? getIndicator(heatingData?.reminderOneValue, 288, '1') : '';
    const secondIndicator = heatingData?.hasWarning2 && heatingData?.reminderTwoValue ? getIndicator(heatingData?.reminderTwoValue, 290, '2') : '';

    const svgContent = `
    <svg style="position: relative; right: 5px;" width="250px" height="250px" viewBox="0 0 42 42">
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round" style="stroke: #ddd; stroke-width: 2; fill: transparent; position: relative; right: 5px;" />
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round"
        style="stroke: var(--primaryColour); stroke-width: 2; fill: transparent; stroke-dasharray: ${percentage}; transition: stroke-dasharray 0.3s;" />
        ${firstIndicator} 
        ${secondIndicator}
    </svg>`;

    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }


  navigateToMyHeating(address, view) {
    if (view == 'new') {
      return this.showMyHeating.emit(address);
    }
    this.showMyHeating.emit(true)
  }

  getSvg(type) {
    switch (type) {
      case 'Heizöl': return this['Fire'];
      case 'Pellets': return this['Pellets'];
      case 'Gas': return this['Gas'];
      default: return this['Fire']
    }
  }


  secureSvg(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }
}

