<div class="aupris_main scrolltotopprogress">

  <div class="au-container section-container" *ngIf="manualaddress ==false" style="margin-top:2px">

    <div class="card1 ">
      <!-- <form> -->
      <div class="aurow proper" [formGroup]="lieferAdresseForm">
        <div class="row" *ngIf="innerWidth > 992">
          <div class="auform-group mb30 aucol-lg-4 aucol-md-4 aucol-sm-12 aucol-xs-12 ">
            <div style="position: relative;">
              <label class="posAbs aupLabel">{{languageData['Adresseingabe'].Var_Anrede || 'Anrede'}}</label>
              <select name="currentTitle" class="auform-control anrede widthh"
                style="color:#757575;font-size:15px;margin-bottom: 0px !important;" value="" required selected
                formControlName="currentTitle" #select [ngClass]="{'anrededisable':select.value}" tabindex="1">
                <option *ngFor="let prod of currentTitle" [ngValue]="prod.name">
                  {{languageData['Adresseingabe']['Var_'+prod.name] || (prod.name)}}
                </option>
              </select>
              <div
                *ngIf="mandatoryCheck == true && lieferAdresseForm.controls.currentTitle.status == 'INVALID' || lieferAdresseForm.controls.currentTitle.touched && lieferAdresseForm.controls.currentTitle?.errors"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-12 alignwidth">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_Addresszusatz || 'Addresszusatz'}}
            </label>
            <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="addressZusatz"
              tabindex="5" />
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Email || 'Email'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="eMail"
                tabindex="10" />

              <div *ngIf="(lieferAdresseForm.controls.eMail?.errors && (lieferAdresseForm.controls.eMail.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <br>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs labelll aupLabel">
                {{languageData['Adresseingabe'].Var_Vorname || 'Vorname'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="firstName"
                tabindex="2" />
              <div
                *ngIf="(lieferAdresseForm.controls.firstName?.errors && (lieferAdresseForm.controls.firstName?.touched)) "
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Strabe || 'Straße'}}
              </label>
              <input autocomplete="Straße" class="auform-control forLabel widthh" formControlName="streetName"
                tabindex="6" />
              <div
                *ngIf="(lieferAdresseForm.controls.streetName?.errors && (lieferAdresseForm.controls.streetName?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Bestelloptionen'].Var_Telefon || 'Telefon'}}*
              </label>
              <input autocomplete="new-password" (keypress)='telephonevalidation($event.key)'
                class="auform-control forLabel widthh" formControlName="phoneNumber" tabindex="11" />
              <div
                *ngIf="(lieferAdresseForm.controls.phoneNumber?.errors && (lieferAdresseForm.controls.phoneNumber?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Nachname || 'Nachname*'}}
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="lastName"
                tabindex="3" />
              <div
                *ngIf="(lieferAdresseForm.controls.lastName?.errors && (lieferAdresseForm.controls.lastName?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Hausnummer || 'Hausnummer'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="houseNo"
                tabindex="7" />

              <div *ngIf="(lieferAdresseForm.controls.houseNo?.errors && (lieferAdresseForm.controls.houseNo?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-12 alignwidth" style="height: 34px;">
            <div style="display:flex">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_date || 'dateOfBirth(DD.MM.YYYY)'}}
              </label>
              <input class="textbox-n" autocomplete="new-password" [matDatepicker]="picker" (click)="picker.open()"
                focus="picker.open()" [max]="today" class="auform-control widthh" #lGeburtstag
                (dateChange)="onDateChange($event.target.value)" formControlName="dateOfBirth" tabindex="12">


              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="multi-year"></mat-datepicker>

            </div>

          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-12 alignwidth">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_Firma || 'Firma'}}
            </label>
            <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="companyName"
              tabindex="4" />
          </div>
          <!-- *ngIf="((addressType == 'billing') || (addressType == 'secondary' &&  savenewAddress == true) || ((addressType == 'primary') && (addNewAddress == false)))"  -->
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-12 alignwidth"
            *ngIf="!((addressType == 'primary') && (addNewAddress == true))">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_plz || 'PLZ*'}}
              </label>

              <input type="text" class="auform-control forLabel widthh" formControlName="zipCode"
                [matAutocomplete]="autoPlz" tabindex="8" (ngModelChange)="getPlz($event)" (keyup)="changeMyControl()"
                autocomplete="new-password" removeAutoComplete>

              <!-- <input autocomplete="PLZ" class="auform-control forLabel" [attr.disabled]="true" [ngModel]="plz"
          formControlName="zipCode" /> -->
              <!-- [value]="option.country_code" -->
              <mat-autocomplete #autoPlz="matAutocomplete" removeAutoComplete>
                <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectRegionLiefer(option)"
                  [value]="option.country_code + ' ' + option.place_name + ' ' + option.suburb">
                  {{option.place_name}} {{option.country_code}} {{option.suburb}}
                </mat-option>
              </mat-autocomplete>

              <div *ngIf="(lieferAdresseForm.controls.zipCode?.errors && (lieferAdresseForm.controls.zipCode?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
              <div class="alert-danger alert-custom" style="font-size: 13px;" *ngIf="plzOptions?.length == 0">
                {{msg}}
              </div>
            </div>
          </div>
          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-12 alignwidth"
            *ngIf="((addressType == 'primary') && (addNewAddress == true))">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_plz || 'PLZ*'}}
            </label>
            <input type="text" class="auform-control forLabel widthh" [attr.disabled]="true" formControlName="zipCode"
              [matAutocomplete]="autoPlz" autocomplete="new-password" removeAutoComplete tabindex="8">
            <!-- <input autocomplete="PLZ" class="auform-control forLabel" [attr.disabled]="true" [ngModel]="plz"
          formControlName="zipCode" /> -->
          </div>

          <div class="auform-group mb30  aucol-md-12 aucol-lg-12 aucol-sm-12 aucol-xs-12">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_additionaldeliveryinstructions ||'Zusätzliche Lieferhinweise an uns'}}
            </label>
            <textarea class="auform-control" rows="5" formControlName="comments" tabindex="12"
              style=" border-radius: 6px; width: 100%;margin: auto; background: none !important;line-height: 20px;">
            </textarea>
          </div>
        </div>

        <div class="row" *ngIf="innerWidth < 992">

          <div class="auform-group mb30 aucol-lg-4 aucol-md-4 aucol-sm-12 aucol-xs-12 ">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Anrede || 'Anrede'}}
              </label>
              <select name="currentTitle" class="auform-control anrede widthh"
                style="color:#757575;font-size:15px;margin-bottom: 0px !important;" value="" required selected
                formControlName="currentTitle" #select [ngClass]="{'anrededisable':select.value}" tabindex="1">
                <option *ngFor="let prod of currentTitle" [ngValue]="prod.name">
                  {{languageData['Adresseingabe']['Var_'+prod.name] || (prod.name)}}</option>
              </select>
              <div
                *ngIf="mandatoryCheck == true && lieferAdresseForm.controls.currentTitle?.status == 'INVALID' || lieferAdresseForm.controls.currentTitle?.touched && lieferAdresseForm.controls.currentTitle?.errors"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs labelll aupLabel">
                {{languageData['Adresseingabe'].Var_Vorname || 'Vorname'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="firstName"
                tabindex="2" />
              <div
                *ngIf="(lieferAdresseForm.controls.firstName?.errors && (lieferAdresseForm.controls.firstName?.touched)) "
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Nachname || 'Nachname*'}}
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="lastName"
                tabindex="3" />
              <div
                *ngIf="(lieferAdresseForm.controls.lastName?.errors && (lieferAdresseForm.controls.lastName?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-4 alignwidth">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_Firma || 'Firma'}}
            </label>
            <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="companyName"
              tabindex="4" />
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-4 alignwidth">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_Addresszusatz || 'Addresszusatz'}}
            </label>
            <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="addressZusatz"
              tabindex="5" />
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Strabe || 'Straße'}}
              </label>
              <input autocomplete="Straße" class="auform-control forLabel widthh" formControlName="streetName"
                tabindex="6" />
              <div
                *ngIf="(lieferAdresseForm.controls.streetName?.errors && (lieferAdresseForm.controls.streetName?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Hausnummer || 'Hausnummer'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="houseNo"
                tabindex="7" />

              <div *ngIf="(lieferAdresseForm.controls.houseNo?.errors && (lieferAdresseForm.controls.houseNo?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-4 alignwidth"
            *ngIf="!((addressType == 'primary') && (addNewAddress == true))">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_plz || 'PLZ*'}}
              </label>
              <input type="text" class="auform-control forLabel widthh" formControlName="zipCode"
                [matAutocomplete]="autoPlz" tabindex="8" (ngModelChange)="getPlz($event)" autocomplete="new-password"
                removeAutoComplete>
              <!-- [value]="option.country_code" -->
              <mat-autocomplete #autoPlz="matAutocomplete" removeAutoComplete>
                <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="selectRegionLiefer(option)"
                  [value]="option.country_code + ' ' + option.place_name + ' ' + option.suburb">
                  {{option.place_name}} {{option.country_code}} {{option.suburb}}
                </mat-option>
              </mat-autocomplete>

              <div *ngIf="(lieferAdresseForm.controls.zipCode?.errors && (lieferAdresseForm.controls.zipCode?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
              <div class="alert-danger alert-custom" style="font-size: 13px;" *ngIf="plzOptions?.length == 0">
                {{msg}}
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-4 alignwidth"
            *ngIf="((addressType == 'primary') && (addNewAddress == true))">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_plz || 'PLZ*'}}
            </label>
            <input type="text" class="auform-control forLabel widthh" [attr.disabled]="true" formControlName="zipCode"
              [matAutocomplete]="autoPlz" autocomplete="new-password" removeAutoComplete tabindex="8">
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_Email || 'eMail'}}*
              </label>
              <input autocomplete="new-password" class="auform-control forLabel widthh" formControlName="eMail"
                tabindex="10" />

              <div *ngIf="(lieferAdresseForm.controls.eMail?.errors && (lieferAdresseForm.controls.eMail?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <br>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12">
            <div style="position: relative;">
              <label class="posAbs aupLabel">
                {{languageData['Bestelloptionen'].Var_Telefon || 'Telefon'}}*
              </label>
              <input autocomplete="new-password" (keypress)='telephonevalidation($event.key)'
                class="auform-control forLabel widthh" formControlName="phoneNumber" tabindex="11" />
              <div
                *ngIf="(lieferAdresseForm.controls.phoneNumber?.errors && (lieferAdresseForm.controls.phoneNumber?.touched))"
                class="alert-danger alert-custom">
                {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
              </div>
            </div>
          </div>

          <div class="auform-group mb30 aucol-md-4 aucol-lg-4 aucol-sm-4 aucol-xs-4 alignwidth">
            <div style="display:flex">
              <label class="posAbs aupLabel">
                {{languageData['Adresseingabe'].Var_date || 'dateOfBirth(DD.MM.YYYY)'}}
              </label>
              <input class="textbox-n" autocomplete="new-password" [matDatepicker]="picker" (click)="picker.open()"
                focus="picker.open()" [max]="today" class="auform-control widthh" #lGeburtstag
                (dateChange)="onDateChange($event.target.value)" formControlName="dateOfBirth" tabindex="12">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="multi-year">
              </mat-datepicker>
            </div>
          </div>

          <div class="auform-group mb30  aucol-md-12 aucol-lg-12 aucol-sm-12 aucol-xs-12">
            <label class="posAbs aupLabel">
              {{languageData['Adresseingabe'].Var_additionaldeliveryinstructions || 'Zusätzliche Lieferhinweise an
              uns'}}</label>
            <textarea class="auform-control" rows="5" formControlName="comments" tabindex="13"
              style=" border-radius: 6px; width: 100%;margin: auto; background: none !important;line-height: 20px;"></textarea>
          </div>
        </div>
      </div>

      <!-- <mobile view> -->

      <div class="aucol-md-4 aucol-lg-12 aucol-sm-4 aucol-xs-12" *ngIf="addNewAddress && addressType !== 'billing'"
        style="margin-bottom: 30px;">
        <input type="checkbox" name="adresses" [(ngModel)]="savenewAddress" (change)="checkValue($event.target)"
          style="margin-right: 10px;margin-bottom:15px">
        Diese Informationen unter Adressen abspeichern.
      </div>

      <div class="auform-group mb30 ">
        <button type="button" class="cnlBtn font20 smalBtn"
          (click)="cancelBtn()" style="margin-bottom: -12px; margin-top: -14px; margin-left: 15px; background-color: #dddddd !important;">
          {{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}
        </button>
        <button class="au-btn pull-right" type="submit" style="margin-bottom: -12px;
        margin-top: -7px; margin-right: 15px;padding: 6px 12px;font-size:14px"
          [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
          [style.background]="'var(--primaryColour)'" (click)="submitForm();">
          {{languageData['Hamburger'].Var_save || 'Speichern'}}
        </button>
      </div>
    </div>
  </div>
</div>