
<h4 style="margin-top: 0px;text-align:center;color: red;font-family: sans-serif; font-size: 14px;"><b>{{languageData?.login?.Var_delete_online_account || 'Online Konto löschen'}} </b></h4>
<p style= "font-family: sans-serif; font-size: 14px;"> {{languageData?.login?.Var_warning_text || 'Durch das Löschen des online Kontos haben Sie keinen Zugriff mehr auf die Funktionen unseres Portals. Eingestellte Preisalarme bleiben weiter aktiv. Wollen Sie diese deaktivieren, löschen Sie diese bitte vorab.'}}
</p>

<p>{{languageData?.login?.Var_sure_delete_account ||'Online Konto wirklich löschen?'}}</p>

<button mat-dialog-close   class="popbtn cancel-btn" style="float:left" >
 {{languageData?.login?.Var_Abbrechen || 'Abbrechen'}}</button>
<button  mat-dialog-close ="proceed" class="au-btn popbtn" style="float:right;" [ngStyle]="{background:'var(--primaryColour)',color: 'var(--primaryTextColour)'}">
 {{languageData?.Address?.Var_Loschen ||'Löschen'}}</button>