import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MeinepopupComponent } from 'app/popups/meinepopup/meinepopup.component';
import { AdminService } from 'app/Services/admin.service';
import { SmallPcService } from 'app/Services/small-pc.service';
import * as Highcharts from 'highcharts';
import { LanguageService } from '../Services/language.service';
// import { MeineHeizungSetupComponent } from 'app/popups/meine-heizung-setup/meine-heizung-setup.component';
import { DomSanitizer } from '@angular/platform-browser';
import HighchartsMore from 'highcharts/highcharts-more';
import { MeineHeizungSetupComponent } from '../popups/meine-heizung-setup/meine-heizung-setup.component';
// import { CosumptionComponent } from 'app/popups/cosumption/cosumption.component';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { AddresspopupComponent } from 'app/addresspopup/addresspopup.component';
import { InventoyTrackingService } from 'app/Services/inventoy-tracking.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BigpcService } from 'app/Services/bigpc.service';


HighchartsMore(Highcharts);

export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const names = super.getDayOfWeekNames('short');
    return names.map(name => name.substr(0, 3));
  }

  format(date: Date, displayFormat: Object): string {


    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // Return the format as per your requirement
    return `${this._to2digit(day)}.${this._to2digit(month)}.${year}`;

  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}



@Component({
  selector: 'app-create-inventory-tracking',
  templateUrl: './create-inventory-tracking.component.html',
  styleUrls: ['./create-inventory-tracking.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS] }
  ]
})
export class CreateInventoryTrackingComponent implements OnInit {
  currentSetupStage = 4;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  address: any;
  @Output() showPricecalcFromPrimayAddress = new EventEmitter();
  @Output() naivateToAdresses = new EventEmitter();
  addHeatSetup: any;
  currentDate: Date;
  showAdresses: boolean = false;
  adressenpopup: boolean = false;
  addresss: any;
  heatingSetup: boolean;
  heatingOverview: boolean;
  addNewEntry: boolean = false;
  @Input() fromAdreses;
  selectAdress: any;
  select: boolean = false;
  setupView: boolean = false;

  realverbrauch: any = [];
  // realConsumptionYears = ['2018', '2019', '2020', '2021', '2022', '2023'];
  // realConsumptionData = [8650, 8710, 8320, 8390, 8470, 8473];
  realConsumptionYears = [];
  realConsumptionData = [];
  lineGraphData = [];
  lineGaraphObj = {};
  url: any;
  Pellets: any;
  Heizöl: any;
  private _window: any;
  showFullScreenLoading: boolean = false;
  svgs = [
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Fire.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Pellets.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Gas.svg',
    environment.assetsUrl + 'assets/inventory/befullung.svg',
    environment.assetsUrl + 'assets/inventory/Bestandskontrolle.svg',
    environment.assetsUrl + 'assets/inventory/preisrechner.svg',
    environment.assetsUrl + 'assets/inventory/Info.svg',
    environment.assetsUrl + 'assets/inventory/Heaterrr.svg',
    environment.assetsUrl + 'assets/inventory/Graph.svg',
  ];
  svgHtmls = {};
  selectedProduct: any;
  nextConsumptionDate: Date = new Date('01-01-2000');
  addresLength: any;
  constructor(
    public dialog: MatDialog,
    public adminService: AdminService,
    private sampleService: SmallPcService,
    public sanitizer: DomSanitizer,
    public languageService: LanguageService,
    private fb: FormBuilder,
    private inventoryService: InventoyTrackingService,
    public httpClient: HttpClient,
    private newBigPc: BigpcService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) {
    this.currentDate = new Date();
    this.url = window.location.href;
    this.inventoryService.notifyHeatingRecordObservable$.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe((res) => {
      if (res == 'update') {
        this.fromAdreses = null;
        this.getAddresses().then((data): any => {
          console.log(data, 564554);
        });
      }
    });
  }


  ngOnInit(): void {
    let langData = this.languageService.getLocalLanguageData();
    this.languageData = langData["kundeportal"] || {};

    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data["kundeportal"] || {};
    });
    this.adminService.getPortaladdresses(true).subscribe((result: any) => {
      if (result.data && Array.isArray(result.data)) {
        this.addresLength = result.data.length;
      }
    });
    if (this.fromAdreses?.show) {
      this.meinepopupopen();
      this.fromAdreses = { show: true, address: this.fromAdreses.address };
      setTimeout(() => {
        this.fromAdreses = { show: false, address: {} }
      }, 1000)
      this.heatingOverview = false
    } else {
      this.getAddresses().then(res => {


      });
    };
    this.getDynamicSVG(this.svgs);
  }

  selectedProductIcon(icon) {
    this.inventoryService.fetchInventoryProducts({}).subscribe((data: any) => {
      if (data.success) {
        let inventoryOverAllData = data.data;
        inventoryOverAllData = inventoryOverAllData.filter(each => (each.type == 'products' && each.name == icon));
        inventoryOverAllData.forEach((data) => {
          data.svgFullPath && this.byPassSVGSecurityItem(data.svgFullPath, data.svgUrl);
        });
      }
    })
  }

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      })
    });
  };
  meinepopupopen() {
    this.inventoryService.fetchInventoryProducts({}).subscribe((data: any) => {
      let myHeatNoProducts = data.data.find(p => p.type == 'products');
      if (!myHeatNoProducts) {
        this.dialog.open(MeinepopupComponent, {
          disableClose: true,
          data: { type: 'noProducts' }
        });
      } else if (this.addresss?.some(e => e.myheating.isHeating == true) && !this.fromAdreses?.show) {
        this.dialog.open(MeinepopupComponent, {
          // disableClose: true,
          data: { type: 'meinepopup' }
        });
      } else {
        this.heatingOverview = false;
        let meineopen = this.dialog.open(MeinepopupComponent, {
          // disableClose: true,
          data: {
            type: 'meinepopup',
            address: this.fromAdreses
          }
        });
        meineopen.afterClosed().subscribe(async (data: any) => {
          this.setupView = true;
          if (data?.address) {
            this.naivateToAdresses.emit(true);
          }
        });
      }
    });
  }

  openHyperlink() {
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        type: 'hyperlink'
      }
    });
    meineopen.afterClosed().subscribe(async (data: any) => {
    });
  }

  heatingSystemInfo: any;
  ngAfterViewInit() {

  };

  unitsName: any = "l";
  prepareBarChartHere(selectAddr) {

    let addId = {
      addId: selectAddr?._id,
    };

    this.inventoryService.getbarGraphData(addId).subscribe((result: any) => {
      this.heatingSystemInfo = result.data;

      let consumptionPastData = result.data.pastConsumption || [];
      let consumptionFutureData = result.data.futureConsumption || [];
      const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour').trim();
      let monthsNames = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
      let xAxisMonths = [];
      let yAxisAmount = [];
      this.consumptionDetails = result.data['consumptionDetails'] || [];
      this.consumptionDetails = this.consumptionDetails.slice(this.consumptionDetails.length - 5, this.consumptionDetails.length)
      if (Array.isArray(this.consumptionDetails) && this.consumptionDetails.length) {
        this.realConsumptionYears = this.consumptionDetails.map(xy => new Date(xy.toDate).getFullYear());
        // let sortedData=  this.consumptionDetails.sort((a, b) => new Date(a.toDate) - new Date(b.toDate));
        // this.consumptionDetails = this.consumptionDetails.sort((a, b) => new Date(b.toDate).getTime() - new Date(a.toDate).getTime());

        this.identifyNextDate(this.consumptionDetails[this.consumptionDetails.length - 1].toDate);
        this.realConsumptionData = this.consumptionDetails.map(item => {
          const date = new Date(item.toDate);
          return [Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), item.consumption];
        });
      };

      if (consumptionPastData.length) {
        consumptionPastData.forEach((m, i) => {
          xAxisMonths.push(monthsNames[m._id.month - 1]);
          yAxisAmount.push({
            y: m.consumption,
            color: primaryColour
          });
        });
      };

      if (consumptionFutureData.length) {
        consumptionFutureData.forEach((m, i) => {
          if (m) {
            xAxisMonths.push(monthsNames[m._id.month - 1]);
            yAxisAmount.push({
              y: m.consumption,
              color: 'gray'
            });
          }
        });
      };

      let maxYaxisVal = 0;
      for (let i = 0; i < yAxisAmount.length; i++) {
        if (yAxisAmount[i].y > maxYaxisVal) {
          maxYaxisVal = yAxisAmount[i].y;
        };
      };

      Highcharts.chart('bar-chart-container', {
        chart: {
          type: 'column'
        },
        title: {
          text: this.languageData['Energie']['VarKundeportalEnergie8'] || 'Monatsverbraacuch',
          align: 'left',
          style: {
            fontSize: '18px',
          }
        },
        xAxis: {
          categories: xAxisMonths,
          crosshair: true,
          title: {
            text: null
          },
          labels: {
            style: {
              fontSize: '12px',
            }
          }
        },
        yAxis: {
          lineWidth: 1,
          min: 0,
          max: maxYaxisVal || 1000,
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              return this.value.toLocaleString('de-DE');
            },
            style: {
              fontSize: '12px',

            }
          },
        },
        tooltip: {
          // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          // pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} units</b></td></tr>',
          headerFormat: `
          <table>
          <span style="font-size:10px">
             {point.key}
          </span>`,
          pointFormat: `
          <tr>
          <td style="padding:0">
             <b>{point.y:.1f} ${this.selectAdress?.myheating?.productUnits?.name}</b>
          </td>
          </tr>`,
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          type: 'column',
          showInLegend: false,
          data: yAxisAmount
        }]
      });
      this.realConsumptionChartDraw();

    });
  };

  getAddresses(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.adminService.getPortaladdresses(true).subscribe(async (result: any) => {
        if (result.success) {
          // this.addresss = result.data.sort((a, b) => (b.isPrimary ? 1 : -1));
          this.addresss = result.data
            .sort((a, b) => (b.isPrimary ? 1 : -1) - (a.isPrimary ? 1 : -1) || a._id.localeCompare(b._id))
            .map((item, index) => ({ ...item, sortOrder: index + 1 }));
          console.log(this.addresss, 5454);
          if (this.fromAdreses && Object.keys(this.fromAdreses.address).length > 0 && !this.fromAdreses.show) {
            this.selectAdress = this.addresss.find(ele => ele._id === this.fromAdreses.address?._id)
            this.fromAdreses.address = {};
          } else if (this.selectAdress && Object.keys(this.selectAdress).length) {
            this.selectAdress = this.addresss.find(ele => ele._id === this.selectAdress?._id);
          };
          if (!this.selectAdress) {
            this.selectAdress = this.addresss.find(ele => ele.myheating?.isHeating);
          }
          if (!this.selectAdress) {
            this.setupView = true;
          } else {
            let addressId = this.selectAdress?.invData?.addressId;
            if (addressId) {
              await this.getLineGraphData(addressId);
            }
          }
          this.heatingOverview = !!this.selectAdress;
          if (this.selectAdress) {
            this.selectedProductIcon(this.selectAdress?.myheating?.productName);
            this.prepareBarChartHere(this.selectAdress);
          }
          resolve();
        } else {
          this.addresss = [];
          reject(result);
        }
      });
    });
  }

  openAdress() {
    let dialog = this.dialog.open(AddresspopupComponent, {
      data: {
        address: this.addresss,
        selectedAdress: this.selectAdress._id,
        width: '550px',
        height: '500px'
      }
    })
    dialog.afterClosed().subscribe(async (data: any) => {
      console.log(data, 422)
      if (data && data?._id) {
        this.natigateSetup(data._id);
      } else if (data.address) {
        this.naivateToAdresses.emit(true)
      }
    })
  };

  initalizeProcess(address) {
    let data: any = {
      type: 'meinepopup',
      edit: false,
    }
    if (address && Object.keys(address).length) {
      data = { ...data, edit: true, "address": address }

    }
    console.log(data, 999);
    let heatingSystem = this.dialog.open(
      MeineHeizungSetupComponent,
      {
        disableClose: true,
        data: data
      });
    heatingSystem.afterClosed().subscribe(async (resp: any) => {

      // saveMyHeatingresp
      if (data && data?.edit) {
        resp?.data && this.saveMyHeatingData(resp?.data)
      }
      if (!data.edit && resp.success) {
        this.getAddresses().then(res => {


        });
      } else {
      };
    });
  };

  natigateSetup(adressId) {
    let setupAdress = this.addresss.find(address => address._id === adressId);
    // console.log(setupAdress, 465);
    if (setupAdress?.myheating?.isHeating) {
      this.selectAdress = setupAdress;
      setTimeout(() => {
        // this.dashboardSPLineChart();
        let addressId = this.selectAdress?.invData?.addressId;
        this.getLineGraphData(addressId);
        this.selectedProductIcon(this.selectAdress?.myheating?.productName);
        this.prepareBarChartHere(this.selectAdress);
      }, 1000);

      this.heatingOverview = true;
      this.showAdresses = false;
    } else {
      this.heatingOverview = false;
      this.showAdresses = false;
      this.setupView = true;
      this.select = this.addresss.some(e => e.myheating.isHeating == true);
    };
  };

  openFilling() {
    let meineopen = this.dialog.open(MeinepopupComponent, {
      disableClose: true,
      data: {
        width: '550px',
        type: 'tankFill',
        heatingData: this.selectAdress.myheating
      }
    });

    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data.save) {
        this.selectAdress.myheating.currentActiveLevel = data.data.newFuelLevel;
        this.inventoryService.saveIventoryFilling({ data: data.data, type: 'fill', addressId: this.selectAdress._id }).subscribe(async data => {

          if (data.success) {
            await this.getAddresses();
          } else {
            await this.getAddresses();
          };
        });
      } else {
        // this.getAddresses();
      };
    });
  };

  openBestands() {

    let meineopen = this.dialog.open(
      MeinepopupComponent,
      {
        disableClose: true,
        data: {
          width: '550px',
          type: 'Bestandskontrolle',
          heatingData: this.selectAdress.myheating
        }
      });

    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data.save) {
        this.inventoryService.saveIventoryFilling({ data: data.data, type: 'Inventory', addressId: this.selectAdress._id }).subscribe(data => {

          if (data.success) {
            this.getAddresses();
          } else {
            this.getAddresses();
          };
        });
      } else {
        // this.getAddresses();
      };
    });
  };

  getPercentageLevel(heatingData) {
    const level = typeof heatingData.activePercentageLevel === 'string' ? Number(heatingData.activePercentageLevel) : heatingData.activePercentageLevel;
    const percentage = `${(level * 3 / 4)} 75`;
    const circleRadius = 15.91549430918954;
    const circleCentreXY_AXIS = 21;
    const totalDegrees = 360 * 0.75;

    const getIndicator = (reminderValue, offset, label) => {
      const reminderPercentage = (reminderValue * 100) / heatingData.maxTankSize;
      const percentageOffset = (reminderPercentage + (offset * 3 / 4));
      const angle = (percentageOffset / 100) * totalDegrees;
      const radian = angle * (Math.PI / 180);
      const x = circleCentreXY_AXIS + circleRadius * Math.cos(radian - Math.PI / 2);
      const y = circleCentreXY_AXIS + circleRadius * Math.sin(radian - Math.PI / 2);
      return `
        <circle width="10" height="10" cx="${x}" cy="${y}" r="2.5" fill="white" stroke="gold" stroke-width="0.8" />
       <text x="${x}" y="${y}" text-anchor="middle" dominant-baseline="middle" dy="0.2" style="font-size: 2px; fill: black; font-weight: bold;">${label}</text>`;
    };

    const firstIndicator = heatingData?.hasWarning1 && heatingData?.reminderOneValue ? getIndicator(heatingData?.reminderOneValue, 288, '1') : '';
    const secondIndicator = heatingData?.hasWarning2 && heatingData?.reminderTwoValue ? getIndicator(heatingData?.reminderTwoValue, 290, '2') : '';

    const svgContent = `
    <svg style="position: relative; right: 5px;" width="250px" height="250px" viewBox="0 0 42 42">
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round" style="stroke: #ddd; stroke-width: 2; fill: transparent; position: relative; right: 5px;" />
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round"
        style="stroke: var(--primaryColour); stroke-width: 2; fill: transparent; stroke-dasharray: ${percentage}; transition: stroke-dasharray 0.3s;" />
        ${firstIndicator} 
        ${secondIndicator}
    </svg>`;

    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }


  clickToOverview() {
    this.heatingOverview = true;
  };

  public errorMessage: string;
  consumptionForms: FormGroup[] = [];
  consumptionForm = new FormGroup({
    fromDate: new FormControl('', [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1)]),
    toDate: new FormControl('', [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1)]),
    total: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')])
  });


  addConsumption() {

    // this.editble = true;
    this.addNewEntry = true;
    // Clear the form array if it's a FormArray
    if (this.consumptionForms instanceof FormArray) {
      this.consumptionForms.clear();
    }
    // Or set it to an empty array if it's a standard array
    else {
      this.consumptionForms = [];
    };

    // Then populate the form array
    !this.realverbrauch.length && this.realverbrauch.push({
      "fromDate": '',
      "toDate": '',
      "total": ''
    })

    this.realverbrauch.forEach(consumption => {
      if (consumption.fromDate !== undefined && consumption.total !== undefined && consumption.toDate !== undefined) {
        const formGroup = this.fb.group({
          fromDate: [[consumption.fromYear], [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1),]],
          toDate: [[consumption.toDate], [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1),]],
          total: [consumption.total, [Validators.required, Validators.pattern('^[0-9]*$')]]
        });
        this.consumptionForms.push(formGroup);

        // formGroup.statusChanges.subscribe(status => {

        //   if (status === 'INVALID') {
        //     this.errorMessage = 'Nur positive und ganzzahlige Eingaben akzeptiert';
        //   } else {
        //     this.errorMessage = null;
        //   }
        // });
      };
    });
    // const dialog = this.dialog.open(CosumptionComponent, {
    //   width: '250px',
    //   data: this.realverbrauch
    // })
    // dialog.afterClosed().subscribe((result) => {

    //   if (result) {

    //     let consumptionData = result.filter(value => value.total !== null && value.total !== undefined && value.year != '' && !isNaN(value.total)).map(value => value.total);

    //     this.realverbrauch = result;

    //     this.realConsumptionYears = result.map((value) => value.year);


    //     this.realConsumptionData = result.filter(value => value.total !== null && value.total !== undefined && !isNaN(value.total)).map(value => value.total);

    //     this.realConsumptionChartDraw();
    //   } else {



    //   }
    // })
  };
  realConsumptionChartDraw() {
    const flattenedArray = this.realConsumptionData.reduce((acc, val) => acc && acc.concat(val[1]), []);
    const maxNumber = flattenedArray.length ? Math.max(...flattenedArray) : 0;
    const max = flattenedArray.length ? Math.ceil(maxNumber / 1000) * 1000 : 1000;

    Highcharts.chart('realConsumption-chart-container', {
      chart: {
        type: 'line'
      },
      title: {
        text: this.languageData['Energie']['VarKundeportalEnergie7'] || 'Jahresverbrauch',
        align: 'left',
        style: {

          fontSize: '18px',
          textAlign: 'left'
        }
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 365 * 24 * 3600 * 1000, // one year
        //   labels: {
        //     formatter: function () {
        //         return Highcharts.dateFormat('%Y', this.value);
        //     }
        // },
        accessibility: {
          rangeDescription: 'Range: 2010 to 2020'
        },
        labels: {
          style: {
            fontSize: '12px',
          }
        },

      },
      yAxis: {
        lineWidth: 1,
        min: 0,
        max: max,
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            return this.value.toLocaleString('de-DE');
          },
          style: {
            fontSize: '12px',
          }
        },

      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      series: [{
        type: 'line',
        showInLegend: false,
        data: this.realConsumptionData
      }]
    });
  };

  onCancel(): void {
    this.addNewEntry = false;
    this.realverbrauch = this.realverbrauch.filter(value => value.total !== null && value.total !== undefined && value.year != '' && !isNaN(value.total)).map(value => value.total);
    this.errorMessage = '';
    this.consumptionForm.reset();
  };
  consumptionDetails = [];

  yearData = [];
  consumeData;
  onSave() {

    if (this.consumptionForm.value.fromDate.getFullYear() == this.consumptionForm.value.toDate.getFullYear()) {
      // this.consumeData.push(this.consumptionForm.value.fromDate.getFullYear());
      let tempdata = [
        {
          fromDate: this.consumptionForm.value.fromDate,
          toDate: this.consumptionForm.value.toDate,
          consumption: this.consumptionForm.value.total
        }
      ]
      // this.consumptionDetails = [...this.consumptionDetails, ...tempdata];
      //   this.yearData.push({
      //    year: this.consumptionForm.value.fromDate.getFullYear(),
      //    consumption: this.consumptionForm.value.total
      //   });
      // this.consumptionDetails = this.consumptionDetails.sort((a, b) => a.year - b.year);
      // this.realConsumptionYears = this.yearData.map((value) => value.year);
      // this.realConsumptionData = this.yearData.map((value) => parseFloat(value.consumption));
      // this.realConsumptionChartDraw();
    }

    // this.errorMessage = '';
    // const emptyForms = this.consumptionForms.filter(form => form.value.year === '' || form.value.total === '');
    // if (emptyForms.length > 0) {
    //   this.errorMessage = 'Bitte machen Sie eine gültige Jahres- und Verbrauchseingabe!';
    //   return;
    // };
    // const inValidForms = this.consumptionForms.filter(form => form.invalid);
    // if (inValidForms.length > 0) {
    //   inValidForms.forEach(form => {
    //     if (form.value.year && !this.isOnlyNumbers(form.value.total)) {
    //       this.errorMessage = 'Nur positive und ganzzahlige Eingaben akzeptiert';
    //     }
    //   })
    //   return
    // };

    // const values = this.consumptionForms.map(form => ({ "year": form.value.year, "total": parseInt(form.value.total) }));

    // // let filteredData = values.filter(value => value.year !== undefined && value.total !== undefined && value.year !== '');

    this.inventoryService.savePastConsumptionEntry({ ...this.consumptionForm.value, addressId: this.selectAdress._id }).subscribe((result: any) => {
      if (result.success) {
        //  this.consumptionDetails=  result.data;
        this.addNewEntry = !this.addNewEntry;
        if (result.data.length) {
          this.consumptionForm.reset();
          result.data[0].hasOwnProperty('consumptionDetails') && this.transformGraphData(result.data[0]['consumptionDetails']);
        }
      } else {

      }
    });

  };

  isOnlyNumbers(str) {
    const regex = /^[0-9]*$/;
    return regex.test(str);
  };

  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;
  today = new Date();

  dashboardSPLineChart() {
    if (this.chartContainer) {
      let currentDate = new Date().setHours(0, 0, 0, 0); // current date at 00:00:00
      this.lineGraphData.sort((a, b) => a[0] - b[0]);

      let currentDateIndex = this.lineGraphData.findIndex(point => new Date(point[0]).setHours(0, 0, 0, 0) > currentDate); // index of the first point that is in the future
      const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour').trim();
      // If all points are in the past, set currentDateIndex to the length of the data
      if (currentDateIndex === -1) {
        currentDateIndex = this.lineGraphData.length;
      }

      const maxCapacity = this.lineGaraphObj['maxCapacity'];
      const maxYAxisValue = Math.ceil(maxCapacity / 1000) * 1000;

      const lineValues = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];

      const plotLines = lineValues.map((value, index) => ({
        value: value,
        zIndex: 5,
        color: 'rgba(230, 230, 230, 1)',
        width: 1,
      }));

      Highcharts.setOptions({
        lang: {
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          decimalPoint: ',',
          thousandsSep: '.'
        }
      });

      Highcharts.chart(this.chartContainer.nativeElement, {
        chart: {
          type: 'line',
          events: {
            load: function () {
              var chart = this,
                points = chart.series[0].points,
                zones = [],
                color,
                maxValue,
                maxValue2,
                chosenPoint,
                chosenPoint2;
              let i = 0;
              points.forEach(function (point, index) {

                if (new Date(point.x).getDate() == new Date().getDate() && new Date(point.x).getMonth() == new Date().getMonth()) {
                  maxValue = point.y;
                  chosenPoint = point;
                }
              });

              chosenPoint?.update(
                {
                  marker: {
                    enabled: true,
                    symbol: 'circle', fillColor: 'red', radius: 5,
                    linewidth: 2,
                  }
                }
              );
            }
          }
        },

        title: {
          text: 'Bestandsentwicklung',
          align: 'left',
          style: {
            fontSize: '18px',
            textAlign: 'left'
          }
        },
        subtitle: {
          text: `Max ${maxCapacity?.toLocaleString('de-DE')} ${this.selectAdress.myheating.productUnits.name}`,
          align: 'left',
          style: {
            fontSize: '14px',
            textAlign: 'left',
            color: '#333'
          }
        },
        xAxis: {
          tickWidth: 0,
          type: 'datetime',
          dateTimeLabelFormats: {
            // day: '%e of %b'
            month: '%b',
          },
          labels: {
            style: {
              fontSize: '12px',
            }
          },
          tickInterval: 30 * 24 * 3600 * 1000,
          currentDateIndicator: true,
          // min: 0,
          lineWidth: 1,
          // gridLineWidth: 1,
          plotLines: [
            {
              color: 'Gold',
              width: 2,
              zIndex: 9999999,
              value: new Date(this.lineGaraphObj['warningAlertDate']).getTime(),
              dashStyle: 'Dash',
              label: {
                text: `${this.lineGaraphObj['reminderRange']} Tage Warnung`,
                align: 'right',
                rotation: 0,
              },
            }
          ]
        },

        yAxis: {
          title: {
            text: null
          },
          max: maxYAxisValue,
          lineWidth: 1,
          // gridLineWidth: 1,
          min: 0,
          tickInterval: 1000, // ✅ Force intervals at 1000
          labels: {
            formatter: function () {
              return this.value.toLocaleString('de-DE');
            },
            style: {
              fontSize: '12px',
            }
          },
          plotLines: plotLines
        },

        plotOptions: {
          series: {
            lineWidth: 5,
            dataLabels: {
              enabled: false,
              overflow: "justify",
            },
          },

        },
        tooltip: {
          enabled: false,
        },
        series: [
          {
            marker: {
              radius: 0,
            },
            data: this.lineGaraphObj['past'],
            lineWidth: 1,
            type: 'line',
            showInLegend: false,
            color: primaryColour, // color for the past data points
            // zones: [
            //   {
            //     value: this.lineGraphData[currentDateIndex - 1][0], // x-value of the last point that is not in the future
            //     color: 'blue', // color up to the current date
            //   },
            //   {
            //     color: 'grey', // color after the current date
            //   },
            // ]

            // zones: (() => {
            //   let zones = [];
            //   this.lineGraphData.forEach(point => {
            //     let date = new Date(point[0]).setHours(0, 0, 0, 0);
            //     if (date <= currentDate) {
            //       zones.push({
            //         value: this.lineGraphData[currentDateIndex][0],
            //         color: '#39baea'
            //       });
            //     } else {
            //       zones.push({
            //         color: 'grey'
            //       });
            //     }
            //   });

            //   return zones;
            // })()
          },
          {
            marker: {
              radius: 0,
            },
            data: this.lineGaraphObj['future'],
            lineWidth: 1,
            type: 'line',
            showInLegend: false,
            color: 'gray',
          }]
      });
    } else {

    };
  };

  series = [];
  async getLineGraphData(addressId) {
    this.inventoryService.getLineGraphData({ addressId: addressId }).subscribe((result: any) => {
      if (result.success) {
        this.lineGaraphObj['lineGraphData'] = result.data[0]['transformedGraphData'];
        this.lineGaraphObj['maxCapacity'] = result.data[0]['tankSize'];
        this.lineGaraphObj['past'] = result.data[0]['pastData'];
        this.lineGaraphObj['future'] = result.data[0]['futureData'];
        this.lineGraphData = result.data[0]['transformedGraphData'];
        this.lineGraphData = this.lineGraphData.filter(point => point[1] >= 0)
        this.series = this.lineGraphData.map(point => {
          return {
            value: point[0],
            color: new Date(point[0]) > new Date() ? 'blue' : 'red',
            // fillColor: new Date(point[0]) >  new Date()? 'blue' : 'red',
          }

        });
        this.lineGaraphObj['warningAlertDate'] = result.data[0]['warningAlertDate'];
        this.lineGaraphObj['reminderRange'] = result.data[0]['reminderRange'];
        setTimeout(() => {
          this.dashboardSPLineChart();
        }, 1000)
      } else {

      }
    });
  };
  onDateChange(date, from) {

  }

  cancel() {
    this.addNewEntry = false;
  }


  editHeating() {

    // this.initalizeProcess(this.selectAdress.invData)
    console.log(this.selectAdress, 544);
    let procesData = { ...this.selectAdress.invData, weatherId: this.selectAdress.weatherId, sunhourStation: this.selectAdress.sunhourStation, Edit: true };
    console.log(procesData, 545);
    this.initalizeProcess(procesData);

  }

  saveMyHeatingData(data) {
    console.log(data, 545);
    if (!data) {
      return
    }
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string' && value !== '') {
        data[key] = parseInt(value, 10);
      }
    });
    ['stageFour', 'stageThree', 'fillingData',
      'stageTwo', 'weatherId', 'unternehmenId', 'QA',
      'myMasterId', 'consumptionInKWH', 'createdAt',
      'gtzValue', 'kundenKontoId', 'lastFuelUpdatedDate', '_id'].forEach(property => {
        if (data.hasOwnProperty(property)) {
          delete data[property];
        }
      });

    data = { ...data, addressId: this.selectAdress._id };

    console.log(data, 65654);
    this.showFullScreenLoading = true;
    this.adminService.saveInventoryData({ inventoryData: data }).subscribe(resp => {
      if (resp && resp.success) {
        this.showFullScreenLoading = false;
        this.getAddresses();
      } else {
        this.showFullScreenLoading = false;
      }
    }, (err) => this.showFullScreenLoading = false);
  };
  deleteLastRow(event) {

    let object = { toDate: event.toDate, addressId: this.selectAdress._id, fromDate: event.fromDate, consumption: event.consumption };
    // this.realverbrauch = this.realverbrauch.filter((value, index) => index !== event); 
    this.inventoryService.removeConsumptionEntry(object).subscribe((result: any) => {
      console.log(result, 1038)
      if (result.success && result.data.length) {
        result.data[0].hasOwnProperty('consumptionDetails') && this.transformGraphData(result.data[0]['consumptionDetails']);
      } else {
        this.transformGraphData([]);
      }
    });
  }

  transformGraphData(consumpion) {

    if (Array.isArray(consumpion) && consumpion.length) {
      this.realConsumptionYears = consumpion.map(xy => new Date(xy.toDate).getFullYear());

      // this.consumptionDetails = consumpion.sort((a, b) => new Date(b.toDate).getTime() - new Date(a.toDate).getTime());
      this.consumptionDetails = consumpion;
      this.consumptionDetails = this.consumptionDetails.slice(this.consumptionDetails.length - 5, this.consumptionDetails.length)
      this.identifyNextDate(this.consumptionDetails[this.consumptionDetails.length - 1]['toDate']);

      this.realConsumptionData = this.consumptionDetails.map(item => {
        const date = new Date(item.toDate);
        return [Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()), item.consumption];
      });
    } else {
      this.identifyNextDate('1999-12-31');
      this.realConsumptionYears = [];
      this.realConsumptionData = [];
      this.consumptionDetails = [];
    }
    this.realConsumptionChartDraw();
  }

  minimumDate = new Date('2022-01-01');
  setToDate(event) {

    this.minimumDate = new Date(event);

  }
  resetDate(event) {
    let nextDay: Date = this.consumptionForm.value.toDate;
    nextDay = new Date(nextDay)
    nextDay.setDate(nextDay.getDate() + 1);
    this.minimumDate = new Date(new Date().setFullYear(new Date().getFullYear() - 6));


    this.nextConsumptionDate = nextDay;

  }
  identifyNextDate(event) {

    if (event) {
      let nextDay: Date = new Date(event);
      nextDay.setDate(nextDay.getDate() + 1);
      this.nextConsumptionDate = nextDay;
    } else {
      let nextDay: Date = this.consumptionForm.value.toDate;
      nextDay = new Date(nextDay)
      nextDay.setDate(nextDay.getDate() + 1);
      this.nextConsumptionDate = nextDay;
    }
  };

  // getSvg(type) {
  //   switch (type) {
  //     case 'Hezoil': return this['Fire'];
  //     case 'Pellets': return this['Pellets'];
  //     case 'Gas': return this['Gas'];
  //     default: return this['Fire']
  //   }

  // }

  byPassSVGSecurityItem(url: string, id: string): void {
    this.httpClient.get(url, { responseType: 'text' }).subscribe(data => {
      this.svgHtmls[id] = this.sanitizer.bypassSecurityTrustHtml(data);
      this.selectedProduct = this.svgHtmls[id]
      this.cdr.detectChanges();
      this.changeColor();
      return this.svgHtmls[id]
    });
  }

  changeColor() {
    setTimeout(() => {
      let productSVG = this.el.nativeElement.querySelector('.productIcon svg');
      if (productSVG) {
        productSVG.style.width = "25px";
        productSVG.style.height = "25px";
        let paths = productSVG.getElementsByTagName('path');
        for (let j = 0; j < paths.length; j++) {
          if (paths[j].outerHTML != '<path d="M44 0L87.3013 75H0.69873L44 0Z" fill="red"></path>') {
            let primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour');
            primaryColour = primaryColour.trim();
            paths[j].setAttribute('fill', primaryColour);
          }
        }
      }

    }, 0);
  };

  async calculatePrice() {
    let primaryAdress = this.addresss.find(item => item.isPrimary == true);
    let params = new URLSearchParams(this.url);
    let sessionId = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.sampleService.validateSessionData(sessionId);
    if (!sessionExists) {
      let obj = { primaryAdress: primaryAdress, loginUser: true, selectedProduct: {} };
      this.newBigPc.createNewSession(obj)
    } else {
      this.showPricecalcFromPrimayAddress.emit(primaryAdress);
    };
  };
}