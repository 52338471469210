<div class="aupris_main scrolltotopprogress main_body">
  <!-- theme 1 start -->
  <div class="aurow aucol-md-12 section-container space" *ngIf="productInfoForWebPC.themeModel == 1">
    <!-- Besrelloptionen & Addresses -->
    <div class="aucol-md-5">
      <!-- ratePayment true -->
      <div class="aurow" style="margin-top:20px;" *ngIf="ratePayment === true">
        <div class="control_label aucol-sm-5 aucol-xs-6" style="margin-left: -53px;">
          <svg style="margin-top: 7px;" [ngStyle]="{fill: 'var(--primaryColour)'}" height="20px"
            viewBox="0 -65 434.67733 434" width="30px" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m152.003906 304.34375c-5.460937 0-10.921875-2.089844-15.082031-6.25l-130.664063-130.667969c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339844-8.339844 21.820313-8.339844 30.164063 0l115.582031 115.582031 246.253906-246.25c8.339844-8.339844 21.820313-8.339844 30.164063 0 8.339844 8.34375 8.339844 21.824219 0 30.167969l-261.332031 261.332031c-4.160156 4.160156-9.625 6.25-15.085938 6.25zm0 0" />
          </svg>
        </div>
        <div class=" aucol-sm-7 aucol-xs-6 " style="margin-top: 8px;margin-left: -23px;">
          <b [ngStyle]="{color: 'var(--primaryColour)'}">
            {{languageData['Bestelloptionen'].Var_Installment_purchase_confirmed || 'Ratenkauf wurde bestätigt!'}}
          </b>
        </div>
      </div>
      <br>
      <!-- ratePayment true bankdetails -->
      <div class="aurow" *ngIf="false">
        <div class="control_label aucol-sm-5 aucol-xs-6">
          {{languageData['Bestelloptionen'].Var_installment_payments || 'Ratenzahlung'}}:
          <p style="cursor:pointer;margin-left:54px;" (click)="page2()">
            <a>
              {{languageData['Bestellubersicht'].Var_andern || 'ändern'}}
            </a>
          </p>
        </div>
        <div class=" aucol-sm-7 aucol-xs-6 " style="margin-left:-15px;">
          <div>{{bankdetails?.laufzeit || 3}} {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}</div>
          <div>{{bankdetails?.kontoinhaber || "vasasadads"}} </div>
          <div>{{bankdetails?.iban || "DE02120300000000202051"}} </div>
          <div>{{bankdetails?.bic}} </div>
          <div>{{bankdetails?.bank_name}}</div>
          <div>{{bankdetails?.mobilfunknummer}}</div>
        </div>
      </div>
      <br>
      <!-- Bestelloptionen -->
      <div class="aurow">
        <div class="control_label aucol-sm-5 aucol-xs-6 bestel" id="best">
          {{languageData['Bestellubersicht'].Var_Bestelloption || 'Bestelloption'}}:
          <p style="cursor:pointer;margin-left:54px;" (click)="page1()" class="andealign">
            <a>
              {{languageData['Bestellubersicht'].Var_andern || 'ändern'}}
            </a>
          </p>
        </div>
        <div class="aucol-sm-7 aucol-xs-6 palignment textfix textalign">
          <div class="aurow">
            <span class="">
              {{languageData['Bestelloptionen'].Var_Abladestellen || 'Abladestellen'}}:
            </span>
            <span class="">
              {{sessionData?.stations}}
              {{languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte'}}
            </span>
          </div>
          <div *ngFor="let options of localOptions">
            <span>
              <div class="aurow">
                <span>
                  {{options.lable }}:</span>
                <span>
                  {{options.name }}
                </span>
              </div>

              <!-- Desired date settings From date Start here -->
              <div class="aurow" *ngIf="sessionData?.desiredDateSettings.wunstermin && options.name == 'Wunschtermin'">
                <!-- desired date settings -->
                <span>
                  {{languageData['Bestelloptionen'].Var_firstDeliveryDate_text || 'Frühester Liefertermin'}}:
                </span>
                <span>
                  {{sessionData?.desiredDateSettings.fromDate}}
                </span>
              </div>
              <!-- Desired date settings From date End here -->

              <!-- Desired date settings To date Start here -->
              <div class="aurow" *ngIf="sessionData?.desiredDateSettings.wunstermin && options.name == 'Wunschtermin'">
                <span>
                  {{languageData['Bestelloptionen'].Var_lastDeliveryDate_text ||'Spätester Liefertermin'}}:
                </span>
                <span> {{sessionData?.desiredDateSettings.toDate}}</span>
              </div>
              <!-- Desired date settings To date End here -->
            </span>
          </div>
        </div>
      </div>
      <br>

      <!-- addresses -->
      <div class="aurow">
        <!-- Rechnungsadresse -->
        <ng-container *ngIf="stationCount == 1">
          <div class="control_label aucol-sm-5 aucol-xs-6 bestel">
            {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}:
            <p style="cursor:pointer;margin-left:54px;" (click)="page2()" class="andealignr">
              <a>{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</a>
            </p>
          </div>

          <ng-container *ngIf="billingAddressEnable">
            <div class=" aucol-sm-7 aucol-xs-6 textfix rechadress" style="margin-left:-15px;"
              *ngFor="let billing of billingAddress; let i = index;">
              <div>
                <span>
                  {{ billing?.firstName + ' ' + billing?.lastName }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.streetName + ' ' + billing?.houseNo }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.zipCode + ' ' + billing?.ort }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.phoneNumber }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.eMail}}
                </span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!billingAddressEnable">
            <div class=" aucol-sm-7 aucol-xs-6 textfix rechadress" style="margin-left:-15px;"
              *ngFor="let billing of deliveryAddress; let i = index;">
              <div>
                <span>
                  {{ billing?.firstName + ' ' + billing?.lastName }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.streetName + ' ' + billing?.houseNo }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.zipCode + ' ' + billing?.ort }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.phoneNumber }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.eMail }}
                </span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="stationCount > 1">
          <div class="control_label aucol-sm-5 aucol-xs-6 bestel">
            {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}:
            <p style="cursor:pointer;margin-left:54px;" (click)="page2()" class="andealignr">
              <a>{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</a>
            </p>
          </div>

          <ng-container *ngIf="selectedBiilingOption == 'Einzelrechnungent'">
            <div class="aucol-sm-7 aucol-xs-6 textfix" style="margin-left:-15px;">
              <div>
                {{languageData['Bestellubersicht'].Var_DeleveryAddAsBillingAddress || 'Lieferadressen gleich
                Rechnungsadressen'}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedBiilingOption == 'Sammelrechnung'">
            <div class="aucol-sm-7 aucol-xs-6 textfix rechadress" style="margin-left:-15px;"
              *ngFor="let billing of billingAddress">
              <div>
                <span>
                  {{ billing?.firstName + ' ' + billing?.lastName }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.streetName + ' ' + billing?.houseNo }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.zipCode + ' ' + billing?.ort}}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.phoneNumber }}
                </span>
              </div>
              <div>
                <span>
                  {{ billing?.eMail }}
                </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <!-- Lieferandressen -->
      <div class="aurow">
        <ng-container *ngFor="let dellivery of deliveryAddress; let i = index;">
          <div class="control_label aucol-sm-5 aucol-xs-6 bestel lefer" id="laddleft">
            {{languageData['Bestellubersicht'].Var_Lieferandressen || 'Lieferandressen'}} {{ i + 1}}:
            <p style="cursor:pointer;margin-left:54px;" (click)="page2()" class="andealign">
              <a>{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</a>
            </p>
          </div>
          <div class="aucol-sm-7 aucol-xs-6 textfix rechadress" style="margin-left:-15px" id="laddright">
            <div>
              <span>
                {{ dellivery?.quantity | replaced : languageVar }}
                {{label}}
              </span>
            </div>
            <div>
              <span>
                {{ dellivery?.firstName + ' ' + dellivery?.lastName }}
              </span>
            </div>
            <div>
              <span>
                {{ dellivery?.streetName + ' ' + dellivery?.houseNo }}
              </span>
            </div>
            <div>
              <span>
                {{ dellivery?.zipCode + ' ' + dellivery?.ort }}
              </span>
            </div>
            <div>
              <span>
                {{ dellivery?.phoneNumber }}
              </span>
            </div>
            <div id="laddress">
              <span>
                {{ dellivery?.eMail }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Qualities & final prices -->
    <div class="panel panel-default aucol-md-6 aucol-sm-11 aucol-xs-12 panel1" style="margin:1%; position: relative;">
      <!-- for loop in price calculation data Qualities -->
      <div *ngFor="let offer of priceCalculationData;trackBy: trackByFn">
        <!-- for loop line -->
        <div *ngIf="offer.qualities?._id === sessionData?.cart[0]?.qualities?._id" style="margin:15px">
          <div class="textfix">
            <!-- If highlighted Quality selected -->
            <div *ngIf="offer.qualities.favorite == true" style="display: flex" class="textfix">
              <div class="text-center" style="min-width:40px;">
                <span class="au-glyphicon au-glyphicon-star">
                  <span [innerHTML]="star" *ngIf="star"></span>
                  <img *ngIf="!star" [src]="'assets/svgs/star.svg' | assetsUrl">
                </span>
              </div>
              <div style="width:100%">
                <small>{{languageData['Bestellubersicht'].Var_Unsere_Empfehlung || 'Unsere
                  Empfehlung!'}}</small>
                <h4 style="margin-top:-2px;">
                  <b>{{offer?.product?.label}}</b>
                  <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="offer.product.description"
                    (click)="specialtextpopup1(offer.product.description)">
                    <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                    <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl">
                  </span>
                  <!-- Voucher available -->
                  <span style="font-size: 12px; margin-left: 16px; float: right;"
                    *ngIf="offer.voucherInfo && offer.voucherInfo?.value">
                    <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                    <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                  </span>
                </h4>
              </div>
            </div>
            <!-- If normal Quality selected -->
            <div *ngIf="offer.qualities.favorite == false">
              <h3>
                <b>{{offer?.product?.label}}</b>
                <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="offer.product.description"
                  (click)="specialtextpopup1(offer.product.description)">
                  <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                  <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl">
                </span>
                <!-- Voucher available -->
                <span style="font-size: 12px; margin-left: 16px; float: right;"
                  *ngIf="offer.voucherInfo && offer.voucherInfo?.value">
                  <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                  <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                </span>
              </h3>
            </div>
            <!-- Co2 Compensation -->
            <div style="padding-bottom:6px;display: flex">
              <div class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12"
                style="padding: 0px !important;cursor: context-menu;display: flex;"
                *ngIf="offer.qualities.co2Compensation?.compensate">
                <div class="aucol-md-3 aucol-xs-3 aucol-sm-3 aucol-lg-3"
                  style="width: auto!important;padding: 0px!important; display: flex;align-items: center;padding-left: 5px !important"
                  [ngStyle]="{'background-color': (check(offer?.qualities?._id)) || offer.qualities.co2Compensation?.type == 'Dauerhaft' ? ('var(--co2Colour)' || 'green') : 'gray'}">
                  <label *ngIf="!(offer.qualities.co2Compensation?.type == 'Dauerhaft')"
                    style="margin-bottom:0px;color: #707070;" class="checkbox-label"
                    [ngClass]="{checked: check(offer?.qualities?._id)}">
                    <input type="checkbox" [disabled]="offer?.qualities.co2Compensation?.type == 'Dauerhaft'"
                      (change)="co2Compensate(offer?.qualities?._id)" [checked]="check(offer?.qualities?._id)">
                    <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                      <span class="checkbox-tick"
                        [ngStyle]="{'border-color': check(offer?.qualities?._id) ? 'var(--primaryColour)' : 'var(--primaryTextColour)'}"></span>
                    </span>
                  </label>
                  <!-- co2 compensation link -->
                  <a [href]="offer.qualities.co2Project?.projectlink" *ngIf="offer.qualities.co2Project?.projectlink"
                    [style.padding-left.px]='(offer.qualities.co2Compensation.type == "Dauerhaft")'
                    [ngStyle]="{color:'var(--primaryTextColour)'}" class="compensationtext" target="_blank">
                    CO<sub>2</sub>
                    {{languageData['Bestelloptionen'].Var_Kompensation || 'Kompensation'}}
                  </a>
                  <p style="margin: 0 0 0px !important;text-align: center;padding: 0px 10px"
                    [ngStyle]="{color:'var(--primaryTextColour)'}" *ngIf="!offer.qualities.co2Project?.projectlink">
                    CO<sub>2</sub><br />
                    {{languageData['Bestelloptionen'].Var_Kompensation || 'Kompensation'}}
                  </p>
                  <!--  3rd stage co2 monkey svg -->
                  <img [src]="'assets/co2monkey.svg' | assetsUrl" class="sbg cl" />
                  <!-- 3rd stage co2 monkey svg end -->
                </div>
              </div>
            </div>
            <!-- Bestellmenge -->
            <div style="padding-bottom:6px;display: flex">
              <div class="mfont12 mfont14" style="width:100%;">
                <span>{{languageData['Bestelloptionen'].Var_Bestellmenge || 'Bestellmenge'}}</span>
              </div>
              <div class="mfont12 mfont14" style="min-width:125px;text-align: right;">
                {{sessionData.quantity | replaced : languageVar}} {{label}}
              </div>
            </div>
            <!-- 100 L netto -->
            <div style="display:flex;padding-bottom:6px;">
              <div class="mfont12 mfont14" style="width:100%">
                {{offer.priceFactor | replaced : languageVar}} {{label}} {{languageData['Bestelloptionen'].Var_netto ||
                'netto'}}
              </div>
              <div class="mfont12 mfont14" style="text-align: right;min-width:125px;">
                {{offer?.currencySymbol}}
                {{
                (
                (offer?.identifyPackagesInPc && offer.voucherInfo&&offer.voucherInfo.value) ?
                (offer?.basicPricePriceFactorNett | langReplacer : languageVar) :
                (offer?.basicSurgargePriceFactorNett | langReplacer : languageVar)
                )
                }}
              </div>
            </div>
            <!-- 100 L netto including voucher price-->
            <div style="display:flex;padding-bottom:6px;">
              <div class="mfont12 mfont14" style="width:100%" *ngIf="offer.voucherInfo && offer.voucherInfo?.value">
                <span>{{offer.priceFactor | replaced : languageVar}}</span> {{label}} netto
                {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}
              </div>
              <div class="mfont12 mfont14" style="text-align: right;min-width:125px;">
                <b *ngIf="offer.voucherInfo && offer.voucherInfo?.value">
                  <span>{{offer.currencySymbol}}</span>
                  <span *ngIf="!(offer.pc100lWithoutFlatRate || offer.identifyPackagesInPc )">
                    {{(offer.basicSurgargePriceFactorNett) | langReplacer : languageVar}}
                  </span>
                  <span *ngIf="(offer.pc100lWithoutFlatRate || offer.identifyPackagesInPc) ">
                    {{(offer.basicSurgargePriceFactorNett) | langReplacer : languageVar}}
                  </span>
                </b>
              </div>
            </div>
            <br>
            <!-- Table 2 in price detail popup starts -->
            <!-- Basispreis (1.000l) netto -->
            <div style="display:flex;padding-bottom:6px;">
              <div class="mfont12 mfont14" style="width:100%">
                {{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}}
                ({{sessionData.quantity | replaced : languageVar}}<span>{{unitsSymbol}}</span>)
                {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
              </div>
              <div class="mfont12 mfont14" style="text-align: right;min-width:125px;">
                <span>{{offer.currencySymbol}}</span>
                {{offer.basicPriceNett | langReplacer : languageVar }}
              </div>
            </div>
            <!-- paushale settings -->
            <div *ngIf="offer?.surchargeNett" style="display:flex;padding-bottom:6px;">
              <div class="mfont12 mfont14" style="width:100%">
                + {{sessionData?.stations}} x
                {{offer.surcharge?.name }} netto
              </div>
              <div class="mfont12 mfont14" style="text-align: right;min-width:125px;">
                <span>{{offer.currencySymbol}}</span> {{offer.surchargeNett | langReplacer : languageVar }}
              </div>
            </div>
            <!-- ratePayment settings -->
            <!-- <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;">
                <div class="mfont12" style="width:100%">
                  + {{ languageData?.Bestelloptionen['Var_servicefeefinancing'] || 'Servicegebühr Finanzierung '}}
                </div>
                <div class="mfont12" style="text-align: right;min-width:125px;">
                  {{offer.currencySymbol}} {{offer.ratePaymentDetails.servicegebuehr | replace}}
                </div>
              </div> -->
            <!-- Voucher settings -->
            <!-- Default voucher -->

            <div class="gutshchein-3rd">
              <div class="gutschein-wrapper">
                <div class="gutschein-input" style="cursor:pointer">
                  <div class="mfont12 mfont14 Guts gutline" style="min-width: 94px;">
                    - {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}:
                  </div>
                  <div class="input-container gutinput"
                    style="position:unset;margin-bottom:0px !important;align-items:center">
                    <input class="auform-control guts gutts"
                      style="width:44%;position:unset;margin-right:-26px;flex-grow:1" name="gutschein" minlength="4"
                      pattern="[A-Za-z0-9äöüÄÖÜß ]*" (ngModelChange)="updateoptions($event,'VOUCHER')"
                      [disabled]="showFullScreenLoading" (blur)="voucherfun($event)" required
                      [value]="offer.voucherInfo?.code" />

                    <div class="gut" style="position: sticky; right: 13%;padding:6px 0px;cursor: pointer">
                      <span [innerHTML]="reload" *ngIf="reload"
                        style="position:relative;margin-top: 3px;margin-right: 3px;top:3px" class="reload1"></span>
                      <img *ngIf="!reload" [src]="'assets/svgs/reload.svg' | assetsUrl"
                        (click)="voucherValidity(offer.voucherInfo?.code)">
                    </div>

                    <!-- gutschein brutto -->
                    <div class="mfont12 mfont14 gutbrutto" *ngIf="offer.voucherInfo?.value">
                      <span style="color:black; position: relative; bottom: 2px; ">
                        (
                        {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}:
                        <span style="white-space:nowrap">
                          <span>{{offer.currencySymbol}}</span>
                          <span> {{offer.voucherInfo?.value | langReplacer : languageVar}}
                            )
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mfont12 mfont14" *ngIf="offer.voucherInfo"
                  style="justify-content: flex-end; display: flex; align-items: center;min-width:60px;position: relative;top: 1px;">
                  <span style="padding-right: 5px;">{{offer.currencySymbol}}</span>
                  {{voucherNett | langReplacer : languageVar}}
                </div>
                <div class="mfont12 mfont14" *ngIf="!offer.voucherInfo"
                  style="justify-content: flex-end; display: flex; align-items: center;min-width:60px;position: relative;top: 1px;">
                  <span style="padding-right: 5px;">{{offer.currencySymbol}}</span>
                  0
                </div>
              </div>

              <div class="mfont12 mfont14" class="alert-danger" style="margin-top:3px;"
                *ngIf="voucherCode && !voucher.valid && touchedVoucherInput">
                {{languageData['Bestellubersicht'].Var_Ungultiger_Gutschein || 'Ungültiger Gutschein'}}
              </div>
            </div>
            <!-- Tax details -->
            <div [ngStyle]="{'border-bottom':'2px solid '+'var(--primaryColour)'}"
              style="display:flex;padding-bottom:6px;">
              <div class="mfont12 mfont14" style="width:100%">
                + {{offer.productTax.taxRate | langReplacer : languageVar}}% {{languageData['Bestelloptionen'].Var_MwSt
                ||'MwSt'}}.
              </div>
              <div *ngIf="!sessionData.ratePaymentSettings.status" class="mfont12 mfont14"
                style="text-align: right;min-width:125px;">
                <span>
                  <span *ngIf="offer?.productTax?.status"> ** </span>
                  {{offer.currencySymbol}}
                </span>
                {{offer.tax | langReplacer : languageVar}}
              </div>
              <div *ngIf="sessionData.ratePaymentSettings.status" class="mfont12 mfont14"
                style="text-align: right;min-width:125px;">
                <span>
                  <span *ngIf="offer?.productTax?.status"> ** </span>
                  {{offer.currencySymbol}}
                </span>
                {{offer.tax | langReplacer : languageVar}}
              </div>
            </div>
            <!-- final price details  -->
            <div [ngStyle]="{color:'var(--primaryColour)'}" style="display:flex;font-size:20px;padding-top:6px">
              <div class="mfont12 mfont14" [ngClass]="sessionData.ratePaymentSettings.status? 'ratepayment-set' :'gesamtpriesPadding'">
                <b *ngIf="!sessionData.ratePaymentSettings.status">{{languageData['Bestelloptionen'].Var_Gesamtpreis
                  ||
                  'Gesamtpreis'}}</b>
                <span *ngIf="sessionData.ratePaymentSettings.status">{{languageData['Bestelloptionen'].Var_Warenwert
                  ||
                  'Warenwert'}}
                  ({{sessionData.quantity | langReplacer : languageVar}}<span>{{unitsSymbol}}</span>)
                  {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</span>
              </div>
              <div class="mfont12 mfont14" style="text-align: right;min-width:125px;">
                <b>
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer.currencySymbol}}
                  </span>
                </b>
                <span *ngIf="!sessionData.ratePaymentSettings.status"><b>
                    <span>{{offer.gross | langReplacer : languageVar }}</span></b></span>
                <span *ngIf="sessionData.ratePaymentSettings.status"><b><span>
                      {{offer.gross | langReplacer : languageVar }}</span></b></span>
              </div>
            </div>
            <!-- error msg for price change -->
            <div *ngIf="third_stage_pricechange"
              style="position:relative; bottom:12px; display: block;text-align:end;color:red ">
              <span class="mfont12">
                {{languageData['Bestatigung'].Var_check_box32 || 'Der Preis hat sich geändert und ihr Angebot wurde
                angepasst!'}}
              </span>
            </div>
            <!-- error msg for price change -->
            <!-- time dependent surharges -->
            <div class="frame" style="background: #dfdfdf; margin: 15px 0px 15px 0px !important;"
              *ngIf="offer.timeDepandentSurcharges &&(offer.timeDepandentSurcharges.length>0)">
              <div class="mfont12" style="padding-bottom:6px;display: flex">
                <div class="mfont12" style="width:100%;">
                  <span>{{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:</span>
                </div>
                <div class="mfont12" style="min-width:125px;text-align: right;">
                </div>
              </div>
              <div class="mfont12" style="padding-bottom:6px;display: flex"
                *ngFor="let item of timeDepandentSurcharges">
                <div class="mfont12" style="width:100%;">
                  + <span>{{item.name}}</span>
                  <span class="au-glyphicon au-glyphicon-info-sign">
                    <span [innerHTML]="infoSign" *ngIf="infoSign" (click)="alternativePopup(item.specialText)"></span>
                    <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl"
                      (click)="alternativePopup(item.specialText)">
                  </span>
                </div>
                <div class="mfont12" style="min-width:125px;text-align: right;">
                  <span>{{offer.currencySymbol}}</span> {{item.timeDepandantSurchargeNett | langReplacer : languageVar}}
                </div>
              </div>
              <div class="mfont12" style="display:flex;border-bottom: 1px solid black">
                <div class="mfont12" style="width:100%;">
                  + {{offer.productTax.taxRate | langReplacer : languageVar}}%
                  {{languageData['Bestelloptionen'].Var_MwSt
                  || 'MwSt'}}.
                </div>
                <div class="mfont12" style="min-width:125px;text-align: right;">
                  <span>
                    <span *ngIf="offer?.productTax?.status" style=" margin-top: -35px; margin-bottom: 4px"> ** </span>
                    {{offer.currencySymbol}}
                  </span>
                  <span>
                    {{offer.taxTimeDepandandentSurchargeNett | langReplacer : languageVar}}
                  </span>
                </div>
              </div>
              <div class="mfont12" style="display:flex;padding-top: 10px;">
                <div class="mfont12" style="width:100%;">
                  <span>
                    <span *ngIf="!sessionData.ratePaymentSettings.status">=
                      {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}}
                      ({{sessionData.quantity | langReplacer : languageVar}}<span>{{unitsSymbol}}</span>)</span>
                    <span *ngIf="sessionData.ratePaymentSettings.status">=
                      {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}}
                      ({{sessionData.quantity | langReplacer : languageVar}}<span>{{unitsSymbol}}</span>)</span>
                    {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
                  </span>
                </div>
                <div class="mfont12" style="min-width:125px;text-align: right;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer?.currencySymbol}}
                  </span>
                  <span>
                    {{offer?.grossWithTimeDepandantCharges| langReplacer : languageVar}}
                  </span>
                </div>
              </div>
            </div>

            <!-- Ratepayment settings charges -->
            <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;margin-top:25px">
              <div class="mfont12" style="width:100%">
                {{ languageData?.Bestelloptionen['Var_financingterm'] || 'Finanzierung Laufzeit: '}}:
                {{offer.paymentDetails?.numberOfInstallments || 6}}
                {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}
              </div>
            </div>

            <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;">
              <div class="mfont12" style="width:100%">

                {{offer.paymentDetails?.numberOfInstallments - 1}} X
                {{ languageData?.Bestelloptionen['Var_monthly'] || 'Monatsrate brutto'}}
              </div>
              <div class="mfont12" style="text-align: right;min-width:125px;">
                <span>
                  <span *ngIf="offer?.productTax?.status"> ** </span>
                  {{offer.currencySymbol}}
                </span>
                {{offer.paymentDetails?.installment | langReplacer : languageVar}}
              </div>
            </div>

            <div *ngIf="offer.ratePayment" style="display:flex; padding-bottom:6px;"
              [ngStyle]="{'border-bottom':'2px solid '+'var(--primaryColour)'}">
              <div class="mfont12" style="width:100%">
                1 X {{ languageData?.Bestelloptionen['Var_finalinstallment'] || 'Schlussrate brutto'}}

              </div>
              <div class="mfont12" style="text-align: right;min-width:125px;">
                <span>
                  <span *ngIf="offer?.productTax?.status"> ** </span>
                  {{offer.currencySymbol}}
                </span>
                {{offer.paymentDetails?.lastInstallment| langReplacer : languageVar}}
              </div>
            </div>

            <div *ngIf="offer.ratePayment" [ngStyle]="{color:'var(--primaryColour)'}"
              style="display:flex;padding-top:6px;font-size:20px">
              <div class="mfont12" style="width:100%">
                <b>
                  {{ languageData?.Bestelloptionen['Var_totalfinance'] || 'Gesamtsumme Finanzierung'}}
                </b>
              </div>
              <div class="mfont12" style="text-align: right;min-width:125px;">
                <span>
                  <span *ngIf="offer?.productTax?.status"> ** </span>
                  {{offer.currencySymbol}} </span><b>
                  {{offer.paymentDetails?.totalValue | langReplacer : languageVar}}
                </b>
              </div>
            </div>

            <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:17px;">
              <div class="mfont12" style="width:100%">
                <span>{{languageData['Bestelloptionen'].Var_interestRate}}</span>
              </div>
              <div class="mfont12" style="text-align: right;min-width:125px;">
                {{offer.currencySymbol}}
                {{offer.paymentDetails?.totalInterest | langReplacer : languageVar}}
              </div>
            </div>

            <div *ngIf="offer.ratePayment" style="display:flex;padding-top:6px;">
              <div class="mfont12" style="width:100%;color: #888888">
                {{ausgewaehlterRatenplan?.tilgungsplanText}}
              </div>
            </div>
            <!-- <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:15px;padding-top:6px;">
              <a [ngStyle]="{color:'var(--primaryColour)'}" target="_blank"
                [href]="ausgewaehlterRatenplan?.urlVorvertraglicheInformationen">{{languageData['Bestelloptionen'].Var_pre_contractual_information}}</a>
            </div> -->

            <!-- Voucher related settings -->
            <small *ngIf="offer?.voucherInfo && offer?.voucherInfo?.value" class="addedVoucher"
              [ngStyle]="third_stage_pricechange ? {'position': 'relative', 'bottom': '18px'} : {}"
              [ngClass]="{'addedVoucherr' : third_stage_pricechange}">
              <div class="gutalign">
                <br>{{languageData['Bestelloptionen'].Var_grossValueVoucher || 'Gutschein i.H.v.'}}
                <b class="auprisfont14 inline-block">
                  {{offer.voucherInfo?.value}}
                </b>
                <span>
                  {{offer.currencySymbol}}
                </span>,
                {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                {{languageData['Bestelloptionen'].Var_enthalten }}
              </div>
            </small>

            <!-- Company terms and conditions -->
            <div style="display:block;" class="checkbox-inline1">
              <div *ngFor="let pdfData of thridStagePdfs" style="margin-bottom:10px !important;">
                <span *ngIf="pdfData.show_in_pricecalculator"
                  [ngStyle]="{'margin-left':!pdfData.checkbox_in_the_pricecalculator ? '' : '','color':(!pdfData.checked &&  pdfData.touched && pdfData?.checkbox_in_the_pricecalculator &&  pdfData.mandatory_checkbox) ? 'red' : 'black' }">
                  <input class="form-check-input check" (change)="pdfOnSelect($event.target.checked, pdfData)"
                    [checked]="pdfData.checked" *ngIf="pdfData.checkbox_in_the_pricecalculator" type="checkbox"
                    style="cursor: pointer;" />
                  <span class="alignPdf">
                    {{pdfData.textarea1}}
                    <b>
                      <a *ngIf="!pdfData.isLink" [ngClass]="pdfData.filename ? 'colors' :'noColor'"
                        (click)="downloadFile(pdfData.refCentralizedFileId)">{{pdfData.name}}</a>
                      <a *ngIf="pdfData.isLink" [ngClass]="pdfData.link ? 'colors' :'noColor'"
                        [href]="pdfData.link | securitycheck" target="_blank">
                        {{pdfData.name}}</a>
                    </b>
                    {{pdfData.textarea2}}
                    <span *ngIf="pdfData?.checkbox_in_the_pricecalculator && pdfData?.mandatory_checkbox"
                      style="position: absolute;">*</span>
                  </span>
                </span>
              </div>

              <div class="alert-danger" style="margin-left: 2px;margin-top:10px" *ngIf="showPdfError">
                {{languageData['Bestatigung'].Var_pdfError || 'Um Ihre Bestellung abzuschließen, müssen alle
                verpflichtenden Bestimmungen akzeptiert sein!'}}
              </div>


              <div *ngIf="offer.productTax.status">
                <div class="form-check">
                  <input class="form-check-input check" style="margin-top: 0px !important" type="checkbox"
                    [(ngModel)]="agbAccepted.third" style="cursor: pointer">
                  <span style="left: 24px;position: relative; bottom: 1px">
                    {{languageData['Bestatigung'].Var_checkbox_4 || 'Ich habe den'}}

                    <b>
                      <a [ngStyle]="{color: 'var(--primaryColour)'}"
                        (click)="steuerhinweisPopup(offer?.productTax?.infoText)">
                        {{languageData['Bestatigung'].Var_specialnote_on_Vat || '**Sonderhinweis zur Mehrwertsteuer'}}
                      </a>
                    </b>
                    {{languageData['Bestatigung'].Var_read_and_understood||'gelesen und verstanden.'}}
                  </span>
                </div>
              </div>

              <div style="display:block" *ngIf="third_stage_pricechange" [ngStyle]="{'margin-bottom' :(third_stage_pricechange) ? '10px': ''}">
                <div class="form-check" style="position: relative;">
                  <input class="form-check-input check" type="checkbox" (change)='priceChangeAccept($event)'>
                  <span class="alignPdf">
                    {{
                    languageData['Bestatigung'].Var_check_box3 || 'Die Preisveränderung wurde gesehen und akzeptiert!'
                    }}
                  </span>
                </div>
                <!-- <p class="alignPdf" style="color:red;">
                  {{languageData['Bestatigung'].Var_check_box32 || 'Der Preis hat sich geändert und ihr Angebot wurde angepasst!'}}
                </p> -->
              </div>


              <!-- ratepayment && zaulung plan -->
              <!-- <div *ngIf="offer.ratePayment && (ausgewaehlterRatenplan && ausgewaehlterRatenplan.mTanRequired) "
                style="margin-top: 15px;">
                <label class="mtanLabel">mTAN für Ratenkauf*</label>
                <input type="text" class="mtanInput" [formControl]="mtanInput">
                <span *ngIf="showResendmTan" class="mtanSendAgain"
                  (click)="resendMtan(ausgewaehlterRatenplan.tbVorgangskennung)"
                  [ngStyle]="{color: 'var(--primaryColour)'}">mTAN emeut senden</span>
              </div>
              <div class="mfont12" class="alert-danger" style="margin-top:5px;"
                *ngIf="offer.ratePayment && showMtanError">
                {{languageData['Bestatigung'].Var_wrongmTan || 'Ungültige mTAN!'}}
              </div> -->
              <!-- <div style="display:block"
                  *ngIf="offer.productTax.status">
                  <div class="form-check">
                    <input class="form-check-input check" type="checkbox"
                      style="cursor: pointer">
                    {{languageData['Bestatigung'].Var_checkbox_4 || 'Ich habe den'}}
                    <b>
                      <a [ngStyle]="{color: 'var(--primaryColour)'}" (click)="steuerhinweisPopup(offer.productTax.infoText)">
                        {{languageData['Bestatigung'].Var_specialnote_on_Vat || '**Sonderhinweis zur Mehrwertsteuer'}}</a>
                    </b>
                    {{languageData['Bestatigung'].Var_read_and_understood||'gelesen und verstanden.'}}
                  </div>
                </div>  -->
              <!-- ratepayment && zaulung plan -->
              <div *ngIf="offer.ratePayment && (ausgewaehlterRatenplan && ausgewaehlterRatenplan.mTanRequired) "
                style="margin-top: 15px;">
                <label class="mtanLabel">mTAN für Ratenkauf*</label>
                <input type="text" class="mtanInput" [formControl]="mtanInput">
                <span *ngIf="showResendmTan" class="mtanSendAgain"
                  (click)="resendMtan(ausgewaehlterRatenplan.tbVorgangskennung)"
                  [ngStyle]="{color: 'var(--primaryColour)'}">mTAN emeut senden</span>
              </div>
              <div class="mfont12" class="alert-danger" style="margin-top:5px;"
                *ngIf="offer.ratePayment && showMtanError">
                {{languageData['Bestatigung'].Var_wrongmTan || 'Ungültige mTAN!'}}
              </div>
              <div class="aurows">
                <div class="zahlungbutton">
                  <button   *ngIf="!easyCreditButton " class="au-btn  aucol-md-12" style="width:100%;" cyPlaceOrder="cyPlaceOrder" 
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="ZahlungMail()">
                    {{placeOrderBtn}}
                   </button>
                   
                   <button   *ngIf="(easyCreditButton &&orderStatus =='success') && !priceChangewithPaymentMode" class="au-btn  aucol-md-12" style="width:100%;" cyPlaceOrder="cyPlaceOrder" 
                   [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                   (click)="ZahlungMail()">
                   {{placeOrderBtn}}
                  </button>
                  
                   <!-- <easycredit-express-button  full-width="false"  *ngIf="easyCreditButton"></easycredit-express-button> -->
                   <div  *ngIf="easyCreditButton && (priceChangewithPaymentMode || orderStatus == '' ||   orderStatus=='cancel' )">
                    <div class="ec-express-button" style="opacity: 1;" (click)="paymentContinue()">
                      <div class="ec-express-button__btn full-width" style="width: 321px;">
                        <a class="ec-express-button__btn__main">
                          <div class="logo"></div>
                          <div class="ec-express-button__btn__main__inner" style="flex-basis: 190px; width: 190px;">
                            <span class="financing">Jetzt direkt in Raten zahlen</span>
                          </div>
                        </a>
                      </div>
                      <!-- <a class="ec-express-button__link" target="_blank" style="width: 321px;">
                        <div class="icon"></div>
                        <div>Mehr zu <strong style="color:rgb(0, 44, 90) !important;font-size:10px">easyCredit-Ratenkauf</strong></div>
                      </a> -->
                    </div>
                   </div>
                </div>
              </div>
              <div style="text-align: right;">
                * {{languageData['Bestatigung'].Var_Mandatory || 'Verpflichtende Angabe'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- theme 1 ends -->

  <!-- theme 2 start -->
  <div class="aurow stage t2Main" *ngIf="productInfoForWebPC.themeModel == 2">
    <!-- Besrelloptionen & Addresses -->
    <div class="aucol-lg-5 aucol-md-5 aucol-sm-5 aucol-xs-12" style="margin-top:-3px;">
      <!-- ratepayment address -->
      <div class="roww" style="margin-right:-10px;" *ngIf="ratePayment == true">
        <div class="card" style="background-color: #fff;margin-bottom: 0px; ">
          <div class="mfont12"
            style="margin-left:-4px;font-size: 15px;font-weight: 600;padding: 5px;margin-right: -5px;">
            <div>
              <svg style="margin-left: 7px; margin-top: 7px;" [ngStyle]="{fill: 'var(--primaryColour)'}" height="20px"
                viewBox="0 -65 434.67733 434" width="30px" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m152.003906 304.34375c-5.460937 0-10.921875-2.089844-15.082031-6.25l-130.664063-130.667969c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339844-8.339844 21.820313-8.339844 30.164063 0l115.582031 115.582031 246.253906-246.25c8.339844-8.339844 21.820313-8.339844 30.164063 0 8.339844 8.34375 8.339844 21.824219 0 30.167969l-261.332031 261.332031c-4.160156 4.160156-9.625 6.25-15.085938 6.25zm0 0" />
              </svg>
              <span class="mfont12" style="position: absolute;margin-top: 9px;margin-left: 10px;"> <b
                  [ngStyle]="{color: 'var(--primaryColour)'}">{{languageData['Bestelloptionen'].Var_Installment_purchase_confirmed
                  || 'Ratenkauf wurde bestätigt!'}}</b></span>
            </div>
            <div class="card-header" style="margin:7px -1px 0px -1px">
              <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                {{languageData['Bestelloptionen'].Var_installment_payments || 'Ratenzahlung'}}:
                <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                  (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
              </div>
            </div>
          </div>
          <div class="card-body mfont12" style="padding-left: 25px;padding-top: 7px;line-height: 26px;">
            <div>{{bankdetails?.laufzeit}} {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}</div>
            <div>{{bankdetails?.kontoinhaber}} </div>
            <div>{{bankdetails?.iban}} </div>
            <div>{{bankdetails?.bic}} </div>
            <div>{{bankdetails?.bank_name}}</div>
            <div>{{bankdetails?.mobilfunknummer}} </div>
          </div>
        </div>
      </div>

      <!-- left address -->
      <div class="roww" style="margin-right:-10px;">
        <div class="card" style="background-color: #fff;margin-bottom: 0px;">
          <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
            <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
              {{languageData['Bestellubersicht'].Var_Bestelloption || 'Bestelloption'}}
              <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                (click)="page1()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
            </div>
          </div>
          <div class="card-body" style="padding-left: 40px;padding-top: 7px;line-height: 26px;">
            <div class="aurow">
              <span class="control-label control1 mfont14">{{languageData['Bestelloptionen'].Var_Abladestellen
                ||
                'Abladestellen'}}</span>
              <span class="mfont mfont12"> {{sessionData?.stations}}
                {{languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte'}}</span>
            </div>
            <div *ngFor="let options of localOptions">
              <span>
                <div class="aurow">
                  <span>{{options.lable }}: </span>
                  <span>{{options.name }}</span>
                </div>


                <!-- Desired date settings From date Start here -->
                <div class="aurow"
                  *ngIf="sessionData?.desiredDateSettings.wunstermin && options.name == 'Wunschtermin'">
                  <!-- desired date settings -->
                  <span>
                    {{languageData['Bestelloptionen'].Var_firstDeliveryDate_text || 'Frühester Liefertermin'}}:
                  </span>
                  <span>{{sessionData?.desiredDateSettings.fromDate}} </span>
                </div>
                <!-- Desired date settings From date End here -->

                <!-- Desired date settings To date Start here -->
                <div class="aurow"
                  *ngIf="sessionData?.desiredDateSettings.wunstermin && options.name == 'Wunschtermin'">
                  <span>
                    {{languageData['Bestelloptionen'].Var_lastDeliveryDate_text ||'Spätester Liefertermin'}}:
                  </span>
                  <span> {{sessionData?.desiredDateSettings.toDate}}
                  </span>
                </div>
                <!-- Desired date settings To date End here -->
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--  Rechnungsadresse Address heading-->
      <div class="roww" style="margin-right:-10px;">
        <div class="card" style="background-color: #fff;margin-bottom: 0px;">

          <!-- Rechnungsadresse equals 1 -->
          <ng-container *ngIf="stationCount == 1">
            <ng-container *ngIf="billingAddressEnable">
              <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
                <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                  {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}
                  <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                    (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
                </div>
              </div>
              <div class="card-body" style="padding-left: 25px;padding-top: 7px;line-height: 26px;"
                *ngFor="let billing of billingAddress; let i = index;">
                <div class="mfont14">
                  <span>
                    {{
                    billing.firstName
                    +
                    ' '
                    +
                    billing.lastName
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.streetName
                    +
                    ' '
                    +
                    billing.houseNo
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.zipCode
                    +
                    ' '
                    +
                    billing.ort
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.phoneNumber
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.eMail
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!billingAddressEnable">
              <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
                <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                  {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}
                  <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                    (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
                </div>
              </div>
              <div class="card-body" style="padding-left: 25px;padding-top: 7px;line-height: 26px;"
                *ngFor="let billing of deliveryAddress; let i = index;">
                <div class="mfont14">
                  <span>
                    {{
                    billing.firstName
                    +
                    ' '
                    +
                    billing.lastName
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.streetName
                    +
                    ' '
                    +
                    billing.houseNo
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.zipCode
                    +
                    ' '
                    +
                    billing.ort
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.phoneNumber
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.eMail
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- Rechnungsadresse morethan 1 -->
          <ng-container *ngIf="stationCount > 1">
            <ng-container *ngIf="selectedBiilingOption == 'Einzelrechnungent'">
              <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
                <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                  {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}
                  <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                    (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
                </div>
              </div>
              <div class="card-body" style="padding-left: 25px;padding-top: 7px;line-height: 26px;">
                <div class="mfont12">
                  {{languageData['Bestellubersicht'].Var_DeleveryAddAsBillingAddress || 'Lieferadressen gleich
                  Rechnungsadressen'}}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedBiilingOption == 'Sammelrechnung'">
              <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
                <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                  {{languageData['Bestellubersicht'].Var_Rechnungsadresse || 'Rechnungsadresse'}}
                  <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                    (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
                </div>
              </div>
              <div class="card-body" style="padding-left: 25px;padding-top: 7px;line-height: 26px;"
                *ngFor="let billing of billingAddress">
                <div class="mfont14">
                  <span>
                    {{
                    billing.firstName
                    +
                    ' '
                    +
                    billing.lastName
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.streetName
                    +
                    ' '
                    +
                    billing.houseNo
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.zipCode
                    +
                    ' '
                    +
                    billing.ort
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.phoneNumber
                    }}
                  </span>
                </div>
                <div class="mfont14">
                  <span>
                    {{
                    billing.eMail
                    }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>


          <!-- Lieferandressen -->
          <ng-container *ngFor="let dellivery of deliveryAddress; let i = index;">
            <div class="card-header" style="background-color: #eee;margin:7px -1px 0px -1px">
              <div class="mfont12" style="margin-left: 10px;font-size: 15px;font-weight: 600;padding: 5px;">
                {{languageData['Bestellubersicht'].Var_Lieferandressen || 'Lieferandressen'}} {{ i + 1}}:
                <span class="mfont12" style="float: right;cursor:pointer;margin-right: 17px;"
                  (click)="page2()">{{languageData['Bestellubersicht'].Var_andern || 'ändern'}}</span>
              </div>
            </div>

            <div class="card-body" style="padding-left: 25px;padding-top: 7px;line-height: 26px;">
              <div class="mfont14">
                <span>
                  {{
                  dellivery.quantity
                  }}
                  {{label}}
                </span>
              </div>
              <div class="mfont14">
                <span>
                  {{
                  dellivery.firstName
                  +
                  ' '
                  +
                  dellivery.lastName
                  }}
                </span>
              </div>
              <div class="mfont14">
                <span>
                  {{
                  dellivery.streetName
                  +
                  ' '
                  +
                  dellivery.houseNo
                  }}
                </span>
              </div>
              <div class="mfont14">
                <span>
                  {{
                  dellivery.zipCode
                  +
                  ' '
                  +
                  dellivery.ort
                  }}
                </span>
              </div>
              <div class="mfont14">
                <span>
                  {{
                  dellivery.phoneNumber
                  }}
                </span>
              </div>
              <div class="mfont14">
                <span>
                  {{
                  dellivery.eMail
                  }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Qualities & final prices -->
    <div class="aucol-lg-7 aucol-md-7 aucol-sm-7 aucol-xs-12 thirdstage pricem">
      <div class="panel panel-default aucol-md-12 aucol-sm-12 aucol-xs-12" style="margin-top: 1%; position: relative;">
        <loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
        <div *ngFor="let offer of priceCalculationData">

          <div *ngIf="offer.qualities?._id === sessionData?.cart[0]?.qualities?._id" style="margin:15px">
            <div>
              <!-- If favorite quality selected -->
              <div *ngIf="offer.qualities.favorite == true" style="display: flex" class="textfix">
                <div class="text-center" style="min-width:40px;">
                  <span class="au-glyphicon au-glyphicon-star">
                    <span [innerHTML]="star" *ngIf="star"></span>
                    <img *ngIf="!star" [src]="'assets/svgs/star.svg' | assetsUrl">
                  </span>
                </div>
                <div style="width:100%">
                  <small>{{languageData['Bestellubersicht'].Var_Unsere_Empfehlung || 'Unsere
                    Empfehlung!'}}</small>
                  <h4 style="margin-top:-2px;">
                    <b>{{offer.product.label}}</b>
                    <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="offer.product.description"
                      (click)="specialtextpopup1(offer.product.description)">
                      <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                      <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl">
                    </span>
                    <!-- Voucher available -->
                    <span *ngIf="offer.voucherInfo && offer.voucherInfo?.value" class="card"
                      style="font-size: 12px;display:inline-block;position:relative;top:5px">
                      <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                      <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                    </span>
                  </h4>
                </div>
              </div>
              <!-- If favorite quality not selected -->
              <div *ngIf="offer.qualities.favorite == false">
                <h3>
                  <b class="texttt">{{offer.product.label}}</b>
                  <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="offer.product.description"
                    (click)="specialtextpopup1(offer.product.description)">
                    <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                    <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl">
                  </span>
                  <!-- Voucher available -->
                  <span *ngIf="offer.voucherInfo && offer.voucherInfo?.value" class="card"
                    style="font-size: 12px;display:inline-block;position:relative;top:5px">
                    <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                    <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                  </span>
                </h3>
              </div>
              <!-- Co2 Compensation -->
              <div style="padding-bottom:6px;display: flex">
                <div class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12"
                  style="padding: 0px !important;cursor: context-menu;display: flex;"
                  *ngIf="offer.qualities.co2Compensation?.compensate">
                  <div class="card"
                    style="width: auto!important;padding: 0px!important; display: flex;align-items: center;padding-left: 5px !important"
                    [ngStyle]="{'background-color': (check(offer?.qualities?._id)) || offer.qualities.co2Compensation?.type == 'Dauerhaft' ? ('var(--co2Colour)' || 'green') : 'gray'}">
                    <label *ngIf="!(offer.qualities.co2Compensation?.type == 'Dauerhaft')"
                      style="margin-bottom:0px;color: #707070;" class="checkbox-label"
                      [ngClass]="{checked: check(offer?.qualities?._id)}">
                      <input type="checkbox" [disabled]="offer?.qualities.co2Compensation?.type == 'Dauerhaft'"
                        [checked]="check(offer?.qualities?._id)" (change)="co2Compensate(offer?.qualities?._id)">
                      <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                        <span class="checkbox-tick"
                          [ngStyle]="{'border-color': check(offer?.qualities?._id)? 'var(--primaryColour)' : 'var(--primaryTextColour)'}"></span>
                      </span>
                    </label>
                    <!-- co2 compensation link -->
                    <a [href]="offer.qualities.co2Project?.projectlink" *ngIf="offer.qualities.co2Project?.projectlink"
                      [style.padding-left.px]='(offer.qualities.co2Compensation.type == "Dauerhaft")'
                      [ngStyle]="{color:'var(--primaryTextColour)'}" class="compensationtext"
                      target="_blank">CO<sub>2</sub>
                      {{languageData['Bestelloptionen'].Var_Kompensation || 'Kompensation'}}</a>
                    <p style="margin: 0 0 0px !important;text-align: center;padding: 0px 10px"
                      [ngStyle]="{color:'var(--primaryTextColour)'}" *ngIf="!offer.qualities.co2Project?.projectlink">
                      CO<sub>2</sub><br /> {{languageData['Bestelloptionen'].Var_Kompensation ||
                      'Kompensation'}}
                    </p>
                    <!--  3rd stage co2 monkey svg -->

                    <img [src]="'assets/co2monkey.svg' | assetsUrl" class="sbg cl" />
                    <!-- 3rd stage co2 monkey svg end -->
                  </div>
                  <!-- gutschein if favorite on -->
                  <!-- <div *ngIf="offer.qualities.favorite ==true && sessionData?.voucher && sessionData.voucher?.valid"
                        class="card" style="height: 50px;">
                        <div>
                          <span style="font-size: 12px;">
                            <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                            <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                          </span>
                        </div>
                      </div> -->
                  <!-- gutschein if favorite off -->
                  <!-- <div *ngIf="offer.qualities.favorite ==false && sessionData?.voucher && sessionData.voucher?.valid"
                        class="card" style="height: 50px;font-size: 12px;">
                        <div>
                          <span style="font-size: 12px;">
                            <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                            <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                          </span>
                        </div>
                      </div> -->
                </div>
              </div>
              <!-- Bestellmenge -->
              <div class="mfont12" style="padding-bottom:6px;display: flex">
                <div class="mfont14" style="width:100%;">
                  <span>{{languageData['Bestelloptionen'].Var_Bestellmenge || 'Bestellmenge'}}</span>
                </div>
                <div class="mfont14" style="min-width:125px;text-align: right;">
                  {{sessionData.quantity | replaced : languageVar}} {{label}}
                </div>
              </div>
              <!-- 100 liter netto -->
              <div class="mfont12" style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%">
                  {{offer.priceFactor | replaced : languageVar}} {{label}} {{languageData['Bestelloptionen'].Var_netto
                  || 'netto'}}
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  {{offer.currencySymbol}} {{
                  (
                  (offer.pc100lWithoutFlatRate && offer.identifyPackagesInPc) ?
                  (offer.basicPricePriceFactorNett | langReplacer : languageVar) :
                  (offer.basicSurgargePriceFactorNett | langReplacer : languageVar)
                  )
                  }}
                </div>
              </div>
              <!-- Voucher -->
              <!-- 100 L netto including voucher price-->
              <div style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%" *ngIf="offer.voucherInfo && offer.voucherInfo?.value">
                  <span>{{offer.priceFactor | replaced : languageVar}}</span> {{label}} netto
                  {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                  {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  <b *ngIf="offer.voucherInfo && offer.voucherInfo?.value"><span>{{offer.currencySymbol}}</span>
                    <span *ngIf="!(offer.pc100lWithoutFlatRate || offer.identifyPackagesInPc )">
                      {{(offer.basicSurgargePriceFactorNett) | langReplacer : languageVar}}
                    </span>
                    <span *ngIf="(offer.pc100lWithoutFlatRate || offer.identifyPackagesInPc) ">
                      {{(offer.basicSurgargePriceFactorNett) | langReplacer : languageVar}}
                    </span>
                  </b>
                </div>
              </div>
              <br>
              <!-- Table 2 in price detail popup starts -->
              <!-- Basispreis (1.000l) netto -->
              <div style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%">
                  {{ languageData?.Bestelloptionen['Var_Basispreis'] || 'Basispreis '}}
                  ({{sessionData.quantity | replaced : languageVar}}<span>{{unitsSymbol}}</span>)
                  {{languageData['Bestelloptionen'].Var_netto || 'netto'}}
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  <span>{{offer.currencySymbol}}</span>
                  {{offer.basicPriceNett | langReplacer : languageVar }}
                </div>
              </div>
              <!-- Paushale settings -->
              <div *ngIf="offer?.surchargeNett" style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%">
                  + {{sessionData?.stations}} x
                  {{offer.surcharge?.name }} netto
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  <span>{{offer.currencySymbol}}</span> {{offer.surchargeNett | langReplacer : languageVar }}
                </div>
              </div>
              <!-- ratepayment -->
              <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%">
                  + {{ languageData?.Bestelloptionen['Var_servicefeefinancing'] || 'Servicegebühr
                  Finanzierung '}}
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  {{offer.currencySymbol}}
                  {{offer.ratePaymentDetails?.servicegebuehr | langReplacer : languageVar}}
                </div>
              </div>
              <!-- voucher -->
              <!-- Default voucher -->
              <div class="gutshchein-3rd">
                <div class="gutschein-wrapper">
                  <div class="gutschein-input" style="cursor:pointer">
                    <div class="mfont14 Guts gutline" style="min-width: 94px;margin-bottom: 2%;">-
                      {{languageData['Bestelloptionen'].Var_Gutschein || 'Gutschein'}}:
                    </div>
                    <div class="input-container gutinput" style="position:relative">
                      <input class="auform-control guts gutts" style="width:44%" name="gutschein" minlength="4"
                        pattern="[A-Za-z0-9äöüÄÖÜß ]*" (ngModelChange)="updateoptions($event,'VOUCHER')"
                        [disabled]="showFullScreenLoading" (blur)="voucherfun($event)" required [value]="voucherCode" />

                      <div class="gut reloadgut" style="position: absolute; right: 57%;padding:10px 0px;cursor: pointer">
                        <span [innerHTML]="reload" *ngIf="reload" style="margin-top: 3px;margin-right: 3px;"></span>
                        <img *ngIf="!reload" [src]="'assets/svgs/reload.svg' | assetsUrl"
                          (click)="voucherValidity(offer.voucherInfo?.code)">
                      </div>

                      <!-- gutschein brutto -->
                      <div class="mfont14 gutbrutto" *ngIf="offer.voucherInfo">
                        <span style="color:black; position: relative; bottom: -2px;">
                          ({{languageData['Bestelloptionen'].Var_brutto|| 'brutto'}}: <span style="white-space:nowrap">
                            <span>{{offer.currencySymbol}}</span>
                            <span> {{offer.voucherInfo?.value | langReplacer : languageVar}})</span>
                          </span></span>
                      </div>
                    </div>
                  </div>
                  <div class="mfont14" *ngIf="offer.voucherInfo"
                    style="justify-content: flex-end; display: flex; align-items: center;min-width:60px;margin-bottom: 1.5%;">
                    <span style="padding-right: 5px;">{{offer.currencySymbol}}</span>
                    {{voucherNett| langReplacer : languageVar}}
                  </div>
                  <div class="mfont14" *ngIf="!offer.voucherInfo"
                    style="justify-content: flex-end; display: flex; align-items: center;min-width:60px;margin-bottom: 1.5%;">
                    <span style="padding-right: 5px;">{{offer.currencySymbol}}</span>
                    0
                  </div>
                </div>
                <div class="mfont14" class="alert-danger" style="margin-top:-15px;"
                  *ngIf="voucherCode && !voucher.valid && touchedVoucherInput">
                  {{languageData['Bestellubersicht'].Var_Ungultiger_Gutschein || 'Ungültiger Gutschein'}}
                </div>
              </div>
              <!-- Tax details -->
              <div [ngStyle]="{'border-bottom':'2px solid '+'var(--primaryColour)'}"
                style="display:flex;padding-bottom:6px;">
                <div class="mfont14" style="width:100%">
                  + {{offer.productTax.taxRate | langReplacer : languageVar }}%
                  {{languageData['Bestelloptionen'].Var_MwSt
                  ||'MwSt'}}.
                </div>
                <div *ngIf="!sessionData.ratePaymentSettings.status" class="mfont14"
                  style="text-align: right;min-width:125px;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer.currencySymbol}}
                  </span>
                  {{offer.tax | langReplacer : languageVar}}
                </div>
                <div *ngIf="sessionData.ratePaymentSettings.status" class="mfont14"
                  style="text-align: right;min-width:125px;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer.currencySymbol}}
                  </span>
                  {{offer.tax | langReplacer : languageVar}}
                </div>
              </div>
              <!-- final price details  -->
              <div [ngStyle]="{color:'var(--primaryColour)'}" style="display:flex;font-size:20px;padding-top:6px">
                <div class="mfont14"  [ngClass]="sessionData.ratePaymentSettings.status? 'ratepayment-set' :'gesamtpriesPadding'">
                  <b *ngIf="!sessionData.ratePaymentSettings.status">{{languageData['Bestelloptionen'].Var_Gesamtpreis
                    || 'Gesamtpreis'}}</b>
                  <span *ngIf="sessionData.ratePaymentSettings.status">{{languageData['Bestelloptionen'].Var_Warenwert
                    || 'Warenwert'}}
                    ({{sessionData.quantity | langReplacer : languageVar}}<span>{{unitsSymbol}}</span>)
                    {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}</span>
                </div>
                <div class="mfont14" style="text-align: right;min-width:125px;">
                  <b>
                    <span>
                      <span *ngIf="offer?.productTax?.status"> ** </span>
                      {{offer.currencySymbol}}
                    </span>
                  </b>
                  <span *ngIf="!sessionData.ratePaymentSettings.status">
                    <b>
                      <span>
                        {{offer.gross | langReplacer : languageVar }}
                      </span>
                    </b>
                  </span>
                  <span *ngIf="sessionData.ratePaymentSettings.status">
                    <b>
                      <span>
                        {{offer.gross | langReplacer : languageVar }}
                      </span>
                    </b>
                  </span>
                </div>
              </div>
              <!-- error msg for price change -->
              <div *ngIf="third_stage_pricechange"
                style="position:relative; bottom:12px; display: block;text-align:end;color:red">
                <span class="mfont12">
                  {{languageData['Bestatigung'].Var_check_box32 || 'Der Preis hat sich geändert und ihr Angebot wurde
                  angepasst!' }}
                </span>
              </div>
              <!-- error msg for price change -->
              <!-- time dependent surharges -->
              <div class="frame" style="background: #dfdfdf;"
                *ngIf="offer.timeDepandentSurcharges &&(offer.timeDepandentSurcharges.length>0)">
                <div class="mfont12" style="padding-bottom:6px;display: flex">
                  <div class="mfont12" style="width:100%;">
                    <span>
                      {{languageData['Bestelloptionen'].Var_Alternative || 'Alternativ'}}:
                    </span>
                  </div>
                  <div class="mfont12" style="min-width:125px;text-align: right;">
                  </div>
                </div>
                <div class="mfont12" style="padding-bottom:6px;display: flex"
                  *ngFor="let item of timeDepandentSurcharges">
                  <div class="mfont12" style="width:100%;">
                    +
                    <span>{{item.name}}</span>
                    <span class="au-glyphicon au-glyphicon-info-sign">
                      <span [innerHTML]="infoSign" *ngIf="infoSign" (click)="alternativePopup(item.specialText)"></span>
                      <img *ngIf="!infoSign" [src]="'assets/svgs/infoSign.svg' | assetsUrl"
                        (click)="alternativePopup(item.specialText)">
                    </span>
                  </div>
                  <div class="mfont12" style="min-width:125px;text-align: right;">
                    <span>
                      {{offer?.currencySymbol}}
                    </span>
                    {{item?.timeDepandantSurchargeNett | langReplacer : languageVar}}
                  </div>
                </div>
                <div class="mfont12" style="display:flex;border-bottom: 1px solid black">
                  <div class="mfont12" style="width:100%;">
                    +
                    {{offer.productTax.taxRate | langReplacer : languageVar}}%
                    {{languageData['Bestelloptionen'].Var_MwSt ||'MwSt'}}.
                  </div>
                  <div class="mfont12" style="min-width:125px;text-align: right;">
                    <span>
                      <span *ngIf="offer?.productTax?.status"> ** </span>
                      {{offer?.currencySymbol}}
                    </span>
                    <span>
                      {{offer?.taxTimeDepandandentSurchargeNett | langReplacer : languageVar}}
                    </span>
                  </div>
                </div>
                <div class="mfont12" style="display:flex;padding-top: 10px;">
                  <div class="mfont12" style="width:100%;">
                    <span>
                      <span *ngIf="!sessionData.ratePaymentSettings.status">=
                        {{ languageData?.Bestelloptionen['Var_Gesamtpreis'] || 'Gesamtpreis'}}
                        ({{sessionData.quantity | langReplacer : languageVar}}
                        <span>{{unitsSymbol}}</span>)
                      </span>
                      <span *ngIf="sessionData.ratePaymentSettings.status">=
                        {{ languageData?.Bestelloptionen['Var_Warenpreis'] || 'Warenpreis'}}
                        ({{sessionData.quantity | langReplacer : languageVar}}
                        <span>{{unitsSymbol}}</span>)
                      </span>
                      {{languageData['Bestelloptionen'].Var_brutto || 'brutto'}}
                    </span>
                  </div>
                  <div class="mfont12" style="min-width:125px;text-align: right;">
                    <span>
                      <span *ngIf="offer?.productTax?.status"> ** </span>
                      {{offer?.currencySymbol}}
                    </span>
                    <span>
                      {{offer?.grossWithTimeDepandantCharges | langReplacer :languageVar}}
                    </span>
                  </div>
                </div>
              </div>
              <!-- rate payment settings -->
              <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;margin-top:25px">
                <div class="mfont12" style="width:100%">
                  {{ languageData?.Bestelloptionen['Var_financingterm'] || 'Finanzierung Laufzeit:' }}:
                  {{ offer?.paymentDetails?.numberOfInstallments || 6 }}
                  {{ languageData?.Bestelloptionen['Var_month'] || 'Monate' }}
                </div>
              </div>
              <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;">
                <div class="mfont12" style="width:100%">
                  {{ offer?.paymentDetails?.numberOfInstallments - 1}} X
                  {{ languageData?.Bestelloptionen['Var_monthly'] || 'Monatsrate brutto'}}
                </div>
                <div class="mfont12" style="text-align: right;min-width:125px;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer.currencySymbol}}
                  </span>
                  {{offer?.paymentDetails?.installment | langReplacer : languageVar}}
                </div>
              </div>
              <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:6px;"
                [ngStyle]="{'border-bottom':'2px solid '+'var(--primaryColour)'}">
                <div class="mfont12" style="width:100%">
                  1 X {{ languageData?.Bestelloptionen['Var_finalinstallment'] || 'Schlussrate brutto'}}
                </div>
                <div class="mfont12" style="text-align: right;min-width:125px;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer?.currencySymbol}}
                  </span>
                  {{offer?.paymentDetails?.lastInstallment | langReplacer : languageVar}}
                </div>
              </div>
              <div *ngIf="offer.ratePayment" [ngStyle]="{color:'var(--primaryColour)'}"
                style="display:flex;padding-top:6px;font-size:20px">
                <div class="mfont12" style="width:100%">
                  <b>
                    {{ languageData?.Bestelloptionen['Var_totalfinance'] || 'Gesamtsumme Finanzierung'}}
                  </b>
                </div>
                <div class="mfont12" style="text-align: right;min-width:125px;">
                  <span>
                    <span *ngIf="offer?.productTax?.status"> ** </span>
                    {{offer?.currencySymbol}}
                  </span>
                  <b>
                    {{offer.paymentDetails?.totalValue | langReplacer : languageVar}}
                  </b>
                </div>
              </div>
              <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:25px;">
                <div class="mfont12" style="width:100%">
                  <span>{{languageData['Bestelloptionen'].Var_interestRate}}
                  </span>
                </div>
                <div class="mfont12" style="text-align: right;min-width:125px;">
                  {{offer.currencySymbol}}
                  {{offer.paymentDetails?.totalInterest | langReplacer : languageVar}}
                </div>
              </div>
              <!-- <div *ngIf="offer.ratePayment" style="display:flex;padding-top:6px;">
                <div class="mfont12" style="width:100%;color: #888888">
                  {{ausgewaehlterRatenplan.tilgungsplanText}}
                </div>
              </div> -->
              <!-- <div *ngIf="offer.ratePayment" style="display:flex;padding-bottom:15px;padding-top:6px;">
                <a [ngStyle]="{color:'var(--primaryColour)'}" target="_blank"
                  [href]="ausgewaehlterRatenplan.urlVorvertraglicheInformationen">{{languageData['Bestelloptionen'].Var_pre_contractual_information}}</a>
              </div> -->
              <!-- voucher related settings -->
              <small *ngIf="offer?.voucherInfo && offer?.voucherInfo?.value" class="addedVoucher"
                [ngStyle]="third_stage_pricechange ? {'position': 'relative', 'bottom': '18px'} : {}">
                <div style="position: relative; bottom: 10px;">
                  <br>{{languageData['Bestelloptionen'].Var_grossValueVoucher || 'Gutschein i.H.v.'}}
                  <b class="auprisfont14 inline-block">
                    {{offer.voucherInfo?.value}}
                  </b>
                  <span>
                    {{offer.currencySymbol}}
                  </span>,
                  {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                  {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                  {{languageData['Bestelloptionen'].Var_enthalten }}
                </div>
              </small>
              <!-- Company terms and conditions -->
              <div style="display:block;" class="checkbox-inline1">
                <div *ngFor="let pdfData of thridStagePdfs" style="margin-bottom:10px !important;">
                  <span *ngIf="pdfData.show_in_pricecalculator"
                    [ngStyle]="{'margin-left':!pdfData.checkbox_in_the_pricecalculator ? '' : '','color':(!pdfData.checked &&  pdfData.touched && pdfData?.checkbox_in_the_pricecalculator &&  pdfData.mandatory_checkbox) ? 'red' : 'black' }">
                    <input class="form-check-input check" (change)="pdfOnSelect($event.target.checked, pdfData)"
                      [checked]="pdfData.checked" *ngIf="pdfData.checkbox_in_the_pricecalculator" type="checkbox"
                      style="cursor: pointer;" />
                    <span class="alignPdf">
                      {{pdfData.textarea1}}
                      <b>
                        <a *ngIf="!pdfData.isLink" [ngClass]="pdfData.filename ? 'colors' :'noColor'"
                          style="position:relative; bottom:1px !important" (click)="downloadFile(pdfData.pdf)">
                          {{pdfData.name}}
                        </a>
                        <a *ngIf="pdfData.isLink" [ngClass]="pdfData.link ? 'colors' :'noColor'"
                          [href]="pdfData.link | securitycheck" target="_blank">
                          {{pdfData.name}}
                        </a>
                      </b>
                      {{pdfData.textarea2}}
                      <span *ngIf="pdfData?.checkbox_in_the_pricecalculator && pdfData?.mandatory_checkbox"
                        style="position: absolute;">*</span>
                    </span>
                  </span>
                  <!-- in future if we need use it for error msgs -->
                  <!-- <div  class="alert-danger" style="margin-left: 2px;" *ngIf ="!pdfData?.checked &&  pdfData.touched && pdfData?.mandatory_checkbox">
                      {{languageData['Bestatigung'].Var_pdfError || 'Um Ihre Bestellung abzuschließen, müssen alle verpflichtenden Bestimmungen akzeptiert sein!'}}
                      </div> -->
                </div>

                <div class="alert-danger" style="margin-left: 2px;margin-top:10px" *ngIf="showPdfError">
                  {{languageData['Bestatigung'].Var_pdfError || 'Um Ihre Bestellung abzuschließen,
                  müssen alle
                  verpflichtenden Bestimmungen akzeptiert sein!'}}
                </div>

                <!-- last checkbox -->
                <div *ngIf="offer.productTax.status">
                  <div class="form-check">
                    <input class="form-check-input check" style="margin-top: 0px !important"
                      (change)='productTaxCheck($event)' type="checkbox" style="cursor: pointer">
                    <span style="left: 24px;position: relative; bottom: 1px ">
                      {{languageData['Bestatigung'].Var_checkbox_4 || 'Ich habe den'}}

                      <b>
                        <a [ngStyle]="{color: 'var(--primaryColour)'}"
                          (click)="steuerhinweisPopup(offer?.productTax?.infoText)">
                          {{languageData['Bestatigung'].Var_specialnote_on_Vat || '**Sonderhinweis zur Mehrwertsteuer'}}
                        </a>
                      </b>
                      {{languageData['Bestatigung'].Var_read_and_understood||'gelesen und verstanden.'}}
                    </span>
                  </div>
                </div>

                <div style="display:block" *ngIf="third_stage_pricechange" [ngStyle]="{'margin-bottom' :(third_stage_pricechange) ? '10px': ''}">
                  <div class="form-check" style="position: relative;">
                    <input class="form-check-input check" type="checkbox" (change)='priceChangeAccept($event)'>
                    <span class="alignPdf">
                       {{
                      languageData['Bestatigung'].Var_check_box3 || 'Die Preisveränderung wurde gesehen und akzeptiert!'
                      }}
                    </span>
                  </div>
                  <!--<p class= "alert-danger">
                    {{
                      languageData['Bestatigung'].Var_check_box32 || 'Der Preis hat sich geändert und ihr Angebot wurde angepasst!'
                    }}
                  </p>-->
                </div>
                <!-- ratepayment && zaulung plan -->
                <!-- <div *ngIf="offer.ratePayment && (ausgewaehlterRatenplan && ausgewaehlterRatenplan.mTanRequired) "
                  style="margin-top: 15px;">
                  <label class="mtanLabel">mTAN für Ratenkauf*</label>
                  <input type="text" class="mtanInput" [formControl]="mtanInput">
                  <span *ngIf="showResendmTan" class="mtanSendAgain"
                    (click)="resendMtan(ausgewaehlterRatenplan.tbVorgangskennung)"
                    [ngStyle]="{color: 'var(--primaryColour)'}">mTAN emeut senden</span>
                </div>
                <div class="mfont12" class="alert-danger" style="margin-top:5px;"
                  *ngIf="offer.ratePayment && showMtanError">
                  {{languageData['Bestatigung'].Var_wrongmTan || 'Ungültige mTAN!'}}
                </div>  -->
                <!-- <div style="display:block"
                    *ngIf="offer.productTax.status">
                    <div class="form-check">
                      <input class="form-check-input check" type="checkbox"
                        style="cursor: pointer">
                      {{languageData['Bestatigung'].Var_checkbox_4 || 'Ich habe den'}}
                      <b>
                        <a [ngStyle]="{color: 'var(--primaryColour)'}" (click)="steuerhinweisPopup(offer.productTax.infoText)">
                          {{languageData['Bestatigung'].Var_specialnote_on_Vat || '**Sonderhinweis zur Mehrwertsteuer'}}</a>
                      </b>
                      {{languageData['Bestatigung'].Var_read_and_understood||'gelesen und verstanden.'}}
                    </div>
                  </div>  -->

                <!-- ratepayment && zaulung plan -->
                <div *ngIf="offer.ratePayment && (ausgewaehlterRatenplan && ausgewaehlterRatenplan.mTanRequired) "
                  style="margin-top: 15px;">
                  <label class="mtanLabel">mTAN für Ratenkauf*</label>
                  <input type="text" class="mtanInput" [formControl]="mtanInput">
                  <span *ngIf="showResendmTan" class="mtanSendAgain"
                    (click)="resendMtan(ausgewaehlterRatenplan.tbVorgangskennung)"
                    [ngStyle]="{color: 'var(--primaryColour)'}">mTAN emeut senden</span>
                </div>
                <div class="mfont12" class="alert-danger" style="margin-top:5px;"
                  *ngIf="offer.ratePayment && showMtanError">
                  {{languageData['Bestatigung'].Var_wrongmTan || 'Ungültige mTAN!'}}
                </div>
                
                <div class="aurows">
                  
                  <div class="zahlungbutton">
                    <button  *ngIf="!easyCreditButton" class="au-btn  aucol-md-12" style="width:100%;" cyPlaceOrder="cyPlaceOrder"
                      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                      (click)=" ZahlungMail()">{{placeOrderBtn}}</button> 
                  </div>

                  <button   *ngIf="(easyCreditButton &&(orderStatus =='success') ) && !priceChangewithPaymentMode" class="au-btn  aucol-md-12" style="width:100%;" cyPlaceOrder="cyPlaceOrder" 
                  [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                  (click)="ZahlungMail()">
                  {{placeOrderBtn}}
                 </button>
                 
                 
                   <div  *ngIf="easyCreditButton && (orderStatus == '' ||   orderStatus=='cancel' ||priceChangewithPaymentMode == 'true')">
                    <div class="ec-express-button" style="opacity: 1;" (click)="paymentContinue()">
                      <div class="ec-express-button__btn full-width" style="width: 321px;">
                        <a class="ec-express-button__btn__main">
                          <div class="logo"></div>
                          <div class="ec-express-button__btn__main__inner" style="flex-basis: 190px; width: 190px;">
                            <span class="financing">Jetzt direkt in Raten zahlen</span>
                          </div>
                        </a>
                      </div>
                      <!-- <a class="ec-express-button__link" target="_blank" style="width: 321px;">
                        <div class="icon"></div>
                        <div>Mehr zu <strong style="color:rgb(0, 44, 90) !important;font-size:10px">easyCredit-Ratenkauf</strong></div>
                      </a> -->
                    </div>
                   </div>
                </div>
                <div style="text-align: right;">
                  * {{languageData['Bestatigung'].Var_Mandatory || 'Verpflichtende Angabe'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- theme 2 ends -->



  <loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>
</div>



<!-- desired date settings -->
<!-- <span *ngIf="sessionData?.desiredDateSettings.wunstermin">
  <div class="aurow">
    <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_firstDeliveryDate_text
      ||
      'Frühester Liefertermin'}}</span>
    <span class="mfont mfont12">{{sessionData?.desiredDateSettings.fromDate}}</span>
  </div>
  <div class="aurow">
    <span class="control1 mfont12">{{languageData['Bestelloptionen'].Var_lastDeliveryDate_text
      ||
      'Spätester
      Liefertermin'}}</span>
    <span class="mfont mfont12">{{sessionData?.desiredDateSettings.toDate}}</span>
  </div>
</span> -->



<!-- desired date settings -->
<!-- <span *ngIf="sessionData?.desiredDateSettings.wunstermin">
  <div class="aurow">
    <span class=" control1 mfont12">{{languageData['Bestelloptionen'].Var_firstDeliveryDate_text
      ||
      'Frühester Liefertermin'}}</span>
    <span class="mfont mfont12">{{sessionData?.desiredDateSettings.fromDate}}</span>
  </div>
  <div class="aurow">
    <span class="control1 mfont12">{{languageData['Bestelloptionen'].Var_lastDeliveryDate_text
      ||
      'Spätester
      Liefertermin'}}</span>
    <span class="mfont mfont12">{{sessionData?.desiredDateSettings.toDate}}</span>
  </div>
</span> -->