import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CommonServiceLatest } from './common.service';
import { Observable, Subject, } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalAuthService } from 'app/login/auth.service';
import { SmallPcService } from 'app/Services/small-pc.service';
import { CommonService } from 'app/common.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BigpcService {
  public ipaddress: any;
  baseUrl: string = environment.baseUrl;
  showLoginPage = new Subject<{ login: boolean }>();
  showLoginPage$ = this.showLoginPage.asObservable();
  redirectLoginPage = new Subject<{ login: boolean }>();
  redirectLoginPage$ = this.redirectLoginPage.asObservable();
  showDashBoard = new Subject<{ show: boolean }>();
  showDashBoard$ = this.showDashBoard.asObservable();
  showSecondStage = new Subject<boolean>();
  showSecondStage$ = this.showSecondStage.asObservable();
  setNewPc = new Subject<{ new: boolean, userData: any }>();
  setNewPc$ = this.setNewPc.asObservable();
  updatePriceAlaram = new Subject<{ data: any }>();
  updatePriceAlaram$ = this.updatePriceAlaram.asObservable();
  pcColours = new Subject();
  pcColours$ = this.pcColours.asObservable();

  showDynamicPc = new Subject<{ show: boolean }>();
  showDynamicPc$ = this.showDynamicPc.asObservable();
  // updateToDasboard: Subject<any> = new Subject()
  url: string;
  constructor(
    private httpClient: HttpClient,
    public commonService: CommonServiceLatest,
    public portalAuthService: PortalAuthService,
    public smallPCService: SmallPcService,
    public CommonService: CommonService
  ) {
    // this.ipaddress = JSON.parse(localStorage.getItem('aupris_ipaddress'));
  }

  // get options and option sets data
  // dynamicOptions(data: any) {
  //   let authHeader = new HttpHeaders();
  //   authHeader = authHeader.append('Content-Type', 'application/json');
  //   let domainName = this.commonService.getDomainName();
  //   let dynamicProductId = data.dynamicProductId
  //   let plzId = data.plzId || "597f43b48324563a64de6610"
  //   if (domainName && dynamicProductId && plzId) {
  //     authHeader = authHeader.append('domainname', domainName);
  //     authHeader = authHeader.append('dynamicProductId', dynamicProductId);
  //     authHeader = authHeader.append('plzid', plzId);
  //   }
  //   return this.httpClient.get(this.baseUrl + '/bigpc/options', { headers: authHeader });
  // };

  // dynamicOptions(data: any) {
  //   return this.httpClient.get(this.baseUrl + '/bigpc/options');
  // };

  //products data getting based on tag  and client domain name 
  // products(data): Observable<any> {
  //   let authHeader = new HttpHeaders();
  //   authHeader = authHeader.append('Content-Type', 'application/json');
  //   authHeader = authHeader.append('tag', data.tag.toString());
  //   authHeader = authHeader.append('dynamiceProductId', '' + data.dynamicProductId);
  //   authHeader = authHeader.append('domainname', 'localhost');
  //   return this.httpClient.get(this.baseUrl + '/webLinks/products', { headers: authHeader });
  // };

  products(data): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/webLinks/products');
  };


  dynamicPcHolidays(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/holidays');
  };

  // product info used in big pc
  // dynamicProductInfoForWebPc(data): Observable<any> {
  //   let authHeader = new HttpHeaders();
  //   // authHeader = authHeader.set('tag', data.tag.toString());
  //   // authHeader = authHeader.set('dynamiceProductId', data.dynamiceProductId.toString());
  //   // authHeader = authHeader.set('domainname', 'localhost');
  //   authHeader = authHeader.set('tag', '1');
  //   authHeader = authHeader.set('dynamiceProductId', '61d421c387bf6a786895f827');
  //   authHeader = authHeader.set('domainname', 'localhost');
  //   return this.httpClient.post(this.baseUrl + '/webLinks/productInfoForWebPc', data, { headers: authHeader });
  // };

  dynamicProductInfoForWebPc(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/webLinks/productInfoForWebPc', data).pipe(map((response: Response) => {
      if (response['success']) {
        let portalSettings = response['data']['portalSettings'];
        if (portalSettings?.status) {
          if (!this.portalAuthService.isUserLoggedIn()) {
            if (portalSettings['position'] == 1) {
              this.portalAuthService.isLoggedInSubject.next({ guestLogin: false, userLogin: false })
            }
          };
          // this.portalAuthService.isUserLoggedIn() && this.portalAuthService.getAuprisToken() && this.portalAuthService.getAuprisToken().includes('JWT')

        };
        return response;
        // if(response.data)
      } else {

      }
    }))
  };


  companyInfoForWebPc(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/webLinks/companyInfoForWebPc');
  };

  dynamicOptions() {
    let headers = new HttpHeaders();
    headers = headers.append('webPC', 'true');
    return this.httpClient.get(this.baseUrl + '/calculation/getDynamicOptions');
  };

  getPortalOptions(data: any) {
    return this.httpClient.post(this.baseUrl + '/calculation/getPortalOptions', data);
  }

  calculatedQualities(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/calculation/priceCalculation', data);
  };

  order(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/order/placeOrder', data);
  };

  getTempOrderData(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/order/getTempOrderdData', data);
  };

  goForInstallmentPurchase(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/order/installMentPurChase', data);
  };

  tempOrder(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/order/placeTempOrder', data);
  };

  calcuLieferTerminDates(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/calculation/calcLieferDates', data);
  };

  getWunsterminOptionsData(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/calculation/wunsterminInfo', data);
  };


  postalCode(body: object): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/postalCode', body);
  };

  ZahlungMail(body: object, domain) {
    return this.httpClient.post(this.baseUrl + '/bigpc/ZahlungMail', JSON.stringify(body)).pipe(map((response: Response) => response.json()));
  };

  pdfiledownload(body: object, domain) {
    return this.httpClient.post(this.baseUrl + '/bigpc/pdfdownload', JSON.stringify(body)).pipe(map((response: Response) => response));
  };

  downloadInvoice(body) {
    return this.httpClient.post(this.baseUrl + '/bigpc/downloadInvoiceportal', JSON.stringify(body)).pipe(map((response: Response) => response.json()));
  };

  // userRegister(data: object, domain) {
  //   return this.httpClient.post(this.baseUrl + '/userRegistration', JSON.stringify(data)).pipe(map((response: Response) => response.json()));
  // };

  // login(data: object, domain) {
  //   return this.httpClient.post(this.baseUrl + '/customLogin', JSON.stringify(data)).pipe(map((response: Response) => response.json()));
  // };

  emailVerification(data: object, domain) {
    return this.httpClient.post(this.baseUrl + '/emailVerification', JSON.stringify(data)).pipe(map((response: Response) => response.json()));
  };

  pretext_event(domain) {
    return this.httpClient.get(this.baseUrl + '/bigpc/pretext').pipe(map((response: Response) => response));
  };


  data_security_Verification(data: object, domain) {
    return this.httpClient.post(this.baseUrl + '/data_security_emailVerification', JSON.stringify(data)).pipe(map((response: Response) => response.json()));
  };

  data_security_delete(data: object, domain) {
    return this.httpClient.post(this.baseUrl + '/data_security_delete', JSON.stringify(data)).pipe(map((response: Response) => response));
  };

  displayLoginPage(event: boolean) {
    this.showLoginPage.next({ login: event });
  };

  loginPage(event: boolean) {
    this.redirectLoginPage.next({ login: event });
  };

  showdashboard(ev: boolean) {
    this.showDashBoard.next({ show: ev });
  };

  createNewPcSessionWithPrimaryAdress(event) {
    this.setNewPc.next({ new: event.session, userData: event.userData });
  };

  NavigateToPriceCalculator(event: boolean) {
    this.showDynamicPc.next({ show: event });
  };

  displaySecondStage(event) {
    this.showSecondStage.next(event);
  };

  updatePriceAlramdata(data) {
    this.updatePriceAlaram.next({ data: data });
  };

  gutscheinData(data): Observable<any> {
    let authHeader = new HttpHeaders();
    authHeader = authHeader.set('domainname', 'localhost');
    return this.httpClient.post(this.baseUrl + '/validateVoucher', data, { headers: authHeader });
  };

  getDefaultPlans(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/payment/installments', data);
    // return of({ success: true, data: [{ 'zahlungRate': 6 }, { 'zahlungRate': 12 }, { 'zahlungRate': 18 }] });
  };

  mTanResend(data) {
    return this.httpClient.post(this.baseUrl + '/mTANResend', data);
  };

  mTanVerify(data) {
    return this.httpClient.post(this.baseUrl + '/mTANverify', data);
  };

  rateApproval(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/rateapproval', data);
  };

  getIbanByDetails(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/ibanDetailByAccount', JSON.stringify(data));
  };

  checkIban(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/checkIban', JSON.stringify(data));
  };

  checkAvailApi(): Observable<any> {
    return this.httpClient.get(this.baseUrl + '/checkAvailApi');
  };

  // getIbanByDetails(data): Observable<any> {
  //   var authHeader = new Headers();
  //   authHeader.append('Content-Type', 'application/json');
  //   let domainName = this.getDomainName();
  //   if (domainName) {
  //     authHeader.append('domainname', domainName);
  //   }
  //   return this._http.post(this.baseUrl + '/ibanDetailByAccount',
  //     JSON.stringify(data), { headers: authHeader }).pipe(map((response: Response) => response.json()));
  // }


  // createNewSeqence(data): Observable<any> {
  //   return this.httpClient.post(this.baseUrl + '/webLinks/createNewSeqence', data);
  // };

  dynamicPcWithAddress(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/dynamicPcWithAddress', data);
  };

  getQualitiesList(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/bigpc/getQualities', data);
  };

  getNativeAppId(data) {
    return this.httpClient.post(this.baseUrl + '/getNativeAppId', data);
  }

  getDriverAppLicenses(domain, productName) {
    return new Promise((resolve, reject) => {
      var authHeader = new Headers();
      authHeader.append('domainname', domain);
      authHeader.append('ip', this.ipaddress);
      authHeader.append('Cache-control', 'no-cache');
      authHeader.append('Cache-control', 'no-store');
      authHeader.append('Expires', '0');
      authHeader.append('Pragma', 'no-cache');
      if (this.portalAuthService.isUserLoggedIn() && (this.portalAuthService.getAuprisToken() && this.portalAuthService.getAuprisToken().includes('JWT'))) {
        authHeader.append('authorization', this.portalAuthService.getAuprisToken())
      }
      if (productName) {
        authHeader.append('product', productName);
      }
      // { headers: authHeader } passing through interspecter check
      resolve(this.httpClient.get(`${this.baseUrl}/trackingInfo/getDriverAppLicenses`).pipe(map((response) => response)).toPromise());
    });
  };


  FB_detailsInsert(data: object, domain) {
    this.updatedtoken();
    return this.httpClient.post(this.baseUrl + '/FB_detailsInsert', JSON.stringify(data)).pipe(map((response: Response) => response));
  };

  token: any;
  updatedtoken() {
    if (localStorage.getItem('aupris_token')) {
      this.token = localStorage.getItem('aupris_token');
    }
  };

  getDomainName() {
    if (!environment.testSite_or_thirdPartySites) {
      this.url = window.location.href;
      let params = new URLSearchParams(this.url);
      let domainname = decodeURIComponent(params.get("Domainname"));
      return domainname;
    } else {
      return false
    }
  };

  async createNewSession(data) {
    this.url = window.location.href;
    let params = new URLSearchParams(this.url);
    let sessionId = decodeURIComponent(params.get("sid"));
    let getData: any = sessionStorage.getItem('aupris_ipaddress');
    getData = JSON.parse(getData) ?? {};
    let newSessionId;
    let matchId;
    if (sessionId && sessionId != "null") {
      if (getData && Object.keys(getData).length) {
        matchId = getData.sessionId
      }
      if (matchId != sessionId) {
        newSessionId = matchId
      } else {
        newSessionId = sessionId;
      }
    } else {
      newSessionId = await this.smallPCService.genereateNewSessionId();
    };

    let selectedProduct;
    let quantity;
    let stations;
    if (data.seelctProduct) {
      selectedProduct = {
        availableProduct: true,
        ...data.seelctProduct.dynamicPcBasicSettings,
        tag: data.seelctProduct.tag,
      };
      quantity = data.primaryAdress.quantityVal;
      stations = data.primaryAdress.stationVal;
    } else if (data.preview) {
      selectedProduct = {
        availableProduct: true,
        tag: data.selectedProduct.tag,
        dynamicProductId: data.selectedProduct._id,
        name: data.selectedProduct.product
      }
      quantity = data.quantity;
      stations = 1;
    } else {
      selectedProduct = await this.prepareProductData();
      quantity = 3000;
      stations = 1;
    };

    if (selectedProduct['availableProduct']) {
      let plzData;
      let url;
      let domains = await JSON.parse(localStorage.getItem("domainUrls"));

      if (data.loginUser && data.primaryAdress && Object.keys(data.primaryAdress).length) {
        plzData = {
          plzId: data.primaryAdress.plzId,
          city: data.primaryAdress.city,
          postalcode: data.primaryAdress.zipCode,
          suburb: data.primaryAdress.suburb,
          latitude: data.primaryAdress.latitude ? data.primaryAdress.latitude : '',
          longitude: data.primaryAdress.longitude ? data.primaryAdress.longitude : '',
        };
      };

      if (data.preview) data.loginUser = false
      // this.url = window.location.href;
      // var params = new URLSearchParams(this.url);
      let stages = ["one", "two", "three", "four"];
      let stageVal = params.get('stage');
      if (environment.testSite_or_thirdPartySites) {
        if (stages.includes(stageVal)) {
          url = data.loginUser ? `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&page=dynamicPcs&stage=one` : `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&stage=${stageVal}`;
        } else {
          url = data.loginUser ? `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&page=Dashboard` : `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&stage=one`;
        };
      } else {
        if (stages.includes(stageVal)) {
          if (data.preview) {
            url = data.loginUser ? `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&page=dynamicPcs&stage=one` : `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&stage=one&forPreview=true`;
          } else {
            url = data.loginUser ? `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&page=dynamicPcs&stage=one` : `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&stage=${stageVal}`;
          }
        } else {
          url = data.loginUser ? `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&page=Dashboard` : `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&stage=one`;
        };
      };
      if (window.location.href.includes('aupris.com')) {
        url = url.replace('http://', 'https://');
      }
      let preparePcInfo = {
        newSessionId: newSessionId,
        selectedProduct: selectedProduct,
        plzData: plzData,
        url: url,
        domains: domains,
        quantity: quantity,
        stations: stations,
      };
      this.setSessionDataLocal(preparePcInfo);
    } else {
      return;
    }
  };

  prepareProductData() {
    return new Promise((resolve, reject) => {
      try {
        this.smallPCService.unterProducts().subscribe((productsData: any) => {
          let seletectedProduct;
          if (productsData.success && productsData.data.length) {
            seletectedProduct = {
              dynamicProductId: productsData.data[0].dynamicPcBasicSettings?.dynamicProductId,
              name: productsData.data[0].dynamicPcBasicSettings?.name,
              tag: productsData.data[0].tag || productsData.data[0].loginColors.tag || 1,
              availableProduct: true,
            }
            resolve(seletectedProduct);
          };
          reject({ availableProduct: false });
        });
      }
      catch (err) {
        reject({ availableProduct: false });
      }
    });
  };

  setSessionDataLocal(pcInfo) {
    console.log(pcInfo, 5454546);

    let sessionData = {
      priceCalcUrl: pcInfo.domains.priceCalcUrl,
      domainName: pcInfo.domains.domainName,
      quantity: pcInfo.quantity ? parseInt(pcInfo.quantity) : 3000,
      stations: pcInfo.quantity ? parseInt(pcInfo.stations) : 1,
      product: {
        "_id": pcInfo.selectedProduct?.dynamicProductId,
        "name": pcInfo.selectedProduct?.name,
      },
      postalCode: {
        plzId: pcInfo.plzData?.plzId ? pcInfo.plzData?.plzId : "",
        city: pcInfo.plzData?.city ? pcInfo.plzData?.city : "",
        postalcode: pcInfo.plzData?.postalcode ? pcInfo.plzData?.postalcode : "",
        suburb: pcInfo.plzData?.suburb ? pcInfo.plzData?.suburb : "",
        latitude: pcInfo.plzData?.latitude ? pcInfo.plzData?.latitude : "",
        longitude: pcInfo.plzData?.longitude ? pcInfo.plzData?.longitude : "",
      },
      localOptions: [
        { optionSetId: '', optionId: '', sort: 1 },
        { optionSetId: '', optionId: '', sort: 2 },
        { optionSetId: '', optionId: '', sort: 3 },
        { optionSetId: '', optionId: '', sort: 4 },
        { optionSetId: '', optionId: '', sort: 5 },
      ],
      ratePaymentSettings: {
        status: false,
        zahlungRate: '',
        zahlungspans: []
      },
      desiredDateSettings: {},
      cart: [
        {
          _id: ''
        }
      ],
      co2Compensation: [],
      tag: pcInfo.selectedProduct?.tag || 1,
    };
    if (pcInfo.selectedProduct?.availableProduct) {
      // console.log("enterr");

      this.smallPCService.setSessionData(pcInfo?.newSessionId, sessionData).then(() => {
        // console.log("session created");
        let oldUrl = window.location.href;
        if (oldUrl == pcInfo?.url) {
          window.location.reload();
        } else if (this.CommonService.isSafari()) {
          window.history.pushState("", document.title, pcInfo?.url);
          window.location.reload();
        }
        else {
          window.location.replace(pcInfo?.url);
        };
      });
    } else {
      return;
    };
  };

  finalCreditCheck(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/payment/finalCreditCheck', data);
  };

  async tempOrderDataPrepareForSession(saleTempInfo, sessionId) {
    let tempInfo = saleTempInfo.data;
    let prepareAsLikeLocal = {
      localOptions: tempInfo.options,
      orderAddresses: tempInfo.addresses,
      domainName: tempInfo.bigPcUrls.domainName,
      cart: [],
      co2Compensation: tempInfo.co2Compensation,
      postalCode: tempInfo.address,
      stations: tempInfo.stations,
      quantity: tempInfo.quantity,
      ratePaymentSettings: tempInfo.ratePaymentDetails,
      product: {
        name: tempInfo.orderInfo.product,
        _id: tempInfo.dynamicProductId
      },
      priceCalcUrl: tempInfo.ratePaymentDetails,
      desiredDateSettings: tempInfo.desiredDate,
    };
    await this.smallPCService.setSessionData(sessionId, prepareAsLikeLocal);
  };
  payAuthorize(data): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/payment/authorize', data);
    // return of({ success: true, data: [{ 'zahlungRate': 6 }, { 'zahlungRate': 12 }, { 'zahlungRate': 18 }] });
  };
};
