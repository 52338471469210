import { Component, Inject, OnInit } from '@angular/core';
import { AdminService } from 'app/Services/admin.service';
import { InventoyTrackingService } from 'app/Services/inventoy-tracking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-addresspopup',
  templateUrl: './addresspopup.component.html',
  styleUrls: ['./addresspopup.component.css']
})
export class AddresspopupComponent implements OnInit {
  filteredData: any;
  address: { [key: string]: any }[] = [];

  constructor(public adminService: AdminService,
    private inventoryService: InventoyTrackingService,
    private dialogRef: MatDialogRef<AddresspopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.filteredData = this.data.address.filter(item => item._id !== this.data.selectedAdress);
    this.filteredData = this.filteredData.sort((a, b) => a.sortOrder - b.sortOrder);
    // console.log(this.filteredData ,65464);
  }


  natigateSetup(adressId) {
    this.dialogRef.close({ _id: adressId, address: false })
  }

  changeView(event) {
    this.dialogRef.close({ address: true })
  };
}
