<div class="container">
    <!-- main heading  -->
    <h2 class="text-center top-magin-zero">
        {{ languageData['Dashboard']['VarKundeportalDashboard1']||'Meine Heizung'}}
    </h2>
    <!-- progress bar -->
    <div>
        <app-meine-progress [currentStage]="currentSetupStage"></app-meine-progress>
    </div>
    <!-- stage heading -->
    <span class="stageHeadLineName" (click)="changeColor()">
        {{stageName(currentSetupStage)}}
    </span>

    <!------------------------ stages body start ------------------------------>

    <!-- ----------------------------   Meine heating stage1 start     ------------------------------- -->
    <div *ngIf="currentSetupStage == 1">
        <div class="container">
            <div class="cards">
                <span class="arrows" *ngIf="tiles.length > 3" [ngClass]="{'blue-color': this.productStart > 0}"
                    (click)="moveToPrevious()" style="top: 54px">
                    <p style="position: relative;top: 1px;">❮</p>
                </span>
                <div class="tile-container">
                    <ng-container *ngFor="let tile of tiles.slice(productStart, productEnd); let i = index">
                        <div class="tile card" [class.selected-tile]="tile.isSelected"
                            (click)="selectTile(i,tile,'product')">
                            <mat-checkbox [checked]="true" [disableRipple]="true" [disabled]="true"
                                [(ngModel)]="tile.isSelected" class="tile-checkbox"
                                *ngIf="tile.isSelected"></mat-checkbox>
                            <!-- <img [src]=byPassSVGSecurity(tile.svgUrl) [alt]="tile.text"
                                [class.selected-img]="tile.isSelected" class="dynamicColor"> -->
                            <span id='mySvg' [innerHTML]="svgHtmls[tile.svgUrl]" [class.selected-img]="tile.isSelected"
                                class="dynamicColor svg"></span>
                            <div class="tile-text">{{ tile.name }}</div>
                        </div>
                    </ng-container>
                </div>
                <span class="arrows" *ngIf="tiles.length > 3" [ngClass]="{'blue-color': productEnd < tiles.length}"
                    (click)="moveToNext()" style="top: 54px">
                    <p style="position: relative;top: 1px;">❯</p>
                </span>
            </div>
        </div>

        <div class="container">
            <div class="cards">
                <span class="arrows" *ngIf="allHeatingSytems?.length > 3"
                    [ngClass]="{'blue-color': this.startIndex > 0}" (click)="movePrevious()" style="top: 54px">
                    <p style="position: relative;top: 1px;">❮</p>
                </span>
                <div class="tile-container">
                    <ng-container *ngFor="let tile of heatingSystem.slice(startIndex, endIndex); let i = index">
                        <div class="tile card" [class.selected-tile]="tile.isSelected"
                            (click)="selectTile(i,tile,'heatingSystem')">
                            <mat-checkbox [checked]="true" [disableRipple]="true" [disabled]="true"
                                [(ngModel)]="tile.isSelected" class="tile-checkbox"
                                *ngIf="tile.isSelected"></mat-checkbox>
                            <!-- <img [src]=byPassSVGSecurity(tile.imageUrl) [alt]="tile.text"
                                [class.selected-img]="tile.isSelected" class="dynamicColor"> -->
                            <span [innerHTML]="svgHtmls[tile.svgUrl]" [class.selected-img]="tile.isSelected"
                                class="dynamicColor svg"></span>
                            <div class="tile-text">{{ tile.name }}</div>
                        </div>
                    </ng-container>
                </div>
                <span class="arrows" *ngIf="allHeatingSytems?.length > 3"
                    [ngClass]="{'blue-color': this.endIndex < this.allHeatingSytems.length}" (click)="moveNext()"
                    style="top: 54px">
                    <p style="position: relative;top: 1px;">❯</p>
                </span>
            </div>
        </div>
        <!-- buttons view -->
        <div>
            <button class="popbtn cancel-btn pointer" (click)="close()">
                {{languageData['kundenportalEinstellungen']['VarKundeportalKundenportalEinstellungen7'] || "Abbrechen"}}
            </button>
            <button class="pull-right pointer" (click)="saveStage1()" [ngClass]="{'disabled': !stage1Check}"
                [disbled]="stage1Check">
                {{preisrechnerTranslations['Adresseingabe']['Var_Weiter'] || "Weiter"}}
            </button>
        </div>
    </div>
    <!-- ----------------------------   Meine heating stage1 End     ------------------------------- -->

    <!-- ----------------------------   Meine heating stage2 start     ------------------------------- -->
    <div *ngIf="currentSetupStage == 2">
        <div [formGroup]="stageTwoForm" class="scrollbar">
            <div class="cardoverview">
                <div class="arrows" style="left: 32px;top: 59px;"
                    [ngClass]="{'blue-color': this.houseStartIndex > 0}" *ngIf="HouseTypes?.length > 3"
                    (click)="movePreviousHouse()">
                    <p style="position: relative;top: 1px;">❮</p>
                </div>
                <div #houseContainer style="margin: auto;">
                    <ng-container *ngFor="let option of HouseTypes.slice(houseStartIndex, houseEndIndex)">
                        <div class="card tile" [ngClass]="{highlight:option.show}" (click)="selectedHouse(option)">
                            <div class="text-center">
                                <span *ngIf="option.show" class="checkboxhas2">
                                    <mat-checkbox [checked]="true" [disableRipple]="true" [disabled]="true">
                                    </mat-checkbox>
                                </span>
                                <!-- <span  [ngStyle]="{'margin-left': option.show ? '15px' : '0px' }" [innerHTML]='getSVG(option.icon)'></span> -->
                                <!-- <img [src]=byPassSVGSecurity(option.imageUrl) [alt]="option.type"
                                    [ngStyle]="{'margin-left': option.show ? '15px' : '0px' }" class="dynamicColor"> -->
                                <span id='mySvg' [innerHTML]="svgHtmls[option.svgUrl]"
                                    [ngStyle]="{'margin-left': option.show ? '15px' : '0px' }"
                                    [class.selected-img]="option.isSelected" class="dynamicColor"></span>
                            </div>
                            <div class="text-center cardText"
                                [ngStyle]="{'color': option.show ? 'var(--primaryColour)' : '#333333' }"
                                style="height: 30px;display: flex;align-items: center;">
                                {{option.houseName}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="arrows" style="top: 59px;right: 40px;"
                    [ngClass]="{'blue-color': this.houseEndIndex < this.HouseTypes.length}"
                    *ngIf="HouseTypes?.length > 3" (click)="moveNextHouse()">
                    <p style="position: relative;top: 1px;">❯</p>
                </div>
            </div>

            <div *ngIf="tempEle">
                <div class="unitshas">
                    <span style="margin-right:20px" [innerHTML]="heatLimit"></span>
                    <div class="form-group">
                        <label
                            class="labelset fs-newsLabel">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung19||'Headline'}}</label>
                        <input class="formControl" min="0" type="number" (keydown)="onKeyPress($event)"
                            formControlName="heatedArea">
                        <span style="margin-left: 4px;">m²</span>
                    </div>
                </div>
                <div *ngIf="stageTwoForm.controls.heatedArea.touched && stageTwoForm.controls.heatedArea.status == 'INVALID' && !stageTwoForm.get('heatedArea').hasError('pattern')"
                    class="haus-alert">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
                <div *ngIf="stageTwoForm.get('heatedArea').hasError('pattern') && stageTwoForm.get('heatedArea').touched"
                    class="haus-alert">
                    {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen
                    erlaubt'}}!
                </div>
                <div class="unitshas">
                    <span style="margin-right:20px" [innerHTML]="roomTemperature"></span>
                    <div class="settemp">
                        <label class="bold">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung20 ||
                            'Raumtemperatur'}}</label>
                        <div style="width:25px;display:flex;padding-left:17px;padding-right: 14px;">
                            <div [innerHTML]="Triangle"
                                (click)="tempeatureUpdate(stageTwoForm.controls.tempeature.value , 'i')">
                            </div>
                            <div style="margin-left: 1px;" [innerHTML]="Triangleflipped"
                                (click)="tempeatureUpdate(stageTwoForm.controls.tempeature.value , 'd')"></div>
                        </div>
                        <input type="text" class="input-field" formControlName="tempeature" readonly>
                    </div>
                    <span style="margin-left: 4px;">&deg;C</span>
                </div>
                <div class="unitshas">
                    <span style="margin-right:20px;margin-left: -25px;" [innerHTML]="family">
                    </span>
                    <div class="settemp">
                        <label style="white-space:nowrap"
                            class="bold">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung21 ||
                            'Personen imHaushalt'}}</label>
                        <input type="number" min="0" (keydown)="onKeyPress($event)"
                            style="margin-left:21px;margin-right:-5px" class="input-field" required
                            formControlName="familyCount">
                    </div>

                </div>
                <div class="haus-alert2"
                    *ngIf="stageTwoForm.get('familyCount').hasError('min') || stageTwoForm.get('familyCount').hasError('max')">
                    {{languageData['Erro_msg'].VarKundeportalErro_msg7 || 'Bitte geben Sie einen Wert zwischen 1 und 20
                    ein!'}}
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;">
            <button class="popbtn pointer" (click)="navigateToStageOne()" style="background-color: #ddd;"> {{'Zurück'}}
            </button>
            <button class="popbtn saveBtn pointer" [ngClass]="{'disabled': !stageTwoForm.valid}"
                [disabled]="!stageTwoForm.valid" (click)="saveStageTwo()">{{'Weiter'}}</button>
        </div>
    </div>
    <!-- ----------------------------   Meine heating stage2 End     ------------------------------- -->

    <!-- ----------------------------   Meine heating stage3 start    ------------------------------- -->
    <div *ngIf="currentSetupStage == 3" style="display: flex;flex-direction: column;">
        <div [formGroup]="stageThreeForm">
            <div class="solarsvgs">
                <div class="card" [ngClass]="{highlight:option.show}" *ngFor="let option of solarHouses; let i = index"
                    (click)="solarHouse(option, i)" style="width: 120px;height: 127px;">
                    <div>
                        <span *ngIf="option.show" class="checkboxhas">
                            <mat-checkbox [checked]="true" [disableRipple]="true" [disabled]="true">
                            </mat-checkbox>
                        </span>
                        <span [ngStyle]="{'margin-left': option.show ? '22px' : '22px' }"
                            [innerHTML]='getSVG(option.icon)' style="position: relative;top: 6px;">
                        </span>
                    </div>
                    <div class="text-center cardText"
                        [ngStyle]="{'color': option.show ? 'var(--primaryColour)' : '#333333' }"
                        style="position: relative;top: 7px;">
                        {{option.type}}
                    </div>
                </div>
            </div>
            <div>
                <div style="text-align: center;">
                    <span [appInlinesvg]="'assets/Flache.svg'" style="margin-right: 50px;"></span>
                    <label style="margin-top: 0px;margin-left: 17px;"
                        class="fs-posAbs fs-newsLabel">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung24||'Fläche'}}</label>
                    <input class="formControl" style="position: relative;bottom: 15px;left: 5px;width: 240px;"
                        (input)="validateInput($event)" formControlName="flache"
                        [ngClass]="{'disableInput' : isInputEnabled == false}" type="number">
                    <span style="position: relative;left: 8px;bottom: 18px;">m²</span>
                    <div *ngIf="stageThreeForm?.get('flache')?.errors?.required && (stageThreeForm.get('flache').touched || stageThreeForm.get('flache').dirty) && isInputEnabled"
                        class="haus-alert1" style="width: 257px;margin-left: 220px;">
                        {{'Pflichtfeld ausfüllen'}}!
                    </div>
                    <div *ngIf="stageThreeForm?.get('flache')?.errors?.min && isInputEnabled" class="haus-alert1"
                    style="width: 257px;margin-left: 220px;">
                        {{languageData.Erro_msg.VarKundeportalErro_msg8 || 'Fläche muss größer als null sein!'}}!
                    </div>
                </div>
                <div style="text-align: center;margin-top: 10px;margin-left: 50px;">
                    <label class="toggle" [ngClass]="{'disableInputt' : isInputEnabled == false}">
                        <input type="checkbox" formControlName="support" [disabled]="!isInputEnabled"
                            (change)="stageThreeSupport($event.target.checked,'three')">
                        <span class="slider"></span>
                    </label>
                    <span style="position: relative;bottom: 4px;right: 20px;">
                        {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung25||'Heating Support?'}}
                    </span>
                </div>

                <!-- buttons view -->
                <div>
                    <button class="popbtn cancel-btn pointer" (click)="navigateToStageTwo()">
                        {{"Zurück"}}
                    </button>
                    <button class="pull-right pointer" [disabled]="!stageThreeForm.valid"
                        [ngClass]="{'disabled': !stageThreeForm.valid}" (click)="saveStageThree()">
                        {{preisrechnerTranslations['Adresseingabe']['Var_Weiter'] || "Weiter"}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- ----------------------------   Meine heating stage3 end    ------------------------------- -->


    <!-- ----------------------------   Meine heating stage4 start   ------------------------------- -->
    <div *ngIf="currentSetupStage == 4" style="display: flex;flex-direction: column;">
        <div [formGroup]="stageFourForm" class="scrollbar">
            <div style="text-align: center;">
                <p style="font-weight: 600;">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung27 || 'Ihr geschätzter Normverbrauch pro
                    Jahr
                    beträgt:'}}
                </p>
                <div>
                    <div>
                        <input class="inputalign" formControlName="consumptionFuel" type="text" appNumberOnly
                            (blur)="numberFormat($event.target.value,'consumptionFuel')"
                            style="text-align: left;padding-left: 12px;margin-left: 75px;">
                        <span class="wochealign">{{selectedUnits}}</span>

                        <div *ngIf="stageFourForm?.get('consumptionFuel')?.errors?.min" class="haus-alert3">
                            {{languageData.Erro_msg.VarKundeportalErro_msg9 || 'Verbrauch müss höher als null sein!'}}
                        </div>
                        <div *ngIf="stageFourForm?.get('consumptionFuel')?.errors?.required && (stageFourForm.get('consumptionFuel').touched || stageFourForm.get('consumptionFuel').dirty)"
                            class="haus-alert3">
                            {{'Pflichtfeld bitte ausfüllen'}}!
                        </div>
                        <div *ngIf="stageFourForm.get('consumptionFuel').hasError('pattern') && (stageFourForm.get('consumptionFuel').touched || stageFourForm.get('consumptionFuel').dirty)"
                            class="haus-alert3">
                            {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen
                            erlaubt!'}}
                        </div>
                    </div>
                </div>
                <p style="font-weight: 600;">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung28 || 'Passen Sie Ihren Normverbrauch bei
                    Bedarf
                    an!'}}
                </p>
            </div>

            <div style="text-align: center;position: relative;margin-left: 92px;">
                <span [appInlinesvg]="'assets/Trackingofinventoriessvgs/FuelTank.svg'" class="tankSpan"></span>
                <label style="margin-top: 0px;margin-left: 17px" class="fs-posAbs fs-newsLabel">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung29 || 'Tankgröße'}}*
                </label>
                <input class="tankinput" formControlName="tankGross" type="text" appNumberOnly
                    (blur)="numberFormat($event.target.value,'tankGross')" style="margin-left: 5px;">
                <span class="tankliter">{{selectedUnits}}</span>
                <div *ngIf="stageFourForm.controls.tankGross.touched && stageFourForm.controls.tankGross.status == 'INVALID' && !stageFourForm.get('tankGross').hasError('pattern')"
                    class="haus-alert1" style="margin-left: 93px;">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
                <div *ngIf="stageFourForm.get('tankGross').hasError('pattern') && (stageFourForm.get('tankGross').touched || stageFourForm.get('tankGross').dirty)"
                    class="haus-alert9">
                    {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen
                    erlaubt!'}}
                </div>
            </div>

            <div style="text-align: center;position: relative;margin-left: 92px;">
                <span [appInlinesvg]="'assets/Trackingofinventoriessvgs/FuelReading.svg'" class="tankSpan">
                </span>
                <label style="margin-top: 0px;margin-left: 17px" class="fs-posAbs fs-newsLabel">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung30 || 'Aktueller Füllstand'}}*
                </label>
                <input class="tankinput" formControlName="currentFuel" type="text" appNumberOnly
                    (blur)="numberFormat($event.target.value,'currentFuel')" style="margin-left: 5px;">
                <span class="tankliter">{{selectedUnits}}</span>
                <div *ngIf="stageFourForm.controls.currentFuel.touched && stageFourForm.controls.currentFuel.status == 'INVALID' && !stageFourForm.get('currentFuel').hasError('pattern')"
                    class="haus-alert1" style="margin-left: 93px;">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
                <div *ngIf="stageFourForm.get('currentFuel').hasError('pattern') && (stageFourForm.get('currentFuel').touched || stageFourForm.get('currentFuel').dirty)"
                    class="haus-alert9">
                    {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen
                    erlaubt!'}}
                </div>
                <div *ngIf="stageFourForm.errors?.tankTooBig" class="haus-alert9">
                    {{languageData.Meine_Heizung.VarTrader-AppMeineHeizung45 || "Füllstand kann nicht Größer als
                    Tankgröße sein"}}
                </div>
            </div>

            <div class="wonchdiv">
                <label style="font-weight: 600;">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung31 || 'Erinnerung Bestandskontrolle:'}}
                </label>
                <input class="inputalign" formControlName="fuelRemainder" type="text" appNumberOnly>
                <span class="wochealign" style="width: 82px;right: 83px;">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung32 || 'Woche(n)'}}
                </span>
                <div *ngIf="stageFourForm.get('fuelRemainder').hasError('pattern')" class="haus-alert6">
                    {{'Nur Ganze Zahlen größer als Null akzeptiert!'}}
                </div>
                <div *ngIf="stageFourForm.get('fuelRemainder').hasError('required')" class="haus-alert6">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
            </div>

            <div class="bestreidiv">
                <label class="toggle togglelabel">
                    <input type="checkbox" (change)="toggleSwitch($event.target.checked,'rangeWarning')"
                        formControlName="toggleSwitchRange" [checked]="toggleSwitchRange"><span class="slider"></span>
                </label>
                <span class="reichlabel">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung33 || 'Reichweitenwarnung'}}
                </span>
                <input type="number" class="inputalign" style="margin-left: 23px;" formControlName="rangeWarning"
                    appNumberOnly [attr.disabled]="!stageFourForm.value.toggleSwitchRange ? true : null" min="0" [readonly]="!stageFourForm.value.toggleSwitchRange">
                <span class="wochealign" style="width: 82px;right: 83px;">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung34
                    || 'Tage'}}</span>
                <div *ngIf="stageFourForm.value.toggleSwitchRange &&  stageFourForm?.get('rangeWarning')?.errors?.min" class="haus-alert7">
                    {{languageData.Erro_msg.VarKundeportalErro_msg10 || 'Reichweitenwarnung muss mindestens 10 Tage im
                    Voraus erfolgen!'}}
                </div>
                <div *ngIf="stageFourForm.get('rangeWarning').hasError('pattern')" class="haus-alert7">
                    {{'Nur Ganze Zahlen größer als Null akzeptiert!'}}
                </div>
                <div *ngIf="stageFourForm.value.toggleSwitchRange && stageFourForm.get('rangeWarning').hasError('required') && stageFourForm.get('rangeWarning').touched" class="haus-alert7">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>

            </div>

            <div class="bestreidiv">
                <label class="toggle togglelabel"><input type="checkbox"
                        (change)="toggleSwitch($event.target.checked,'Bestandsalarm1')" formControlName="toogle1"
                        [checked]="stageFourForm.value.toogle1"><span class="slider"></span>
                </label>
                <span class="reichlabel">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung35 || 'Bestandsalarm 1'}}
                </span>

                <input type="text" class="inputalign" style="margin-left: 50px;" formControlName="Bestandsalarm1"
                [attr.disabled]="!stageFourForm.value.toogle1 ? true : null" appNumberOnly
                    (blur)="numberFormat($event.target.value,'Bestandsalarm1');" [readonly]="!stageFourForm.value.toogle1">
                <span class="wochealign">{{selectedUnits}}</span>
                <div *ngIf="stageFourForm.value.toogle1 && stageFourForm?.get('Bestandsalarm1')?.errors?.min" class="haus-alert7">
                    {{languageData.Erro_msg.VarKundeportalErro_msg12 || 'Eingabe muss mindestens 500 sein!'}}
                </div>
                <div *ngIf="stageFourForm.get('Bestandsalarm1').hasError('pattern')" class="haus-alert7">
                    {{'Nur Ganze Zahlen größer als Null akzeptiert!'}}
                </div>
                <div *ngIf="stageFourForm.errors?.invalidAlarms && stageFourForm.get('toogle2')?.value" class="haus-alert10">
                    {{languageData.Erro_msg.VarKundeportalErro_msg11 || 'Eingabe muss größer als Bestandsalarm 2
                    sein!'}}
                </div>
                <div *ngIf="stageFourForm.value.toogle1 && stageFourForm.get('Bestandsalarm1').hasError('required') && stageFourForm.get('Bestandsalarm1').touched" class="haus-alert7">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
            </div>
            <div class="bestreidiv">
                <label class="toggle togglelabel"><input type="checkbox"
                        (change)="toggleSwitch($event.target.checked,'Bestandsalarm2')" formControlName="toogle2"
                        [checked]="stageFourForm.value.toogle2"><span class="slider"></span>
                </label>
                <span class="reichlabel">
                    {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung36 || 'Bestandsalarm 2'}}
                </span>
                <input type="text" class="inputalign" style="margin-left: 50px;" formControlName="Bestandsalarm2"
                     [attr.disabled]="!stageFourForm.value.toogle2 ? true : null"
                    (blur)="numberFormat($event.target.value,'Bestandsalarm2');" appNumberOnly [readonly]="!stageFourForm.value.toogle2">
                <span class="wochealign">{{selectedUnits}}</span>
                <div *ngIf="stageFourForm.value.toogle2 && stageFourForm?.get('Bestandsalarm2')?.errors?.min" class="haus-alert7">
                    {{languageData.Erro_msg.VarKundeportalErro_msg14 || 'Eingabe muss mindestens 250 sein!'}}
                </div>
                <div *ngIf="stageFourForm.get('Bestandsalarm2').hasError('pattern')" class="haus-alert7">
                    {{'Nur Ganze Zahlen größer als Null akzeptiert!'}}
                </div>
                <div *ngIf="stageFourForm.value.toogle2 && stageFourForm.get('Bestandsalarm2').hasError('required') && stageFourForm.get('Bestandsalarm2').touched" class="haus-alert7">
                    {{'Pflichtfeld ausfüllen'}}!
                </div>
                <!-- <div *ngIf="stageFourForm.errors?.invalidAlarms">Bestandsalarm1 should be greater than Bestandsalarm2.</div> -->
            </div>

            <!-- <div style="width: 71%;margin-bottom: 15px;"> -->
            <div style="margin-bottom: 15px;display:flex;margin-left:98px">
                <div style="display: inline-block;" style="padding-top:2px">
                    <input type="checkbox" id="myCheckbox" class="hidden-checkbox" formControlName="myCheckbox">
                    <label for="myCheckbox" class="circle-checkbox"></label>
                </div>
                <div style="padding:0px 0px 0px 7px">
                    <span class="circletxt"
                        (click)="openHyperlink();$event.stopPropagation();">{{languageData.Meine_Heizung.VarKundeportalMeine_Heizung37
                        ||
                        'Ich habe den Hinweis zum Normverbrauch, Bestandstracking und Verbrauchsprognose gelesen und
                        verstanden'}}</span>
                </div>
            </div>
        </div>
        <!-- buttons view -->
        <div>
            <button class="popbtn cancel-btn pointer" (click)="navigateToStageThree()">
                {{"Zurück"}}
            </button>
            <!-- {{stageFourForm.valid}}
            {{stageFourForm.value | json}} -->
            <button class="pull-right pointer" [disabled]="!stageFourForm.valid"
                [ngClass]="{'disabled': !stageFourForm.valid}" (click)="saveAllStages()">
                {{preisrechnerTranslations['Adresseingabe']['Var_Weiter'] || "Weiter"}}
            </button>
        </div>
    </div>
    <!-- ----------------------------   Meine heating stage4 end   ------------------------------- -->

    <!------------------------ stages body end ------------------------------>
</div>

<loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>